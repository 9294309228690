import React from "react";
import "./services.scss";
import { Link, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import servicepatient from "../../../assets/casesImgs/service-patient.png";
import beacon from "../../../assets/casesImgs/beaconofhope.png";
import gig1 from "../../../assets/casesImgs/gig1.png";
import gig2 from "../../../assets/casesImgs/gig2.png";
import gig3 from "../../../assets/casesImgs/gig3.png";
import gig4 from "../../../assets/casesImgs/gig4.png";
import Gigslider from "./gigslider";
import Testimonialcard from "./testimonialcard";
import { Provider } from "../../../context/Context";
import { GET_MY_SERVICES } from "../../../services/methods/Query";
import Cookies from "universal-cookie";
import { useQuery } from "@apollo/client";
import Loader from "../../Loader/Loader";
import { useSelector } from "react-redux";

const Services = () => {
  const { caseById } = Provider();
  const { user } = useSelector((state) => state.user);
  // const gigData = [
  //   {
  //     gigimg: gig1,
  //     name: "Gig’s Title",
  //     gigamt: "$120",
  //     gigpara:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diam",
  //   },
  //   {
  //     gigimg: gig2,
  //     name: "Gig’s Title",
  //     gigamt: "$120",
  //     gigpara:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diam",
  //   },
  //   {
  //     gigimg: gig3,
  //     name: "Gig’s Title",
  //     gigamt: "$120",
  //     gigpara:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diam",
  //   },
  //   {
  //     gigimg: gig4,
  //     name: "Gig’s Title",
  //     gigamt: "$120",
  //     gigpara:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diam",
  //   },
  //   {
  //     gigimg: gig3,
  //     name: "Gig’s Title",
  //     gigamt: "$120",
  //     gigpara:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diam",
  //   },
  //   {
  //     gigimg: gig2,
  //     name: "Gig’s Title",
  //     gigamt: "$120",
  //     gigpara:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis egestas dolor diam",
  //   },
  // ];
  const cookies = new Cookies();
  const params = useParams();

  const { data: services, loading } = useQuery(GET_MY_SERVICES, {
    variables: {
      userId: caseById?.userId,
    },
  });
  const allServices = services?.getMyServices?.data;
  const percentage = Math.floor(
    (caseById?.totalAmountCollected / caseById?.goalAmount) * 100
  );
  return (
    <>
      <div className="services-main-container">
        <div className="herosection d-flex align-items-center justify-content-center">
          <h1>Services</h1>
        </div>
        {/* <div className="service-patient-section d-flex gap-5 justify-content-center m-auto">
          <img src={caseById?.picturesLinks[0]} alt="servicepatient" />
          <div className="service-patient-div d-flex flex-column">
            <h4>{caseById?.patientFullName}</h4>
            <div className="green-container">
              <span className="d-flex gap-2">
                <h6>Rs. {caseById?.totalAmountCollected}</h6> out of Rs.{" "}
                {caseById?.goalAmount}
              </span>
              <div className="Sprogress-containerr d-flex gap-4">
                <div
                  className="Sprogress-barr"
                  style={{
                    width: `${percentage}%`,
                    transition: "width 0.6s ease",
                  }}
                ></div>
              </div>
              <p>{caseById?.supporter} Donations</p>
              {user?.role === "donar" && (
                <div className="d-btn d-flex justify-content-center">
                  <Link to={`/monthlydonation/${params?.id}`}>
                    <button>Support</button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div> */}
        {loading ? (
          <Loader />
        ) : (
          <div className="Patientsgig my-5 m-auto ">
            {allServices?.length >= 1 ? (
              <>
                <h5>Patient’s Gigs</h5>
                <div className="gigcard-container mt-5">
                  <div className="gigcard-container mt-5">
                    <Gigslider allServices={allServices} />
                  </div>
                </div>
              </>
            ): (
              <h5 style={{
                display:'flex',
                justifyContent: 'center'
              }}>You have no service</h5>
            )}
          </div>
        )}

        {/* {user?.role === "donar" && ( */}
        {/* <div className="testimonial-section mx-auto pb-5">
            <h5>Testimonials </h5>
            <div className="testimonial-inner d-flex gap-4">
              <div className="testimonial-left">
                <Testimonialcard />
                <Testimonialcard />
                <Testimonialcard />
              </div>
              <div className="testimonial-right d-flex flex-column align-items-center  gap-4">
                <img src={beacon} alt="beaconofhope" />
                <h3 className="text-center px-4">
                  Become A Beacon Of Hope For Those In Need.
                </h3>
                <Link to="/allCases">
                  <button>Support</button>
                </Link>
              </div>
            </div>
          </div> */}
        {/* )} */}
        {!user?.data?._id && (
          <div className="goodwishes-sec mt-5">
            <h4>Sign up to leave Good wishes!</h4>
            <Link to="/signup">
              <button>Sign Up</button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default Services;
