import React, { useEffect, useState } from "react";
import "./header.css";
import Logo from "../../assets/images/Logo.png";
import userdp from "../../assets/images/PatientsDp.png";
import "@fontsource/poppins";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import Container from "react-bootstrap/Container";
import logoimg from "../../assets/WaitlistPageImages/footerlogo.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { IoMdNotifications } from "react-icons/io";
import userImg from "../../assets/ForgetPasswordImages/username_img.png";
import { CiSearch } from "react-icons/ci";
import { Button, Drawer } from "antd";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { checkToken, getUser } from "../../redux/userSlice";
import Toast from "../../functions/Toast";
import LogoutModal from "./HeaderModal/logoutmodal";
import { pagesArr } from "../../services/helper/pagesData";

function Header() {
  let login = localStorage.getItem("login");
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const navigate = useNavigate();
  const [showheaderrModal, setshowheaderrModal] = useState(false);
  const closeheaderModal = () => setshowheaderrModal(false);
  const handleNavigation = (link) => {
    navigate(link);
  };

  console.log(login);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const { user } = useSelector((state) => state.user);
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  const handleLogout = async () => {
    await cookies.remove("token", { path: "/" });
    await dispatch(getUser(null));
    dispatch(checkToken(false));
    Toast.success("Logout Successfully");
  };

  const handleSearch = (e) => {
    setIsSearch(true);
    setSearchTerm(e.target.value);
    const results = pagesArr?.filter((item) =>
      item?.name?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
    setFilteredResults(results);
  };

  useEffect(() => {
    if (searchTerm == "") {
      setIsSearch(false);
    }
  }, [searchTerm, isSearch]);

  let showHeader;
  if (user?.data?._id) {
    showHeader = (
      <>
        <Navbar expand="lg" className="main_navbar_div">
          <Container>
            <Navbar.Brand href="/">
              <img src={Logo} alt="" className="img-fluid" />
              <p>ShifaLia</p>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <div className="search_par">
                  <div className="header_input">
                    <input value={searchTerm} onChange={handleSearch} />
                    <CiSearch />
                  </div>
                  {isSearch &&
                    (filteredResults?.length == 0 ? (
                      <span className="suggest_search">No data Found</span>
                    ) : (
                      <div className="suggest_search">
                        {filteredResults?.map((item) => (
                          <li
                            onClick={() => navigate(item?.path)}
                            className="suggest_item"
                          >
                            {item?.name}
                          </li>
                        ))}
                      </div>
                    ))}
                </div>
                <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
                <Nav.Link onClick={() => navigate("/allCases")}>
                  All Cases
                </Nav.Link>
                <Nav.Link onClick={() => navigate("/works")}>
                  How it Works
                </Nav.Link>
                <Nav.Link onClick={() => navigate("/blogs")}>Blogs</Nav.Link>
              </Nav>

              <div className="main_navbar_right">
                {/* <div>
                  <IoMdNotifications />
                </div> */}
                <div className="user_navbar">
                  {user?.data?._id && (
                    <>
                      <img
                        src={user?.data?.profileImageLink || userdp}
                        alt=""
                        height={50}
                        width={50}
                        style={{ borderRadius: "50px" }}
                      />
                      <NavDropdown
                        title={user?.data?.firstName}
                        id="basic-nav-dropdown"
                      >
                        <NavDropdown.ItemText>
                          <div className="navprofile-dropdowm d-flex gap-2 align-items-center">
                            <img
                              src={user?.data?.profileImageLink || userdp}
                              alt=""
                              height={50}
                              width={50}
                              style={{ borderRadius: "50px" }}
                            />
                            <span className="d-flex flex-column">
                              {" "}
                              <h6>{user?.data?.firstName}</h6>
                              {user?.data?.email}
                            </span>
                          </div>
                        </NavDropdown.ItemText>
                        <NavDropdown.ItemText>
                          <div className="hr-userprofile mt-2"></div>
                        </NavDropdown.ItemText>
                        <NavDropdown.Item
                          onClick={() => navigate("/userProfile")}
                        >
                          <span className="myprofile-navitem d-flex gap-2 align-items-center mt-2 mx-2">
                            <Icon
                              icon="iconamoon:profile-fill"
                              color="#595959"
                              width="16"
                              height="16"
                            />
                            My Profile
                          </span>
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item
                          onClick={() => navigate("/userProfile")}
                        >
                          <span className="myprofile-navitem d-flex gap-2 align-items-center mt-2 mx-2">
                            <Icon
                              icon="fa6-solid:money-bill-wave"
                              color="#595959"
                              width="18"
                              height="18"
                            />
                            Start Campaigning
                          </span>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={() => navigate("/userProfile")}
                        >
                          <span className="myprofile-navitem d-flex gap-2 align-items-center mt-2 mx-2">
                            <Icon
                              icon="fluent:clipboard-task-24-filled"
                              color="#595959"
                              width="18"
                              height="18"
                            />
                            My Services
                          </span>
                        </NavDropdown.Item> */}
                        {user?.role === "patient" && (
                          <NavDropdown.Item
                            onClick={() => navigate("/starCampaigningForm")}
                          >
                            <button className="nav-fund-btn mt-2">
                              Start Campaigning
                            </button>
                          </NavDropdown.Item>
                        )}
                        <NavDropdown.Item
                          onClick={() => {
                            setshowheaderrModal(true);
                          }}
                        >
                          <button className="nav-logout-btn my-1 d-flex gap-2 align-items-center justify-content-center">
                            <Icon
                              icon="ic:baseline-logout"
                              color="#FFFFFF"
                              width="18"
                              height="18"
                            />
                            Logout
                          </button>
                        </NavDropdown.Item>
                      </NavDropdown>
                      {showheaderrModal && (
                        <LogoutModal closeheaderModal={closeheaderModal} />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="mobile-navbar-notlogged-in">
          <NavLink
            to="/"
            className="d-flex  align-items-center justify-content-center"
            style={{
              textDecoration: "none",
            }}
          >
            <img src={Logo} alt="" className="img-fluid mobileLogo" />
            <h6
              style={{
                color: "#363535",
                marginBottom: "0px",
                textDecoration: "none",
              }}
            >
              ShifaLia
            </h6>
          </NavLink>
          <div className="right-nav-items d-flex gap-1  align-items-center">
            <img
              src={user?.data?.profileImageLink || userdp}
              height={50}
              width={50}
              style={{ borderRadius: "50px" }}
              alt=""
              onClick={() => navigate("/userProfile")}
            />
            <Button onClick={showDrawer} className="menu-btn" type="button">
              <Icon
                color="#02627B"
                icon="material-symbols:menu"
                className="menu-btn-icon"
              />
            </Button>
          </div>

          <Drawer
            placement={placement}
            closable={false}
            onClose={onClose}
            open={open}
            key={placement}
            width="80%"
            className="mobile-menu-drawer"
          >
            <div className="user-nav-toogle d-flex align-items-center gap-1">
              {user?.data?._id && (
                <>
                  <img
                    src={user?.data?.profileImageLink || userdp}
                    alt=""
                    height={60}
                    width={60}
                    style={{ borderRadius: "50px" }}
                  />
                  <span
                    className="d-flex flex-column"
                    style={{ color: "#434343" }}
                  >
                    <h6 className="fw-bold" style={{ marginBottom: "0px" }}>
                      {user?.data?.firstName}
                    </h6>
                    {user?.data?.email}
                  </span>
                </>
              )}
            </div>
            <div>
              <hr></hr>
              <NavLink to="/userProfile">
                <span
                  className="myprofile-nav d-flex gap-2 align-items-center mt-2 mx-2"
                  style={{ color: "#595959", fontSize: "16px" }}
                >
                  <Icon
                    icon="iconamoon:profile-fill"
                    color="#595959"
                    width="22"
                    height="22"
                  />
                  My Profile
                </span>
              </NavLink>

              <hr></hr>
            </div>

            <ul className=" mt-5 pt-2">
              <li>
                <a
                  onClick={() => {
                    handleNavigation("/");
                    setOpen(false);
                  }}
                >
                  Home
                </a>
              </li>

              <li className="my-3">
                <a
                  onClick={() => {
                    handleNavigation("/allCases");
                    setOpen(false);
                  }}
                >
                  All Cases
                </a>
              </li>
              <li className="my-3">
                <a
                  onClick={() => {
                    handleNavigation("/works");
                    setOpen(false);
                  }}
                >
                  How it Works
                </a>
              </li>
              <li className="my-3">
                <a
                  onClick={() => {
                    handleNavigation("/blogs");
                    setOpen(false);
                  }}
                >
                  Blogs
                </a>
              </li>
            </ul>
            {/* <div className="header_input">
              <input />
              <CiSearch />
            </div> */}
            <div className="d-flex flex-column gap-3">
              <NavLink to="/starCampaigningForm">
                <button className="fund-nav-btn">Start Campaigning</button>
              </NavLink>
              <NavLink
                onClick={() => {
                  setshowheaderrModal(true);
                }}
              >
                <button className="logout-nav-btn">Logout</button>
              </NavLink>
              {showheaderrModal && (
                <LogoutModal closeheaderModal={closeheaderModal} />
              )}
            </div>
          </Drawer>
        </div>
      </>
    );
  } else {
    showHeader = (
      <>
        <section className="main-header">
          <div className="container">
            <nav className="navbar nav-box navbar-expand-lg navbar-light">
              <a className="navbar-brand" href="/">
                <img src={Logo} alt="" className="img-fluid" />
                <p>ShifaLia</p>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ms-auto ">
                  <li className="nav-item">
                    <Link to="/landingpages" className="nav-link active">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/allCases" className="nav-link active">
                      All Cases
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/blogs">
                      Blogs
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/works" className="nav-link">
                      How it Works
                    </Link>
                  </li>
                </ul>
                <div className="side-btn">
                  <Link to="/signup" className="sign-btn">
                    Sign up
                    {/* <span className="circle-anim"></span> */}
                  </Link>
                  <Link to="/starCampaigningForm">
                    <button className="fund-btn">Start Campaigning</button>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </section>
        <div className="mobile-navbar-notlogged-in ">
          <NavLink
            to="/"
            className="d-flex  align-items-center justify-content-center"
            style={{
              textDecoration: "none",
            }}
          >
            <img src={Logo} alt="" className="img-fluid mobileLogo" />
            <h6
              style={{
                color: "#363535",
                marginBottom: "0px",
                textDecoration: "none",
              }}
            >
              ShifaLia
            </h6>
          </NavLink>
          <Button onClick={showDrawer} className="menu-btn" type="button">
            <Icon
              color="black"
              icon="material-symbols:menu"
              className="menu-btn-icon"
            />
          </Button>
          <Drawer
            placement={placement}
            closable={false}
            onClose={onClose}
            open={open}
            key={placement}
            width="80%"
            className="mobile-menu-drawer"
          >
            {/* <img src={logoimg} alt="" className="img-fluid mobileLogo" /> */}

            <ul className=" mt-5">
              <li>
                <a
                  onClick={() => {
                    handleNavigation("/");
                    setOpen(false);
                  }}
                >
                  Home
                </a>
              </li>
              <li className="my-3">
                <a
                  onClick={() => {
                    handleNavigation("/allCases");
                    setOpen(false);
                  }}
                >
                  All Cases
                </a>
              </li>
              <li className="my-3">
                <a
                  onClick={() => {
                    handleNavigation("/blogs");
                    setOpen(false);
                  }}
                >
                  Blogs
                </a>
              </li>

              <li className="my-3">
                <a
                  onClick={() => {
                    handleNavigation("/about-us");
                    setOpen(false);
                  }}
                >
                  About Us
                </a>
              </li>

              <li className="my-3">
                <a
                  onClick={() => {
                    handleNavigation("/works");
                    setOpen(false);
                  }}
                >
                  How it Works
                </a>
              </li>
              <li className="my-3">
                <a
                  onClick={() => {
                    handleNavigation("/blogs");
                    setOpen(false);
                  }}
                >
                  Blogs
                </a>
              </li>
            </ul>
            <div className="d-flex flex-column gap-3">
              <NavLink to="/signup">
                <button className="signup-nav-Btn">Sign up</button>
              </NavLink>
              <NavLink to="/starCampaigningForm">
                <button className="fund-nav-btn">Start Campaigning</button>
              </NavLink>
            </div>
          </Drawer>
        </div>
      </>
    );
  }
  return <>{showHeader}</>;
}

export default Header;
