import { useSubscription } from "@apollo/client";
import { GET_SUBS_NOTIFICATION } from "../../services/methods/Subscription";
import Toast from "../../functions/Toast";

const SubscriptionComponent = () => {
  const { data, loading, error } = useSubscription(GET_SUBS_NOTIFICATION);

  console.log(data, "data");
  console.log(error, "error");
  console.log(loading, "loading");
  if (data) {
    Toast.success(data?.newNotification?.data?.notification?.body);
  }
  if (error) {
    Toast.error(error.message);
  }
};

export default SubscriptionComponent;
