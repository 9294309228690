import React from 'react';
import Congratulations from "../../../styles/lottie/congratulations.json";
import Logo from "../../../assets/images/Logo.png";
import Lottie from 'react-lottie';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const SuccessfulChange = () => {
    const navigate = useNavigate();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Congratulations,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const loginHandler = () => {
        navigate("/signin")
    }

    return (
        <div className='forget_password'>
            <Container>
                <a className="navbar-brand" href="/">
                    <img src={Logo} alt="" className="img-fluid" />
                    <p>ShifaLia</p>
                </a>

                <div className='forget_password_box mt-5'>
                    <div className='inner_congratulations'>
                        <Lottie options={defaultOptions}
                            height={180}
                            width={180}
                        />
                        <h6>Congratulations</h6>
                        <p>You have successfully rest your password. Login again with new password.</p>
                        <button onClick={loginHandler}>Log in</button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default SuccessfulChange