import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

const isPushNotificationSupported = () => {
  return "serviceWorker" in navigator && "PushManager" in window;
};

export const generateToken = async () => {
  if (!isPushNotificationSupported()) {
    console.log("Push notifications are not supported on this browser.");
    return;
  }

  const messaging = getMessaging(app);
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    try {
      const token = await getToken(messaging, {
        vapidKey:
          "BJXC4IGDBZ8-N1unmxJGQzDvaxBgK9S-yiAkWLntrDU-GlSsZqQg5M_JP7zYK9-V_YymoUv1KozRFSLJc7V3Joc",
      });
      console.log("FCM Token:", token);
    } catch (error) {
      console.error("Error getting FCM token:", error);
    }
  } else {
    console.log("Notification permission not granted.");
  }
};
