import React from "react";
import "./donations.scss";
import { Col, Row } from "react-bootstrap";
import dBannerContent1 from "../../../assets/casesImgs/RaisedAmount.png";
import dBannerContent2 from "../../../assets/casesImgs/TargetAmount.png";
import Supportercard from "./supportercard";
import StartFund from "../../FundraisingTipsComponents/StartFundraiser/startfund";
import { Provider } from "../../../context/Context";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
const Donations = () => {
  const { caseById } = Provider();
  const { user } = useSelector((state) => state.user);
  const params = useParams();
  return (
    <>
      <div className="donations-sec">
        <div className="donation-banner d-flex flex-column justify-content-center align-items-center">
          <img src={caseById?.picturesLinks[0]} alt="dBanner" />
          <div className="Dbanner-content-  d-flex flex-wrap justify-content-center align-items-center">
            <div className="inn d-flex align-items-center gap-2">
              <img src={dBannerContent1} alt="dBannerContent1" />
              <div className="inner-con">
                <h5>Target Amount</h5>
                <h6>Rs. {caseById?.goalAmount}</h6>
              </div>
            </div>
            <div className="inn d-flex align-items-center gap-2">
              <img src={dBannerContent2} alt="dBannerContent1" />
              <div className="inner-con">
                <h5>Raised Amount</h5>
                <h6>Rs. {caseById?.totalAmountCollected}</h6>
              </div>
            </div>
          </div>
          {user?.role === "donar" && (
            <button className="my-4">
              <Link
                to={`/monthlydonation/${params?.id}`}
                style={{ textDecoration: "none", color: "white" }}
              >
                Support
              </Link>
            </button>
          )}
        </div>
        <div className="donation-support-sec my-5">
          <div className="donation-hr"></div>
          <h5 className="my-5">Supporters For This Campaign</h5>
          <Row>
            <Col md={6} lg={6}>
              {caseById?.AmountCollectionDetails?.map((item) => (
                <Supportercard
                  name={item?.firstName + " " + item?.lastName}
                  amount={item?.amount}
                  key={item?._id}
                />
              ))}

              {/* <div className="btn-card-div d-flex justify-content-center align-items-center">
                <button className="my-4">Load More</button>
              </div> */}
            </Col>
            <Col md={6} lg={6}>
              <div className="donation-summary my-3 d-flex flex-column ">
                <h5>Campaigns Summary</h5>
                <span className="d-flex">
                  Total Contribution Received:
                  <h6 className="px-2">Rs. {caseById?.totalAmountCollected}</h6>
                </span>
                <span className="d-flex">
                  Goal: <h6 className="px-2">Rs. {caseById?.goalAmount}</h6>
                </span>
                <span className="d-flex">
                  Donors: <h6 className="px-2">{caseById?.supporter}</h6>
                </span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <StartFund />
    </>
  );
};

export default Donations;
