import axios from "axios";
import Toast from "../../functions/Toast";
import Cookies from "universal-cookie";

export const paymentAPI = async (data) => {
  const cookies = new Cookies();
  const res = await axios
    .post(`${process.env.REACT_APP_SWAGGER_API}/patient/new-payment`, data, {
      headers: {
        accept: "*/*",
        // Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
    //   Toast.error(
    //     error?.response?.data?.message ||
    //       error?.response?.data?.message[0] ||
    //       "Something went wrong"
    //   );
    });
  return res;
};
