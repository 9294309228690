import React, { useState } from "react";
import Header from "../../Header/header";
import "./raisebanner.scss";
import whtasppp from "../../../assets/raiseaconcern/Whatsapp Contact.png";
import fb from "../../../assets/raiseaconcern/FaceBook btn.png";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import banner2 from "../../../assets/Helpcenter/banner2.png";
import { useApi } from "../../../services/mutation&Query/GraphqlApi";
import { RAISE_A_CONCERN } from "../../../services/methods/Mutations";
const Raisebanner = () => {
  const navigate = useNavigate();

  const [raise] = useMutation(RAISE_A_CONCERN);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    typeId: "PK",
    description: "",
  });
  const { RaiseAConcern } = useApi();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { firstname, lastname, email, phone, typeId, description } = formData;

    if (
      !firstname ||
      !lastname ||
      !email ||
      !phone ||
      !typeId ||
      !description
    ) {
      console.error("Please fill in all fields");
      return false;
    }
    return true;
  };

  const raiseConcernHandler = async () => {
    if (validateForm()) {
      const response = await RaiseAConcern(raise, formData);
      console.log(response);
      // if (response.success) {
      //     // Handle success, e.g., show a success message or redirect
      //     console.log("Concern raised successfully:", response.data);
      // } else {
      //     // Handle failure, e.g., show an error message
      //     console.error("Error raising concern:", response.error);
      // }
    }
  };

  return (
    <>
      <div className="raise-banner">
        <div className="header-div-raise">
          <Header />
        </div>
        <div className="raisebanner-content">
          <h1>
            We are here to help. Leave us a text and we’ll respond to your query
            ASAP!
          </h1>
          <div className="raise-form">
            <h3>Raise a concern</h3>
            <input
              type="text"
              placeholder="First Name"
              className="in-name"
              name="firstname"
              value={formData.firstname}
              onChange={handleInputChange}
            />
            <input
              type="text"
              placeholder="Last Name"
              className="in-name"
              name="lastname"
              value={formData.lastname}
              onChange={handleInputChange}
            />
            <input
              type="email"
              placeholder="Email"
              className="in-name"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            <input
              type="number"
              placeholder="Phone Number"
              className="in-name"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />
            <select
              className="in-name"
              name="typeId"
              value={formData.typeId}
              onChange={handleInputChange}
            >
              <option value="Campaigner">Campaigner</option>
              <option value="Supporter">Supporter</option>
            </select>
            <textarea
              className="in-nameTextteria"
              rows={9}
              placeholder="Enter Message Here"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
            <button
              className="SendMsgButtonRaise mt-4"
              onClick={raiseConcernHandler}
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
      <div className="contact-sec">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://wa.me/+923323165701"
          className="c-inner"
        >
          <h6>Contact us on WhatsApp</h6>
          <img src={whtasppp} alt="whtasppp" />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100089498583736&mibextid=LQQJ4d"
          rel="noreferrer"
          target="_blank"
          className="c-inner"
        >
          <h6>Contact us on Facebook</h6>
          <img src={fb} alt="fb" />
        </a>
        <p>
          Visit our{" "}
          <Link className="faqRaiseLink" to={"/faqs"}>
            FAQs
          </Link>{" "}
          page for further queries.
        </p>
      </div>
      <div
        style={{
          backgroundImage: `url(${banner2})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%", // Add width and height properties
          height: "100%", // Adjust the height as needed
          marginBottom: "100px",
        }}
        className="helpcenter-cootainer-3"
      >
        <h2 style={{ color: "#fff" }}>Ready to start your own Campaign?</h2>
        <div
          style={{ cursor: "pointer" }}
          className="helpcenter-fundbutton"
          onClick={() => navigate("/starCampaigningForm")}
        >
          Start Campaigning
        </div>
      </div>
    </>
  );
};

export default Raisebanner;
