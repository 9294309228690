import React from "react";
import Header from "../components/Header/header";
import KnowMore from "../components/HowitWorksPageComponent/KnowMore";
import VideoSection from "../components/HowitWorksPageComponent/VideoSection/index.js";
import StepsSection from "../components/HowitWorksPageComponent/StepsSection";
// import FAQSection from "../components/HowitWorksPageComponent/FAQSection";
import FundraiserSection from "../components/HowitWorksPageComponent/FundraiserSection/FundraiserSection";
import Footer from "../components/Footer/footer";
import FAQSection from "../components/WaitListPageComponents/WaitlistFAQS/WaitlistFAQS.jsx";
import WorksFaqData from "../components/HowitWorksPageComponent/FAQs.json";
const HowitWorksPage = () => {
  return (
    <>
      <section style={{ backgroundColor: "#F8F3EA" }}>
        <Header />
        {/* <VideoSection /> */}
        <KnowMore />
        {/* <StepsSection /> */}
        <FAQSection faqData={WorksFaqData} padding="padding" />
        <FundraiserSection />
        <Footer />
      </section>
    </>
  );
};
export default HowitWorksPage;
