import React from "react";
import Slider from "react-slick";
import "./services.scss";
import Gigcard from "./gigcard";
import "../documents/doc.scss";

const Gigslider = ({ allServices }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: allServices?.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {allServices?.map((gig, index) => (
          <div key={index}>
            <Gigcard
              id={gig._id}
              gigimg={gig.photoLinks[0]}
              gigname={gig.title}
              para={gig.description}
              minPrice={gig.minPrice}
              maxPrice={gig.maxPrice}
            />
          </div>
        ))}
      </Slider>
    </>
  );
};

export default Gigslider;
