import React from 'react'
import Startfund from '../components/FundraisingTipsComponents/StartFundraiser/startfund'
import Header from '../components/Header/header'
import Footer from '../components/Footer/footer'
import AboutFooterBanner from '../components/AboutUsComponents/AboutFooterBanner'
import AboutBoxSection from '../components/AboutUsComponents/AboutBoxSection'
import OurGoals from '../components/AboutUsComponents/OurGoals'
import OurMission from '../components/AboutUsComponents/OurMission'
import BannerSection from '../components/AboutUsComponents/BannerSection'

const AboutUs = () => {
  return (
    <div>
      <Header />
      <BannerSection />
      <OurMission />
      <OurGoals />
      {/* <AboutBoxSection /> */}
      <AboutFooterBanner />
      <Startfund />
      <Footer />
    </div>
  )
}

export default AboutUs