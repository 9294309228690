import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import "./notifications/notifications.scss";
import profilepic1 from "../../assets/images/profilePic.png";
import moment from "moment";

const Comment = ({data}) => {
  const [showoptions, setshowoptions] = useState(false);

  return (
    <>
      <div className="cmt-div d-flex gap-3">
        <img src={data?.notification?.image || profilepic1} alt="profilepic1" />
        <div className="txt-div d-flex flex-column justify-content-center w-100">
          <div className="txt- d-flex w-100">
            <div className="left- d-flex">
              <span>
               {data?.notification?.body}
              </span>
            </div>
            <div className="right-">
              <Icon
                icon="ph:dots-three"
                color="#111111"
                width="20"
                height="20"
                className="dotsIcon"
                onClick={() => {
                  setshowoptions((prev) => !prev);
                }}
              />
            </div>
            {showoptions && (
              <div className="drop-down-cmts">
                <ul className="d-flex flex-column align-items-center">
                  <li>
                    <div className="inner d-flex gap-4 align-items-end">
                      <span>
                        <Icon
                          icon="material-symbols-light:visibility-outline"
                          width="20"
                          height="20"
                          className="liIcon"
                        />
                      </span>
                      <p>Mark as read</p>
                    </div>
                  </li>
                  <li>
                    <div className="inner d-flex gap-4 align-items-end">
                      <span>
                        <Icon
                          icon="ph:archive-box-thin"
                          width="20"
                          height="20"
                          className="liIcon"
                        />
                      </span>
                      <p>Archive </p>
                    </div>
                  </li>
                  <li>
                    <div className="inner d-flex gap-4 align-items-end">
                      <span>
                        <Icon
                          icon="material-symbols-light:delete-outline"
                          width="20"
                          height="20"
                          className="liIcon"
                        />
                      </span>
                      <p>Delete</p>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>

          <div
            // className={`comt-txt d-flex align-items-center mb-2 ${props.all}`}
          >
            <p>{data?.comment}</p>
          </div>
          <p>{moment.unix(data?.addedDB).format(("DD/MM/YYYY"))}</p>
        </div>
      </div>
    </>
  );
};

export default Comment;
