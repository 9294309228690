import caseImg1 from "../../../assets/images/caseImg1.png"
import caseImg2 from "../../../assets/images/caseImg2.png"
import caseImg3 from "../../../assets/images/caseImg3.png"
import caseImg4 from "../../../assets/images/caseImg4.png"
import caseImg5 from "../../../assets/images/caseImg5.png"
import caseImg6 from "../../../assets/images/caseImg6.png"
import UserDp from "../../../assets/images/UserDp.png"

const AllcasesData = [
    {
      id: 1,
      title: "A Second Chance for Jacob: Help Him Defeat Leukemia",
      userDpImg: UserDp, 
      user : "By Full Name",
      date: "01-Jan-23",
      uploadedonText: "Uploaded On:",
      supporterText: "Supporters:",
      TotalSupporters: "675",
      imgUrl: caseImg1,
     },
  
    {
      id: 2,
      title: "Raising Hope for Ryan's Road to Recovery",
      userDpImg: UserDp, 
      user : "By Full Name",
      date: "01-Jan-23",
      uploadedonText: "Uploaded On:",
      supporterText: "Supporters:",
      TotalSupporters: "675",
      imgUrl: caseImg2,
     },
  
    {
      id: 3,
      title: "Support Sarah's Journey to Overcome Multiple Sclerosis",
      userDpImg: UserDp, 
      user : "By Full Name",
      date: "01-Jan-23",
      uploadedonText: "Uploaded On:",
      supporterText: "Supporters:",
      TotalSupporters: "675",
      imgUrl: caseImg3,
     },
    {
      id: 4,
      title: "Uplifting Emily: Conquering Cerebral Palsy Together",
      userDpImg: UserDp, 
      user : "By Full Name",
      date: "01-Jan-23",
      uploadedonText: "Uploaded On:",
      supporterText: "Supporters:",
      TotalSupporters: "675",
      imgUrl: caseImg4,
     },
    {
      id: 5,
      title: "The best way Hope for Heather: Campaigning care for Terminal Illness",
      userDpImg: UserDp, 
      user : "By Full Name",
      date: "01-Jan-23",
      uploadedonText: "Uploaded On:",
      supporterText: "Supporters:",
      TotalSupporters: "675",
      imgUrl: caseImg5,
     },
  
    {
      id: 6,
      title: "Resilient with Lily: Fighting for a Life Free from Lupus",
      userDpImg: UserDp, 
      user : "By Full Name",
      date: "01-Jan-23",
      uploadedonText: "Uploaded On:",
      supporterText: "Supporters:",
      TotalSupporters: "675",
      imgUrl: caseImg6,
     },
  ];
  
  export default AllcasesData;