// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BannerSection {
  background: #014e7f;
}
.LetsConnectHeading {
  font-size: 38px;
  font-weight: 900;
  font-family: "Poppins";
  color: #ffffff;
}
.letsconnectdescription,
.FAQstxt {
  font-size: 20px;
  font-weight: 400;
  font-family: "Poppins";
}
.letsconnectdescription {
  color: #f0ecec;
}
.FAQstxt {
  color: #ffffff;
}
@media only screen and (max-width: 600px) {
  .LetsConnectHeading {
    font-size: 22px;
    font-weight: 700;
  }
  .letsconnectdescription,
  .FAQstxt {
    font-size: 16px;
  }
  .c-banner {
    padding-bottom: 0px !important;
    margin-bottom: -90px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ContactUsPage_PakistanComponents/BannerSection/Banner.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,cAAc;AAChB;AACA;;EAEE,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;EACA;;IAEE,eAAe;EACjB;EACA;IACE,8BAA8B;IAC9B,+BAA+B;EACjC;AACF","sourcesContent":[".BannerSection {\n  background: #014e7f;\n}\n.LetsConnectHeading {\n  font-size: 38px;\n  font-weight: 900;\n  font-family: \"Poppins\";\n  color: #ffffff;\n}\n.letsconnectdescription,\n.FAQstxt {\n  font-size: 20px;\n  font-weight: 400;\n  font-family: \"Poppins\";\n}\n.letsconnectdescription {\n  color: #f0ecec;\n}\n.FAQstxt {\n  color: #ffffff;\n}\n@media only screen and (max-width: 600px) {\n  .LetsConnectHeading {\n    font-size: 22px;\n    font-weight: 700;\n  }\n  .letsconnectdescription,\n  .FAQstxt {\n    font-size: 16px;\n  }\n  .c-banner {\n    padding-bottom: 0px !important;\n    margin-bottom: -90px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
