import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Container, Button } from "react-bootstrap";
import PricingPagHeaderImg from "../../../assets/images/PricingPageHeaderImg.png";
import "./HeaderImgSection.css";

const HeaderImgSection = () => {
  return (
    <>
      <Container className="HeaderImgContainer d-flex my-5  justify-content-center align-items-center">
        <Row className="py-4 px-4">
          <Col lg={6} md={12}>
            <Container className="contentContainer px-5 py-5">
              <h4 className="Heading">
                Start your Campaign today with a platform fee of
              </h4>
              <h1 className="only1Txt">ONLY 1%</h1>
              {/* <hr className="hrline"></hr> */}
              <p className="RaisemaxTxt">
                Receive maximum financial support for your cause
              </p>
              <Link to="/starCampaigningForm">
                <Button className="StartFundraisingBtn">
                  Start Campaigning
                </Button>
              </Link>
            </Container>
          </Col>
          <Col lg={6} md={12}>
            <Container>
              <img src={PricingPagHeaderImg} className="w-100"></img>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HeaderImgSection;
