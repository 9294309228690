import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const BannerSection = () => {
  return (
    <div className="about_banner_main">
      <Container>
        <div className="about_heading">
          <h4>About Us</h4>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p> */}
        </div>
      </Container>

      <div className="banner_div">
        <Container>
          <p>
            ShifaLia is a medical financing venture led by young Pakistani
            entrepreneurs with the sole purpose of making access to healthcare
            affordable for all. We at ShifaLia have put our trust in the general
            public of Pakistan and created a platform that allows generous
            Pakistanis to help out their fellow brethren without any hassle, and
            with complete safety and security. We believe that financial
            constraints should never be the reason why access to healthcare is
            hindered. Therefore, our platform functions as a bridge between
            those who need financial assistance and those who want to help out
            their fellow Pakistanis. 
          </p>
        </Container>
      </div>

      <div className="banner_footer_content">
        <Container>
          <h4
            className="text-center mb-4 about-head"
            style={{ color: "#014E7F", fontSize: "33px" }}
          >
            Our Mission and Goals
          </h4>

          <Row>
            <Col md={5}>
              <p>
                Our mission is simple. Creating a Pakistan where medical
                expenses do not force people to choose between getting treatment
                or feeding their families. This can only be achieved when
                medical expenses become independent of financial constraints by
                having the generous public chip in to paying off these
                expenses. 
              </p>
            </Col>
            <Col md={2} className="d-flex justify-content-center">
              <div></div>
            </Col>
            <Col md={5}>
              <p>
                ShifaLia has only one goal, which is to create an easy-to-use
                platform that bridges the gap between those in need of financial
                assistance and those who can support these people financially.
                We do this by creating a safe and secure environment for both
                parties. We also ensure that all our cases are authentic by
                having a rigorous verification process, alongside on-ground
                verification by ShifaLia’s Operations team. 
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BannerSection;
