import axios from "axios";
import Toast from "../../functions/Toast";
import Cookies from "universal-cookie";

export const createGigOffer = async (data) => {
  const cookies = new Cookies();
  const res = await axios
    .post(`${process.env.REACT_APP_SWAGGER_API}/patient/new-offer`, data, {
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${cookies.get("token")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.log(error);
      Toast.error(error?.response?.data?.message || "Something went wrong");
    });
  return res;
};
