import React, { useState } from "react";
import "./socialprofile.css";

import { Col, Row, Container, Button, Form, FormGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { Provider } from "../../../context/Context";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
const SocialProfile = ({ isSidebarOpen, setTab }) => {
  const navigate = useNavigate();

  const {
    campaignerWhatsAppNo,
    setcampaignerWhatsAppNo,
    WhatsAppNo,
    setWhatsAppNo,
    previousCompanies,
    setpreviousCompanies,
    caseById,
  } = Provider();

  const [valOfWhatsapp, setvalOfWhatsapp] = useState("");

  const handleAdd = (e) => {
    e.preventDefault();
    if (valOfWhatsapp == "") {
      return;
    }
    setvalOfWhatsapp("");
    setcampaignerWhatsAppNo([...campaignerWhatsAppNo, valOfWhatsapp]);
  };

  const handleSubmit = () => {
    navigate("/detailpage7");
  };
  return (
    <>
      <div
        className={`form-container ${
          isSidebarOpen ? "form-container-open" : ""
        }`}
      >
        <h3 className=" socialprofile">Social Profile</h3>
        <Container className="mt-1 mb-5 form-09">
          <Row>
            <Container className=" py-2 px-5">
              <Form>
                <FormGroup className="d-flex flex-wrap form-colom  ">
                  <Col lg={4} md={5} sm={12} xs={12} className="form-group ">
                    <div style={{}}>
                      <PhoneInput
                        type="text"
                        id="tel"
                        className="fields px-4"
                        placeholder="Campaigner WhatsApp No."
                        value={valOfWhatsapp}
                        onChange={setvalOfWhatsapp}
                      />
                      <button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginTop: "5px",
                          background: "transparent",
                        }}
                        onClick={handleAdd}
                      >
                        Add
                      </button>
                    </div>
                    <Form.Text className="Form-Text">
                      {campaignerWhatsAppNo.length >= 1
                        ? campaignerWhatsAppNo?.map((i) => <li>{i}</li>)
                        : caseById?.campaignerWhatsAppNo?.map((i) => (
                            <li>{i}</li>
                          ))}
                      Please provide an active WhatsApp number for our team to
                      contact you on.
                    </Form.Text>
                  </Col>
                  <Col lg={4} md={5} sm={12} xs={12} className="form-group ">
                    <div>
                      <PhoneInput
                        type="text"
                        id="tel"
                        className="fields px-4"
                        placeholder="Patient’s WhatsApp No."
                        value={WhatsAppNo}
                        onChange={setWhatsAppNo}
                      />
                      <Form.Text className="Form-Text">
                        Please provide the patient’s active WhatsApp number.{" "}
                      </Form.Text>
                    </div>
                  </Col>
                </FormGroup>
                {/* <FormGroup className="d-flex flex-wrap  ">
                  <Col lg={4} md={5} sm={12} xs={12} className="form-group">
                    <input
                      type=""
                      id="document"
                      className="fields px-4"
                      placeholder="Previous Companies"
                      value={previousCompanies}
                      onChange={(e) => setpreviousCompanies(e.target.value)}
                    />
                    <Form.Text className=" Form-Text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut.
                    </Form.Text>
                  </Col>
                </FormGroup> */}

                <Button
                  onClick={() => setTab("hospitaluser")}
                  className="Done-button mt-4"
                >
                  Next
                </Button>
              </Form>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SocialProfile;
