import React from "react";
import CampaignImg from "../../../assets/PreviewPageImages/preview-campaign.svg";
import Verified from "../../../assets/PreviewPageImages/verified.svg";
import PateintImg from "../../../assets/PreviewPageImages/patient-img.png";
import organizerProfile from "../../../assets/PreviewPageImages/organizerProfile.svg";
import "./donation.scss";
import { useNavigate } from "react-router-dom";

const Donation = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="donation-section">
        <p>
          Thank you for choosing us as your medical campaigning platform! We
          will get back to you as soon as we can once we are done reviewing your
          campaign. Meanwhile, please make sure that you submit all the
          necessary documents.{" "}
        </p>
        <div className="donation-hr">
          <hr />
        </div>

        <div className="help-content">
          <h1>Help us push Benjamin towards Recovery!</h1>
          <div className="medical-condition">
            Medical Condition: Brain Tumor
          </div>
        </div>

        <div className="red-div">
          <span>
            Our Team is still working on reviewing and verifying this campaign.
            Please support only if you know the campaigner/beneficiary.
          </span>
        </div>
        <div className="patient-info-section">
          <div className="left-div">
            <div className="patient-img-section">
              <div className="blue-wrap">
                <h4>BelieveingInBenjamin</h4>
                <span>
                  <img src={Verified} alt="" className="mx-2" />
                  verified
                </span>
              </div>
              <img src={PateintImg} alt="" className="patient-img" />
            </div>
            <div className="organizer-div">
              <h5>Organizer</h5>
              <div className="inner-content">
                <div className="left-content">
                  <img src={organizerProfile} alt="" />
                  <span>
                    <h6>Full Name</h6>
                    <span>Location</span>
                  </span>
                </div>
                <div className="right-content">
                  <button onClick={() => navigate("/monthlydonation")}>
                    Support
                  </button>
                  <button>Ask for Update</button>
                </div>
              </div>
            </div>
          </div>
          <div className="right-div">
            <p>
              $0 <span className="mx-2">out of 180,000</span>
            </p>
            <div className="progress progress-Bar">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                style={{ width: "2%" }}
                aria-valuemax="100"
              ></div>
            </div>
            <span>Be the first to support this campaign!</span>
            <div className="btns-div">
              <button onClick={() => navigate("/monthlydonation")}>
                Support
              </button>
              <button>Share</button>
            </div>
            <div className="supports-div">
              <h3>Supporters (0)</h3>
              <div className="campaign-div">
                <img src={CampaignImg} alt="" />
                <p>
                  The Campaign does not have any supporters yet. Support now to
                  become the first one and help inspire others!
                </p>
              </div>
            </div>
            <div className="btn-seeall">
              <button>See All</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Donation;
