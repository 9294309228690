import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import startCampaign from "../../../assets/PolicyTermsImages/start_campaign.png";
import privacyCampaign from "../../../assets/PolicyTermsImages/privacy_campaign.png";
import whatsappIcon from "../../../assets/PolicyTermsImages/whatsapp_icon.png";
import facebookIcon from "../../../assets/PolicyTermsImages/facebook_icon.png";
import "./Description.css";
import { useNavigate } from "react-router-dom";

const Description = () => {
  const navigate = useNavigate();

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const dashboardColorStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      color: "#fff",
      backgroundColor: "#014E7F",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "15px",
      boxShadow: "none",
      borderColor:
        state.isFocused || state.isHovered || state.isActive || state.onHovered
          ? "#fff"
          : "#fff",
      "&:hover": {
        borderColor: state.isFocused || state.isActive ? "#fff" : "#fff",
      },
      "&::placeholder": {
        color: "#fff", // Change placeholder color to white
      },
    }),
    option: (styles) => {
      return {
        ...styles,
        fontSize: "15px",
        color: "#000",
      };
    },
  };

  return (
    <div className="privacy_description">
      <Container>
        <Row className="align-items-center justify-content-center">
          {/* <Col md={6}>
            <Select
              options={options}
              styles={dashboardColorStyles}
              className="search_policy"
              placeholder="Search policy"
            />
          </Col> */}
          {/* <Col md={4}>
            <p className="contact_para">
              <span>Contact us</span> for further queries.
            </p>
          </Col> */}
        </Row>

        <div className="description_details">
          <h6>Last Updated: August 30th, 2023</h6>

          <div>
            <p>
              Thank you for visiting ShifaLia’s Privacy Policy. We are a company
              that deeply cares about your privacy and wants to help you
              understand how we collect, use, and share your personal
              information.
            </p>
          </div>
          <div>
            <h5>Terms used in our Policy:</h5>
            <p>
              <span>ShifaLia:</span> ShifaLia is the company providing this
              platform. Also referred to as “us”, “we”, “our”  “Platform”,
              “Site”, and the “Company”.
            </p>
            <p>
              <span>Users:</span> Users are the individuals/companies who want
              to use Our platform to create their own Campaign(s), and/or, use
              it to support other Campaign(s). Also referred to as “you”,
              “yours”, or “your”. 
            </p>
            <p>
              <span>Campaign:</span> Campaign refers to the service provided by
              ShifaLia where individuals/companies can start their own, and/or,
              support other campaigns to achieve a certain goal.  
            </p>
            <p>
              <span>Backers:</span> Backers are Users who are pledging certain
              amounts for a Campaign. They are also referred to as “Users” and
              “Supporters” in certain places.
            </p>
            <p>
              Campaigners: Campaigners refer to the Users organizing a Campaign
              on ShifaLia.
            </p>
          </div>

          <div>
            <div class="table_index">
              <div class="row_main">
                <div class="title">
                  <a href="#change">Changes to This Policy</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">3</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  <a href="#intro">1. Intro</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">3</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  <a href="#info">2. Information We Collect</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">3</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  <a href="#howto">3. How We Use Your Information</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">4</div> */}
              </div>
              <div className="row_inner">
                <div class="row_main">
                  <div class="title">
                    <a href="#sharedinfo">
                      3.1 Information That’s Shared Publicly
                    </a>
                  </div>
                  <div class="dots"></div>
                  {/* <div class="value">5</div> */}
                </div>
                <div class="row_main">
                  <div class="title">
                    <a href="#notsharedinfo">
                      3.2 Information That Is Not Shared Publicly
                    </a>
                  </div>
                  <div class="dots"></div>
                  {/* <div class="value">6</div> */}
                </div>
                <div class="row_main">
                  <div class="title">
                    <a href="#threethree">
                      3.3 Information Shared with Trusted Third-Party Services
                    </a>
                  </div>
                  <div class="dots"></div>
                  {/* <div class="value">6</div> */}
                </div>
                <div class="row_main">
                  <div class="title">
                    <a href="#34">3.4 Information Shared with Campaigners</a>
                  </div>
                  <div class="dots"></div>
                  {/* <div class="value">6</div> */}
                </div>
                <div class="row_main">
                  <div class="title">
                    <a href="#35">
                      3.5 Information Shared to Protect ShifaLia and Comply With
                      the Law
                    </a>
                  </div>
                  <div class="dots"></div>
                  {/* <div class="value">6</div> */}
                </div>
                <div class="row_main">
                  <div class="title">
                    <a href="#36">3.6 Links to Other Websites and Services</a>
                  </div>
                  <div class="dots"></div>
                  {/* <div class="value">6</div> */}
                </div>
              </div>
              <div class="row_main">
                <div class="title">
                  <a href="#4">4. Your Rights</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">7</div> */}
              </div>
              <div className="row_inner">
                <div class="row_main">
                  <div class="title">
                    <a href="#42">4.1. Privacy Rights Disclosure and Request</a>
                  </div>
                  <div class="dots"></div>
                  {/* <div class="value">7</div> */}
                </div>
                <div class="row_main">
                  <div class="title">
                    <a href="#43">
                      4.2. Categories of Personal Information We Collect
                    </a>
                  </div>
                  <div class="dots"></div>
                  {/* <div class="value">7</div> */}
                </div>
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#5">5. Security</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">8</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#6">6. Children</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">8</div> */}
              </div>
            </div>
          </div>

          <div>
            <h5 id="change">Changes to This Policy</h5>
            <p>
              We may update this Policy from time to time. If we do, we’ll let
              you know about any material changes, either by notifying you on
              the ShifaLia Site (the “Site”) or by sending you an email. New
              versions of this Policy will never apply retroactively—we’ll tell
              you the exact date they go into effect. If you keep using Our
              platform after a change, that means you accept this Policy.
            </p>
          </div>
          <div>
            <h5 id="intro">1. Intro</h5>
            <p>
              ShifaLia is a medical campaigning platform that allows its Users
              to gain financial assistance for their medical expenses by
              starting campaigns on our Platform. Users can also choose to
              support other campaigns on this Platform. We are dedicated to
              protecting your privacy and safeguarding your personally
              identifiable information.
            </p>
            <p className="mt-4">
              This Privacy Policy describes how we collect, use, and share
              information about you that we obtain through your use of our
              Platform. By using our Platform, you accept and consent to the
              practices described in this Privacy Policy (including as changed
              from time to time). Your use of our website is also governed by
              our <Link to="/termsOfUse">Terms of Service</Link> which are to be
              read in conjunction with this policy. If, at any time, or for any
              reason, you disagree with our policy, we recommend you stop using
              the services provided by ShifaLia.
            </p>
          </div>
          <div>
            <h5 id="info">2. Information We Collect</h5>
            <p>
              ShifaLia collects information in the following ways: <br />
            </p>
            <p style={{ paddingLeft: "10px", marginTop: "10px" }}>
              {" "}
              <span>A)</span> When Users provide information directly to us
              (when setting up an account). For you to create an account on our
              Platform and use our services, we need to collect and process
              certain information. Depending on your use of our Platform, that
              may include:
            </p>
            <p style={{ paddingLeft: "20px", marginTop: "10px" }}>
              i. Information you provide while signing up for an account on
              ShifaLia — your name, email and postal addresses, telephone
              number, country of residence, login, and password details. We may
              ask for this information if you register as a User to create your
              Campaign, and/or, support another Campaign.
            </p>

            <p style={{ paddingLeft: "20px", marginTop: "10px" }}>
              ii. Information you provide for identity verification purposes
              when launching a Campaign includes your legal name, business name
              Tax ID for business entities, and date of birth. In some cases,
              our payment processor will request that you provide a secure
              upload of an identity document (such as your passport, driver's
              license, or other government-issued ID) to ShifaLia.
            </p>

            <p style={{ paddingLeft: "20px", marginTop: "10px" }}>
              {" "}
              iii. Details of any requests or transactions you make through the
              Platform. ShifaLia partners with other companies (such as Stripe)
              for payment processing, and the payment information you submit is
              collected and used by them in accordance with their privacy
              policies (
              <a href="https://stripe.com/privacy" target="_blank">
                read Stripe's privacy policy)
              </a>
              . ShifaLia doesn’t store your payment information apart from the
              last four digits of your credit card or bank account (as
              applicable), expiration date, and country, which we require for
              tax, government regulatory, and security purposes;
            </p>
            <p style={{ paddingLeft: "20px", marginTop: "10px" }}>
              iv. Your communications to ShifaLia (for example, when you ask for
              support, send us questions or comments, or report a problem); and
            </p>
            <p style={{ paddingLeft: "20px", marginTop: "10px" }}>
              v. Information that you submit on or to ShifaLia’s platform, such
              as comments, contributions to discussions, or messages to other
              users
            </p>
            <p style={{ paddingLeft: "20px", marginTop: "10px" }}>
              You may decline to provide us with your information. However, this
              will limit your ability to register for an account or use our
              Platform.
            </p>

            <p style={{ paddingLeft: "10px", marginTop: "10px" }}>
              {" "}
              <span>B)</span> We passively collect information from you (such as
              from your browser), and
            </p>
            <p style={{ paddingLeft: "10px", marginTop: "10px" }}>
              <span>C)</span> From third parties (such as ShifaLia’s trusted
              payment provider).
            </p>
          </div>
          <div>
            <h5 id="howto">3. How We Use Your Information</h5>
            <p>
              The information will be provided by the Users will be utilized by
              ShifaLia for the following purposes:
            </p>
            <p>
              <span>A)</span> To enable us to provide you with our Services, and
              to improve and promote our Services.
            </p>
            <p>
              <span>B)</span> To communicate with you about your account or
              contributions on our Site and send you information or request
              feedback.
            </p>
            <p>
              <span>C)</span> For our marketing and third-party marketing
              purposes: to send you targeted offers and promotions for our
              Platform and/or the Campaigns that are active on our Site.
            </p>
            <p>
              <span>D)</span> Personalize the Platform, content, and
              experiences.
            </p>
            <p>
              <span>E)</span> Operate, understand, optimize, develop, or improve
              the Platform, services, and operations, including by performing
              User market research and using analytics tools.
            </p>
            <p>
              <span>F)</span> Bug detection and error reporting.
            </p>
            <p>
              <span>G)</span> Audit interactions on the Platform including
              measuring the placement, frequency, efficacy, and compliance of
              advertising and ad impressions.
            </p>
            <p>
              <span>H)</span> Security, Fraud, and Legal Compliance, including
              detecting, investigating, and preventing activities that may
              violate our policies or be illegal.
            </p>
            <p>
              <span>I)</span> We may aggregate and/or anonymize any information
              collected through the Service so that such information doesn’t
              directly identify you. (“Aggregate/Anonymous Information”). We may
              use Aggregate/Anonymous Information for any purpose and share such
              data with third parties, including advertisers.
            </p>

            <p style={{ margin: "20px 0" }}>
              ShifaLia only shares your valuable information under certain
              circumstances.  ShifaLia will seek to share the minimum amount
              necessary where your data is shared with third parties. The
              circumstances in which ShifaLia may share your information are as
              follows:
            </p>

            <p>
              <span>J)</span> Consent: ShifaLia may share your information with
              third parties based on your consent to do so
            </p>
            <p>
              <span>K)</span> Service Providers: We may share your information
              with third parties who perform services on our behalf. This
              includes, but is not limited to recruitment, marketing, customer
              support, legal services, data storage, data analysis, and
              processing.
            </p>
            <p>
              <span>L)</span> Affiliates: We may share your information with
              current or future affiliated companies, including but not limited
              to business transactions, such as corporate divestiture, merger,
              consolidation, acquisitions, reorganization, or other disposition
              of all or any portion of the business or assets of, or equity
              interests in, or our affiliates (including in connection with a
              bankruptcy or similar proceeding).
            </p>
            <p>
              <span>M)</span> Aggregated or De-identified Information: To
              personalize your experience, we may share de-identified and/or
              aggregated data with third parties, including advertisers,
              promotional partners, and/or others.
            </p>
            <p style={{ margin: "20px 0" }}>
              For example, we use identity, device, and location information to
              prevent fraud and abuse and to keep ShifaLia and our Platform
              secure. 
            </p>
            <p style={{ margin: "20px 0" }}>
              ShifaLia analyzes how our Users interact with our Site so that we
              can better understand what elements of the design are working well
              and which elements are lacking behind in providing the best
              possible user experience. This allows us to improve and develop
              the quality of the online experience we offer all our Users.
            </p>

            <h6 style={{ margin: "20px 0" }} id="sharedinfo">
              3.1 Information That’s Shared Publicly
            </h6>

            <p>
              When you create an account, we create a basic profile page for you
              on ShifaLia, containing your username, the date the account was
              created, and a list of projects you have backed or launched. By
              default, your profile is private and you can choose to make it
              public in your account settings. Your username appears with the
              comments you post and messages you send on ShifaLia and, if your
              profile is set to public, people can click your username to view
              it. If you make your profile public, others will be able to see
              the following things:
            </p>

            <p>
              <span>a)</span> The account username you select, and the date the
              account was created;
            </p>
            <p>
              <span>b)</span> Any information you choose to add to your profile
              (like a picture, bio, your location, etc);
            </p>
            <p>
              <span>c)</span> Campaigns you’ve backed (the amount pledged to
              each Campaign will not be shared);
            </p>
            <p>
              <span>d)</span> Campaigns you’ve created;
            </p>
            <p style={{ margin: "20px 0" }}>
              Your interactions with our Campaigns in the form of comments and
              words of encouragement. 
            </p>

            <p>
              If you would like to have a public profile, but don’t want others
              to see your name or profile image on a project’s Community tab,
              you may choose an account image or name that is not identifiable
              (e.g., Pakistani47). You can modify or delete certain information
              associated with your account (such as your username, profile
              image, biography and location) from your account settings, and you
              can change your privacy settings if you want to make your profile
              private or adjust the default settings.
            </p>
            <p>
              Users are asked to verify their identities before launching a
              project. Once this has been done, the User’s Verified Name will be
              publicly displayed on their account profile and on any projects
              they launch.
            </p>
            <p>
              When a Backer pledges to a project, the Campaign organizer will
              receive information about the Backer, including things like name
              and amount of pledge. If the Campaign is successful, Campaigners
              receive limited Backer information, such as an email address. This
              allows Campaigners to send out a custom survey to obtain a postal
              address to help mail rewards the backer requested.
            </p>

            <h6 style={{ margin: "20px 0" }} id="notsharedinfo">
              3.2 Information That Is Not Shared Publicly
            </h6>

            <p>
              The following data will not be publicly displayed or revealed to
              other users:
            </p>
            <p>a) Any payment information you provide;</p>
            <p>b) Your password details;</p>
            <p>c) Your IP address;</p>
            <p>d) Your phone number;</p>
            <p>
              e) Your date of birth and other identity verification
              documentation (for creators);
            </p>
            <p>
              f) Private profile information, in accordance with your
              preferences; and
            </p>
            <p>
              g) Communications you send to us (for example, when you ask for
              support, send us questions or comments, or report a problem);
            </p>

            <h6 style={{ margin: "20px 0" }} id="threethree">
              3.3 Information Shared with Trusted Third-Party Services
            </h6>

            <p>
              We may share your information with certain trusted third-party
              services to help us provide, improve, promote, or protect ShifaLia
              (like when we partner with payment processors or use services that
              help us manage our ads on other sites). We may also share
              information that’s aggregated and anonymized in a way that doesn’t
              directly identify you.
            </p>

            <h6 style={{ margin: "20px 0" }} id="34">
              3.4 Information Shared with Campaigners 
            </h6>

            <p>
              Campaigners are central to ShifaLia as they provide the Campaigns
              that drive our mission. Given that responsibility, Campaigners are
              bound by this Privacy Policy and shall not sell or share any
              personal information except for fulfillment purposes.
            </p>

            <h6 style={{ margin: "20px 0" }} id="35">
              3.5 Information Shared to Protect ShifaLia and Comply With the Law
            </h6>

            <p>
              We do reserve the right to disclose personal information when we
              believe that doing so is reasonably necessary to comply with the
              law or law enforcement, to prevent fraud or abuse, or to protect
              ShifaLia’s legal rights, property, or the safety of ShifaLia, its
              employees, users, or others.
            </p>

            <h6 style={{ margin: "20px 0" }} id="36">
              3.6 Links to Other Websites and Services
            </h6>

            <p>
              Our Site may include links to other websites or services whose
              privacy practices may differ from ShifaLia. When you use a link to
              an external site or service, the privacy policy and data
              processing disclosures for that site or service governs.
            </p>
          </div>

          <div>
            <h5 id="4">4. Your Rights </h5>

            <p>
              Users residing in certain jurisdictions, such as the EU and
              California, are afforded certain rights regarding their personal
              information. Except where an exception or exemption applies, these
              rights include the ability to access, correct, and request the
              deletion of your personal information.
            </p>
            <p>
              While these rights are not applicable globally, all ShifaLia Users
              can manage their personal information in their account settings.
              You can request a downloadable copy of your personal data from
              ShifaLia from your account settings page. To modify or delete the
              personal information you’ve provided to us, please log in and
              update your profile. You may also delete your account from your
              account settings page. We may retain certain information as
              required by law or as necessary for our legitimate business
              purposes.
            </p>

            <h6 style={{ margin: "20px 0" }} id="42">
              4.1. Privacy Rights Disclosure and Request
            </h6>

            <p>
              Certain jurisdictions allow you or an authorized agent on your
              behalf to make certain requests about your personal information.
              Specific jurisdictions, as noted below, provide additional rights
              where applicable.
            </p>

            <h6 style={{ margin: "20px 0" }} id="43">
              4.2. Categories of Personal Information We Collect 
            </h6>

            <p style={{ margin: "20px 0" }}>
              Throughout this Policy, we discuss in detail the specific pieces
              of personal information we collect from and about users. We are
              also required to provide you with the “categories” of personal
              information we collect. The categories, and some examples of what
              we may collect, are:
            </p>

            <p>
              a) Identifiers and contact or registration information, e.g., your
              name, date of birth, email address, postal address, telephone
              number, country of residence, wireless device identifier, account
              username and password, and government-issued ID (Pakistan’s
              National Identity Card, passport number, etc.)
            </p>
            <p>
              b) Statistical information, e.g., marital status, education,
              professional or employment-related information, interests,
              lifestyle information, and hobbies.
            </p>
            <p>
              c) Commercial and financial information, e.g., business name; tax
              identification numbers; transaction history, expiration date, and
              last four digits of credit/debit card number; and transaction
              history, country of, and last four digits of bank account.
            </p>
            <p>
              d) User-generated content, e.g., photos, videos, audio, any
              information you submit in public forums or message boards, and
              feedback or testimonials you provide about our Platform.
            </p>
            <p>
              e) Customer service information, e.g., questions and other
              messages you address to us directly through online forms, by
              email, over the phone, or by post; and summaries of your
              interactions with the ShifaLia Support Team.
            </p>
            <p>
              f) Device information and device identifiers, e.g., IP address;
              browser type and language; operating system; platform type; device
              type; software and hardware attributes; and unique device,
              advertising, and app identifiers.
            </p>
            <p>
              g) Connection and usage data, e.g., information about files you
              download, domain names, landing pages, browsing activity, content
              or ads viewed and clicked, dates and times of access, pages
              viewed, forms you complete or partially complete, search terms,
              uploads or downloads, whether you open an email and your
              interaction with email content, access times, error logs, and
              other similar information.
            </p>
            <p>
              h) Information used to administer ShifaLia Copyright under
              legalities associated with Pakistan’s Copyright Ordinance, 1962,
              including any information necessary to handle counter-notices and
              copyright takedowns.
            </p>
            <p>
              i) Other Information, e.g., any other information you choose to
              directly provide to us in connection with your use of the
              Platform.
            </p>

            <p style={{ margin: "20px 0" }}>
              Certain laws permit you or an authorized agent on your behalf to
              make certain requests about your personal information. Where
              applicable law provides, an agent may request the following on
              your behalf:
            </p>

            <p>
              j) Information about the categories of personal information we
              collect or disclose about you; the categories of sources of such
              information; the business or commercial purpose for collecting
              your personal information; the categories of third parties with
              whom we share/disclose personal information. Such information is
              also set forth in this Policy.
            </p>
            <p>
              k) Access to and/or a copy of certain information we hold about
              you.
            </p>
            <p>l) Deletion of certain information we have about you.</p>
            <p>
              m) Correction of inaccurate personal information we have about
              you.
            </p>
            <p>
              n) Information about the financial incentives (like benefits or
              payments) that we offer to you, if any.
            </p>

            <p style={{ margin: "20px 0" }}>
              Please note that certain information may be exempt from such
              requests. For example, we need certain information in order to
              provide the Services to you. We also will take reasonable steps to
              verify your identity before responding to a request. You are also
              permitted to designate an authorized agent to subject certain
              requests on your behalf. To verify that your agent has been
              authorized to make a request on your behalf, we may require them
              to provide us with a signed written authorization or a copy of a
              power of attorney.
            </p>

            <p style={{ margin: "20px 0" }}>
              Various jurisdictions set forth certain obligations for businesses
              that sell or share personal information with third parties.
            </p>
          </div>

          <div>
            <h5 id="5">5. Security</h5>

            <p>
              Security of your date is of utmost importance to us. We follow the
              industry-standard practices to protect the data we collect and
              maintain, however, no method of transmission over the internet or
              electronic storage is completely secure, therefore, ShifaLia can
              not guarantee its absolute security. Your account information is
              protected by a password, which you should choose carefully and
              keep secure. We encourage all users to enable two-factor
              authentication in account settings to further protect their
              account. 
            </p>
          </div>

          <div>
            <h5 id="6">6. Children</h5>

            <p>
              People under the age of 18 (or the legal age in your jurisdiction)
              are not permitted to use ShifaLia on their own. We do not permit
              the children under the age of 16 to register on our Platform,
              therefore we do not knowingly collect any personal information
              from children under the age of 16. If you believe that a child has
              provided us with personal information, please contact us at 
              <a href="mailto:example@example.com">info@shifalia.com</a>.
            </p>
          </div>
        </div>

        <div className="mt-5">
          <Row>
            <Col md={7}>
              <div className="privacy_campaign">
                <Row className="align-items-end">
                  <Col md={7}>
                    <h6>Ready to start your own Campaign?</h6>
                    <button onClick={() => navigate("/starCampaigningForm")}>
                      Start Campaigning
                    </button>
                  </Col>
                  <Col md={5}>
                    <img src={privacyCampaign} alt="" />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={5}>
              <div className="start_campaign">
                <h6>
                  Confused about how to <br /> start a Campaign?
                </h6>
                <button onClick={() => navigate("/starCampaigningForm")}>
                  Check Here
                </button>

                <img src={startCampaign} alt="" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="have_question">
          <Row className="align-items-center">
            <Col md={8} style={{ marginBottom: "10px" }}>
              <p>
                Have a question? Check out our FAQs page or chat with us on
                Facebook or WhatsApp.
              </p>
            </Col>
            <Col md={4}>
              <div className="questions_btn">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://wa.me/+923323165701"
                >
                  {" "}
                  <img src={whatsappIcon} alt="" /> Contact Us
                </a>
                <a
                  href="https://www.facebook.com/people/ShifaLia/100089498583736/?mibextid=LQQJ4d"
                  target="_blank"
                >
                  {" "}
                  <img src={facebookIcon} alt="" /> Visit our page
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Description;
