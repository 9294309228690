import React, { useState } from "react";
import "./View.css";
import userPic from "../../../../assets/images/UserDp.png";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { sendProposal } from "../../../../services/http/proposalApi";

const View = ({ closeFooterModal, offer }) => {
  const { user } = useSelector((state) => state.user);

  const [proposal, setproposal] = useState("");
  const [quotation, setquotation] = useState("");
  const [proposalDocs, setproposalDocs] = useState([]);

  const data = {
    offerId: offer?._id,
    proposal,
    quotation,
    proposalDocs,
  };
  const handleProposal = async () => {
    const res = await sendProposal(data);
    console.log(res, "propoasl");
  };

  return (
    <div style={{ overflowY: "auto" }}>
      <div className="Modalwrapper"></div>
      <div className="Modal_C">
        <FontAwesomeIcon
          icon={faTimes}
          className="closeIcon_"
          onClick={closeFooterModal}
        />
        <div className="view_main">
          <h4>{offer?.title}</h4>
          <div className="view_user">
            <span>
              <img
                src={offer?.userDetails[0]?.profileImageLink || userPic}
                alt=""
              />
            </span>
            <div className="user_details_view">
              <h6>{offer?.userDetails[0]?.firstName}</h6>
              <p>{offer?.userDetails[0]?.email}</p>
            </div>
          </div>
          <div className="desc_view">
            <h6>Description of the project</h6>
            <p>{offer?.description}</p>
          </div>
          <div className="docs_view">
            <h6>Documents/Images</h6>
            {offer?.ImageLinks?.map((item) => (
              <div className="doc_view">
                <img height={50} width={50} src={item} alt="" />
                <a href={item} download={true} target="blank">
                  Download
                </a>
              </div>
            ))}
          </div>
          {user?.role == "patient" && (
            <div className="porposal_view">
              <h6>Your Proposal:</h6>
              <textarea
                value={proposal}
                onChange={(e) => setproposal(e.target.value)}
                placeholder="Enter your porposal"
              ></textarea>
              <input
                onChange={(e) => setproposalDocs(e.target.files[0])}
                type="file"
              />
              <input
                value={quotation}
                onChange={(e) => setquotation(e.target.value)}
                type="text"
                placeholder="Quotation for project"
              />
            </div>
          )}

          {user?.role == "donar" && offer?.status == "APPROVED" && (
            <div className="porposal_view">
              <h6>Proposal:</h6>
              <span>{offer?.proposal}</span>
              <h6>Proposal documents/images</h6>
              {offer?.proposalDocs?.map((item) => (
                <img height={50} width={50} src={item} alt="" />
              ))}
              <h6>Quotation:</h6>
              <span>{offer?.quotation} Rs.</span>
            </div>
          )}

          <div className="view_last_btn">
            <button onClick={closeFooterModal}>Cancel</button>
            {user?.role == "patient" && (
              <button onClick={handleProposal}>Send to supporter</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;
