import React, { useRef, useState } from "react";
import "../Social Profile/socialprofile.css";
import { Col, Row, Container, Button, Form, FormGroup } from "react-bootstrap";
import FileUploader from "../../fileuploaderbtn/FileUploader.jsx";
import { useNavigate } from "react-router-dom";
import Toast from "../../../functions/Toast.js";
import { Provider } from "../../../context/Context.jsx";
import { BsPlus } from "react-icons/bs";
import "./fundraise.css";
import Loader from "../../Loader/Loader.jsx";
import { GET_DICTIONARY_QUERY } from "../../../services/methods/Query.js";
import { useQuery } from "@apollo/client";
// import { BsPlus } from 'react-icons/bs';
const FundraiseDetails = ({ isSidebarOpen, loading, setTab }) => {
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const {
    setcustomShortLinks,
    setpicturesLinks,
    setvideoLinks,
    title,
    setTitle,
    goalAmount,
    setGoalAmount,
    medicalCondition,
    setmedicalCondition,
    description,
    setDescription,
    categoryId,
    setcategoryId,
    caseById,
  } = Provider();
  const navigate = useNavigate();

  const { data } = useQuery(GET_DICTIONARY_QUERY);
  const arrOfData = data?.getDictionary?.data;

  function checkGroup(arrOfData) {
    return arrOfData.group === "Categories";
  }

  const filterData = arrOfData?.filter(checkGroup);

  const [prevPic, setPrevPic] = useState([]);
  const [prevVideo, setPrevVideo] = useState([]);
  const [prevCusLinks, setPrevCusLinks] = useState([]);

  const handlePictureLinks = (e) => {
    setpicturesLinks(e.target.files);
    const files = Array.from(e.target.files);
    setPrevPic([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPrevPic((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleVideoLinks = (e) => {
    setvideoLinks(e.target.files);
    const files = Array.from(e.target.files);
    setPrevVideo([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPrevVideo((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  // const handleCustomLinks = (e) => {
  //   setcustomShortLinks(e.target.files);
  //   const files = Array.from(e.target.files);
  //   setPrevCusLinks([]);
  //   files.forEach((file) => {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       if (reader.readyState === 2) {
  //         setPrevCusLinks((old) => [...old, reader.result]);
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };

  // const handleSubmit = () => {
  //   navigate("/detailpage3");
  // };

  return (
    <>
      <div
        className={`form-container ${
          isSidebarOpen ? "form-container-open" : ""
        }`}
      >
        <h3 className="socialprofile">Campaign Detail</h3>
        <Container className="mb-5 form-09">
          <Row>
            <Container className=" pb-4  px-5">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Form>
                    <FormGroup className="d-flex flex-wrap form-colom ">
                      <Col lg={4} md={5} sm={5} xs={5} className="form-group">
                        <div className="multiform-select-inner mb-5">
                          <h5>My campaign is for my</h5>
                          <select
                            onChange={(e) => setcategoryId(e.target.value)}
                          >
                            {filterData &&
                              filterData.map((item) => (
                                <>
                                  <option selected disabled hidden>
                                    {caseById?.categoryDetails[0]?.code}
                                  </option>
                                  <option value={item._id}>{item.code}</option>
                                </>
                              ))}
                          </select>
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup className="d-flex flex-wrap form-colom ">
                      <Col lg={4} md={5} sm={5} xs={5} className="form-group">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef1.current?.click();
                          }}
                          className="button-upload"
                        >
                          <span className="Text-photo d-flex ">Add Photo</span>
                          <BsPlus size="30px" className="plus-icoon" />
                        </button>
                        <input
                          type="file"
                          id="img"
                          className="fields px-4"
                          placeholder="Add Photo"
                          accept="image/*"
                          multiple
                          style={{ display: "none" }}
                          ref={fileInputRef1}
                          onChange={handlePictureLinks}
                        />
                        <div>
                          {prevPic.length >= 1
                            ? prevPic?.map((i) => (
                                <img className="edit_img_prev" src={i} />
                              ))
                            : caseById?.picturesLinks?.map((i) => (
                                <img src={i} className="edit_img_prev" />
                              ))}
                        </div>
                        <Form.Text className="Form-Text">
                          People find it easier to entrust their campaign with a
                          cause that they can see for themselves. This is why,
                          to receive maximum donations, please add a picture of
                          the patient these campaign are being raised for.
                        </Form.Text>
                      </Col>
                      <Col lg={4} md={5} sm={6} xs={12} className="form-group ">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef2.current?.click();
                          }}
                          className="button-upload"
                        >
                          <span className="Text-photo d-flex ">Add Video</span>
                          <BsPlus size="30px" className="plus-icoon" />
                        </button>
                        <input
                          type="file"
                          id="img"
                          className="fields px-4"
                          multiple
                          style={{ display: "none" }}
                          ref={fileInputRef2}
                          onChange={handleVideoLinks}
                        />
                        <div>
                          {prevVideo.length >= 1
                            ? prevVideo?.map((i) => (
                                <img className="edit_img_prev" src={i} />
                              ))
                            : caseById?.videoLinks?.map((i) => (
                                <img src={i} className="edit_img_prev" />
                              ))}
                        </div>
                        <Form.Text className="Form-Text">
                          Have a video showcasing the patient talking about
                          their case? Add it here. (Optional)
                        </Form.Text>
                      </Col>
                    </FormGroup>
                    <FormGroup className="d-flex flex-wrap form-colom   ">
                      <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                        <div>
                        <label>Description</label>
                          <input
                            type="text"
                            className="fields px-4"
                            placeholder="Description of the case"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                          <Form.Text className="Form-Text">
                            Please write a detailed description of the case
                            while highlighting all the important aspects
                            including, medical problems, financial background of
                            the patient, reason for this medical campaign, etc.
                            The more convincing your story, the more donors your
                            cause will attract.
                          </Form.Text>
                        </div>
                      </Col>
                      <Col lg={4} md={5} sm={6} xs={12} className="form-group ">
                      <label>Goal Amount</label>

                        <input
                          type="number"
                          id="number"
                          className="fields px-4"
                          placeholder="Goal Amount"
                          value={goalAmount}
                          onChange={(e) => setGoalAmount(e.target.value)}
                        />
                        <br></br>
                        <Form.Text className="Form-Text">
                          Please enter the amount you are trying to raise for
                          this cause.
                        </Form.Text>
                      </Col>
                    </FormGroup>
                    <FormGroup className="d-flex flex-wrap form-colom ">
                      {/* <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                    <input
                      type="text"
                      id="img"
                      className="fields px-4"
                      placeholder="Fundraise Details"
                      // value={details}
                      // onChange={(e) => setDetails(e.target.value)}
                    />
                    <Form.Text className="Form-Text">
                      Please enter the amount you are trying to raise for this
                      cause.
                    </Form.Text>
                  </Col> */}
                      <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                        <div>
                      <label>Medical Condition</label>

                          <input
                            type="text"
                            className="fields px-4"
                            placeholder="Medical Condition"
                            value={medicalCondition}
                            onChange={(e) =>
                              setmedicalCondition(e.target.value)
                            }
                          />
                          <Form.Text className="Form-Text">
                            Please enter the medical condition the patient is
                            suffering from.
                          </Form.Text>
                        </div>
                      </Col>

                      <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                        <div>
                        <label>Title</label>

                          <input
                            type="text"
                            className="fields px-4"
                            placeholder="Campaign Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          <Form.Text className="Form-Text">
                            Please enter a short title for this fundraiser. For
                            example: “Support Patient XYZ” .
                          </Form.Text>
                        </div>
                      </Col>
                    </FormGroup>
                  </Form>

                  <Button
                    onClick={() => setTab("medical")}
                    className="Done-button mt-2"
                    type="submit"
                  >
                    Next
                  </Button>
                </>
              )}
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default FundraiseDetails;
