import React, { Fragment, useEffect, useRef, useState } from "react";
import "../Auth.css";
import { Container } from "react-bootstrap";
import Logo from "../../../assets/images/Logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { successNotify } from "../../../services/helper/helper";
import { useApi } from "../../../services/mutation&Query/GraphqlApi";
import { OTP_SEND } from "../../../services/methods/Mutations";
import { useMutation } from "@apollo/client";

const VerifyAccount = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("verify");

  const [otpsend] = useMutation(OTP_SEND);
  const { OtpSendMutation } = useApi();

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [activeIndex, setActiveIndex] = useState(null);

  const inputRef = useRef(null);

  const handleChange = ({ target }, index) => {
    const { value } = target;
    const newOTP = [...otp];
    newOTP[index] = value.substring(value.length - 1);
    setOtp(newOTP);
    if (!value) {
      setActiveIndex(index - 1);
    } else {
      setActiveIndex(index + 1);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeIndex]);
  console.log(otp);

  const verifyHandler = async () => {
    const val = otp.join("");
    const otpCode = Number(val);
    const data = { email, otpCode };
    setLoading(true);
    const res = await OtpSendMutation(otpsend, data);
    setLoading(false);
    if (res?.forgetOTPSend?.success) {
      navigate(`/change-password?token=${res.forgetOTPSend.data}`);
    }
  };

  const resendCode = () => {
    if (email === "email") successNotify("Email has been resent!");
    // if (verifyParam === "phone") successNotify("Code has been resent!");
  };

  return (
    <div className="forget_password">
      <Container>
        <a className="navbar-brand" href="/">
          <img src={Logo} alt="" className="img-fluid" />
          <p>ShifaLia</p>
        </a>

        <div className="forget_password_box verify_account mt-5">
          <h5>Let’s verify your account</h5>
          <p className="enter_code">
            Enter the code that we sent to your{" "}
            {email === "email" ? "email address" : "phone Number"}{" "}
          </p>

          <div className="otp_input">
            {otp.map((_, index) => (
              <Fragment key={index}>
                <input
                  disabled={loading}
                  required
                  ref={index === activeIndex ? inputRef : null}
                  type="number"
                  onChange={(e) => handleChange(e, index)}
                  value={otp[index]}
                />
              </Fragment>
            ))}
          </div>

          {loading ? (
            <button>Verifying....</button>
          ) : (
            <button onClick={verifyHandler} className="mt-4">
              Verify account
            </button>
          )}

          <p className="didnt_receive">
            Did not receive the code?
            <span style={{ cursor: "pointer" }} onClick={resendCode}>
              {" "}
              Resend code
            </span>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default VerifyAccount;
