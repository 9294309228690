import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import Cookies from "universal-cookie";
const httpLink = new HttpLink({
  uri: "https://backend.shifalia.com/graphql",
});

const cookies = new Cookies();

const wsLink = new GraphQLWsLink(createClient({
  url: 'wss://backend.shifalia.com/graphql',
  connectionParams: () => {
    return {
      Authorization: cookies.get("token"),
    };
  },
  on: {
    connected: () => console.log('WebSocket connected'),
    closed: () => console.log('WebSocket closed'),
    error: (error) => console.error('WebSocket error', error),
  }
}));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    console.log(query,definition.operation,'def');
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

export default client;
