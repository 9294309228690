import React, { useState } from "react";
import "./background.css";
import { Col, Row, Container, Button, Form, FormGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { Provider } from "../../../context/Context";
import Toast from "../../../functions/Toast";
const BackGround = ({ isSidebarOpen, setTab }) => {
  const navigate = useNavigate();
  const {
    relationWithPatient,
    setrelationWithPatient,
    monthlyIncome,
    setmonthlyIncome,
    howDoYouKnowAboutANF,
    sethowDoYouKnowAboutANF,
    depandant,
    setdepandant,
    totalEarners,
    settotalEarners,
    assets,
    setassets,
    patientHouse,
    setpatientHouse,
    patientLivingIn,
    setpatientLivingIn,
    isZakatEligible,
    setisZakatEligible,
  } = Provider();

  // for none
  const [NassetsType, setNAssetsType] = useState("");

  const [carsNum, setCarsNum] = useState(0);
  const [bikesNum, setBikesNum] = useState(0);


  const handleInputChange = (index, field, value) => {
    const updatedEarners = totalEarners?.map((earner, i) =>
      i === index ? { ...earner, [field]: value } : earner
    );
    settotalEarners(updatedEarners);
  };

  const addEarner = (e) => {
    e.preventDefault();
    settotalEarners([...totalEarners, { relation: "", income: "" }]);
    console.log(totalEarners);
  };

  const handleInputChangeofDependant = (index, field, value) => {
    const updateDepandant = depandant?.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    setdepandant(updateDepandant);
  };

  const addDepandant = (e) => {
    e.preventDefault();
    setdepandant([...depandant, { age: "", isStudent: "", instituteName: "" }]);
    console.log(depandant);
  };

  const handleSubmit = () => {
    // if (!relationWithPatient || !monthlyIncome || !howDoYouKnowAboutANF) {
    //   Toast.error("Please provide all fields");
    // }
    navigate("/detailpage6");
  };
  return (
    <>
      <div
        className={`form-container ${
          isSidebarOpen ? "form-container-open" : ""
        }`}
      >
        <h3 className=" socialprofile">Background</h3>
        <Container className="mt-1 mb-5 form-09">
          <Row>
            <Container className=" py-2 px-5">
              <Form>
                <FormGroup className="d-flex flex-wrap form-colom  ">
                  <Col lg={4} md={4} sm={12} xs={12} className="form-group ">
                  <label>Relation with Patient</label>

                    <input
                      type="text"
                      id="text"
                      className="fields px-4"
                      placeholder="Relation with Patient"
                      value={relationWithPatient}
                      onChange={(e) => setrelationWithPatient(e.target.value)}
                    />
                    {/* <Form.Text className="Form-Text">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut.{" "}
                    </Form.Text> */}
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12} className="form-group ">
                    <div>
                  <label>Monthly Income</label>

                      <input
                        type="number"
                        id="document"
                        className="fields px-4"
                        placeholder="Total Monthly Income"
                        value={monthlyIncome}
                        onChange={(e) => setmonthlyIncome(e.target.value)}
                      />
                      <Form.Text className="Form-Text">
                        Total income per month of the patient/patient’s husband,
                        or guardian
                      </Form.Text>
                    </div>
                  </Col>
                </FormGroup>
                Total Earner(s):
                {totalEarners.map((earner, index) => (
                  <FormGroup
                    key={index}
                    className="d-flex flex-wrap form-colom"
                  >
                    <Col lg={4} md={4} sm={12} xs={12} className="form-group">
                      <div className="total_earners_tab1">
                        <span className="total_earners">{index + 1}</span>
                        <input
                          type="text"
                          className="fields px-4"
                          placeholder="Relation with Patient"
                          value={earner.relation}
                          onChange={(e) =>
                            handleInputChange(index, "relation", e.target.value)
                          }
                        />
                      </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="form-group">
                      <div className="total_earners_tab2">
                        <input
                          type="text"
                          className="fields px-4"
                          placeholder="Total Monthly Income"
                          value={earner.income}
                          onChange={(e) =>
                            handleInputChange(index, "income", e.target.value)
                          }
                        />
                      </div>
                    </Col>
                  </FormGroup>
                ))}
                <span className="add_earner_btn_par">
                  <button onClick={addEarner} className="add_earner_btn">
                    Add Earner +
                  </button>
                </span>
                Dependent(s):
                <div style={{ marginTop: 20 }}>
                  {depandant?.map((item, index) => (
                    <FormGroup
                      key={index}
                      className="d-flex flex-wrap form-colom "
                    >
                      <Col
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        className="form-group new_comp"
                      >
                        <div className="depandant_tab">
                        <label>Age of dependent</label>

                          <input
                            type=""
                            id="document"
                            className="fields px-4 transpanrent_cls text_clr input_border"
                            placeholder="Age of dependent"
                            value={item.age}
                            onChange={(e) =>
                              handleInputChangeofDependant(
                                index,
                                "age",
                                e.target.value
                              )
                            }
                          />
                          <span className="text_clr">
                            Is dependent currently in school/college/university?
                          </span>
                          <div className="checkbox_parent">

                            <input
                              type="radio"
                              name="document"
                              value={true}
                              onChange={(e) =>
                                handleInputChangeofDependant(
                                  index,
                                  "isStudent",
                                  e.target.value
                                )
                              }
                            />
                            <span className="text_clr">Yes</span>
                          </div>
                          <div className="checkbox_parent">
                            <input
                              type="radio"
                              name="document"
                              value={false}
                              onChange={(e) =>
                                handleInputChangeofDependant(
                                  index,
                                  "isStudent",
                                  e.target.value
                                )
                              }
                            />
                            <span className="text_clr">No</span>
                          </div>
                          <span className="text_clr">If Yes, then:</span>
                          <div className="total_earners_tab2">
                          <label>Name of school/college/university</label>

                            <input
                              type=""
                              id="document"
                              className="fields px-4 transpanrent_cls text_clr input_border"
                              placeholder="Name of school/college/university"
                              value={item.instituteName}
                              onChange={(e) =>
                                handleInputChangeofDependant(
                                  index,
                                  "instituteName",
                                  e.target.value
                                )
                              }
                            />
                            <span className="add_depandant_btn">
                              <button
                                onClick={addDepandant}
                                className="add_depandant_btn"
                              >
                                Add Dependent +
                              </button>
                            </span>
                          </div>
                        </div>
                      </Col>
                    </FormGroup>
                  ))}
                  <div style={{ marginTop: 20 }}>
                    <FormGroup className="d-flex flex-wrap form-colom  ">
                      <Col
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        className="form-group assets"
                      >
                        <span>Asset(s): (tick all that apply)</span>
                        <div className="checkbox_parent">
                          <input
                            type="checkbox"
                            id="document"
                            value="MotorBike"
                          />
                          <span>Motorbike(s):</span>
                          <input
                            type="number"
                            id="document"
                            className="assets_input transpanrent_cls"
                            placeholder="how many?"
                            value={assets?.bikes}
                            onChange={(e) =>  setassets({
                              bikes: e.target.value,
                              cars: assets?.bikes
                            })}
                          />
                        </div>
                        <div className="checkbox_parent">
                          <input
                            type="checkbox"
                            id="document"
                            value={"Cars"}
                          />
                          <span>Cars(s):</span>
                          <input
                            type="number"
                            id="document"
                            className="assets_input transpanrent_cls"
                            placeholder="how many?"
                            value={assets?.cars}
                            onChange={(e) =>
                              setassets({
                                cars: e.target.value,
                                bikes: assets?.bikes
                              })
                            }
                          />
                        </div>
                        <div className="checkbox_parent">
                          <input
                            type="checkbox"
                            id="document"
                            value={"none"}
                            onChange={(e) => setNAssetsType(e.target.value)}
                          />
                          <span>None</span>
                        </div>
                      </Col>
                    </FormGroup>
                  </div>
                </div>
                <div style={{ marginTop: 20 }}>
                  <FormGroup className="d-flex flex-wrap form-colom  ">
                    <Col lg={4} md={4} sm={12} xs={12} className="form-group">
                      <div
                        className="accordion"
                        id="accordionPanelsStayOpenExample"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingOne"
                          >
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseOne"
                              aria-expanded="true"
                              aria-controls="panelsStayOpen-collapseOne"
                              style={{ fontSize: "14px" }}
                            >
                              Is the patient living in their:
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingOne"
                          >
                            <div className="accordion-body">
                              <span
                                onClick={() => setpatientHouse("own house")}
                                className={`accordian_content ${
                                  patientHouse == "own house" &&
                                  "selected_accordian"
                                }`}
                              >
                                Own House
                              </span>
                              <hr />
                              <span
                                onClick={() => setpatientHouse("rented house")}
                                className={`accordian_content ${
                                  patientHouse == "rented house" &&
                                  "selected_accordian"
                                }`}
                              >
                                Rented House
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="form-group">
                      <div
                        className="accordion"
                        id="accordionPanelsStayOpenExample"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingTwo"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseTwo"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseTwo"
                              style={{ fontSize: "14px" }}
                            >
                              Is the patient living in a:
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseTwo"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingTwo"
                          >
                            <div className="accordion-body">
                              <span
                                onClick={() => setpatientLivingIn("nuclear")}
                                className={`accordian_content ${
                                  patientLivingIn == "nuclear" &&
                                  "selected_accordian"
                                }`}
                              >
                                Nuclear Family(Husband, Wife, Kids)
                              </span>
                              <hr />
                              <span
                                onClick={() => setpatientLivingIn("joint")}
                                className={`accordian_content ${
                                  patientLivingIn == "joint" &&
                                  "selected_accordian"
                                }`}
                              >
                                Joint Family
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </FormGroup>
                  <FormGroup className="d-flex flex-wrap form-colom  ">
                    <Col lg={4} md={4} sm={12} xs={12} className="form-group">
                      <div
                        className="accordion"
                        id="accordionPanelsStayOpenExample"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingThree"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#panelsStayOpen-collapseThree"
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseThree"
                              style={{ fontSize: "14px" }}
                            >
                              Is the patient eligible for Zakat?
                            </button>
                          </h2>
                          <div
                            id="panelsStayOpen-collapseThree"
                            className="accordion-collapse collapse show"
                            aria-labelledby="panelsStayOpen-headingThree"
                          >
                            <div className="accordion-body">
                              <span
                                onClick={() => setisZakatEligible(true)}
                                className={`accordian_content ${
                                  isZakatEligible == true &&
                                  "selected_accordian"
                                }`}
                              >
                                Yes
                              </span>
                              <hr />
                              <span
                                onClick={() => setisZakatEligible(false)}
                                className={`accordian_content ${
                                  isZakatEligible == false &&
                                  "selected_accordian"
                                }`}
                              >
                                No
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </FormGroup>
                </div>
                <Button
                  onClick={() => {
                    if (NassetsType == "none") {
                      setassets({});
                    } else {
                      setassets({
                        cars: `${assets?.cars}`,
                        bikes: `${assets?.bikes}`,
                      });
                    }
                    // depandant?.push({
                    //   age,
                    //   isStudent: Boolean(isStudent),
                    //   instituteName,
                    // });
                    // totalEarners?.push({
                    //   relation,
                    //   income,
                    // });
                    setTab("social");
                  }}
                  className="Done-button mt-4"
                >
                  Next
                </Button>
              </Form>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BackGround;
