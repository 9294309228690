import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../SIgnInPageComponent/signin.css";
import Logo from "../../assets/images/Logo.png";
import "@fontsource/poppins";
import Google from "../../assets/images/google.svg";
import Facebook from "../../assets/images/facebook.svg";
import { useMutation } from "@apollo/client";
import { LOGIN_QUERY } from "../../services/methods/Query";
import { useApi } from "../../services/mutation&Query/GraphqlApi";
import Toast from "../../functions/Toast";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { app } from "../../firebase";
import Cookies from "universal-cookie";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { checkToken } from "../../redux/userSlice";
import { isEmailValid } from "../../services/helper/helper";
import {
  GOOGLE_LOGIN,
  GOOGLE_REGISTRATION,
} from "../../services/methods/Mutations";

const SignInPage = () => {
  const [login] = useMutation(LOGIN_QUERY);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const usenavigate = useNavigate();
  const { loginQuery } = useApi();
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("login", true);
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const signInHandler = async () => {
    try {
      if (!email || !password) {
        Toast.error("Please fill in all fields");
        return;
      }
      const inputData = {
        email: email.toLowerCase().trim(),
        password: password,
        loginType: "manual",
      };
      const validEmail = await isEmailValid(email.toLowerCase().trim());
      if (!validEmail) {
        Toast.error("Please provide valid email");
        return;
      }
      setLoading(true);
      const response = await loginQuery(login, inputData);
      setLoading(false);
      console.log(response);
      if (response?.login?.success) {
        await dispatch(checkToken(true));
        usenavigate("/starCampaigningForm");
      }
    } catch (error) {
      setLoading(false);
      Toast.error(error);
      console.error("Error in signInHandler:", error);
    }
  };

  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  const [googlelogin] = useMutation(GOOGLE_LOGIN);
  const { GoogleLoginMutation } = useApi();
  const handleGoogleLogin = () => {
    setGoogleLoading(true);
    signInWithPopup(auth, provider)
      .then(async (result) => {
        await GoogleAuthProvider.credentialFromResult(result);
        const user = await result.user;
        const data = {
          email: user?.email,
          googleUserID: user.uid,
          loginType: "google",
        };
        console.log(data);
        const response = await GoogleLoginMutation(googlelogin, data);
        setGoogleLoading(false);
        if (response?.googleLogin?.success) {
          await dispatch(checkToken(true));
          usenavigate("/starCampaigningForm");
        }
      })
      .catch((error) => {
        console.log(error);
        Toast.error("Google Signin error");
        setGoogleLoading(false);
      });
  };

  return (
    <>
      <Row className="main-container">
        <Col lg={4} md={8} sm={11} xs={11} className="loginContainer mt-2 mb-5">
          <img src={Logo} className="logoimg mt-1" alt="Logo"></img>
          <h3 className="heading">Welcome Back</h3>
          <Col lg={7} md={8} sm={11} xs={11} className="inputContainer">
            <Row>
              <input
                disabled={loading || googleLoading}
                className="InputFields"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={handleEmailChange}
              ></input>
            </Row>
            <Row>
              <input
                disabled={loading || googleLoading}
                className="InputFields mt-2"
                type="password"
                placeholder="Password"
                value={password}
                onChange={handlePasswordChange}
              ></input>
            </Row>
            <Row>
              <Link to="/forget-password" className="forgottext mt-2">
                Forgot Password?
              </Link>
            </Row>
            <Row>
              {loading ? (
                <button
                  className="SignInBtn mt-3 text-center"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThreeDots
                    visible={true}
                    height="20"
                    width="50"
                    color="#FFFFFF  "
                    radius="9"
                    ariaLabel="three-dots-loading"
                  />
                </button>
              ) : (
                <Button
                  className="SignInBtn mt-3 text-center"
                  type="submit"
                  disabled={loading}
                  onClick={signInHandler}
                >
                  Sign in
                </Button>
              )}
            </Row>
            <Row>
              <p className="ORText mt-3 text-center">-OR-</p>
            </Row>
          </Col>
          <Col lg={6} md={8} sm={11} xs={11} className="socialbtnContainer ">
            <Row>
              {googleLoading ? (
                <button
                  className="SocialBtn"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThreeDots
                    visible={true}
                    height="20"
                    width="50"
                    color="#4285F4"
                    radius="9"
                    ariaLabel="three-dots-loading"
                  />
                </button>
              ) : (
                <Button
                  onClick={handleGoogleLogin}
                  className="SocialBtn"
                  type="submit"
                >
                  <img
                    src={Google}
                    className="mx-2 social-logo"
                    alt="google_icon"
                  />
                  Sign in with Google
                </Button>
              )}
            </Row>
          </Col>
          <Col className="mt-1 mb-3">
            <Row>
              <p className="AccountnotExist text-center">
                Don't have an Account?&nbsp;
                <Link to="/signup" className="signuplink">
                  Sign up
                </Link>
                &nbsp;
              </p>
            </Row>
          </Col>
        </Col>
      </Row>
    </>
  );
};
export default SignInPage;
