import React, { useState } from "react";
import "./AllGigs.css";
import testPic1 from "../../assets/images/gig1.png";
import testPic2 from "../../assets/images/gig2.png";
import testPic3 from "../../assets/images/gig3.png";
import userPic from "../../assets/images/profilePic1.png";
import heroImage from "../../assets/casesImgs/allgigs.png";
import {
  GET_ALL_GIGS,
  GET_DICTIONARY_QUERY,
} from "../../services/methods/Query";
import { useQuery } from "@apollo/client";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AllGigs = () => {
  const { data: dictionaryData } = useQuery(GET_DICTIONARY_QUERY);
  const arrOfData = dictionaryData?.getDictionary?.data;
  function checkGroup(arrOfData) {
    return arrOfData.group === "ServiceType";
  }
  const filterData = arrOfData?.filter(checkGroup);

  const [catId, setCatId] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [localSearch, setLocalSearch] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch(localSearch);
    console.log(search);
  };
  const query = {
    page,
    pageSize: 6,
  };
  if (search) {
    query["query"] = search;
  } else if (catId) {
    query["filterByCategory"] = catId;
  }
  const { data, loading, fetchMore } = useQuery(GET_ALL_GIGS, {
    fetchPolicy: "network-only",
    variables: query,
  });
  const allData = data?.getAllGigs?.data?.items;

  const PaginationData = data?.getAllGigs?.data;

  const totalPages = Math.ceil(
    PaginationData?.total / PaginationData?.per_page
  );

  console.log(catId);
  return (
    <>
      <div className="search_gigs">
        <input
          type="text"
          placeholder="Search Service"
          value={localSearch}
          onChange={(e) => setLocalSearch(e.target.value)}
        />
        <button
          style={{ border: "none" }}
          className="fa fa-search gig-search"
          onClick={handleSubmit}
        ></button>
      </div>
      <div className="main_img_gig">
        <img src={heroImage} alt="" />
      </div>
      <div className="all_gigs_main">
        <div className="all_gigs_cat">
          <h5>Category</h5>
          <div className="cat_list">
            {filterData?.map((item) => (
              <div className="cat_list_child">
                <input
                  name="dec"
                  type="radio"
                  onClick={() => setCatId(item?._id)}
                />
                <span>{item.code}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="all_gigs">
          <div className="all_gigs_top_sec">
            <h5>All Gigs</h5>
            <div className="all_gigs_sorting">
              <span>Sort By:</span>
              <select>
                <option value="price">Price</option>
              </select>
            </div>
          </div>
          {allData?.length >= 1 ? (
            <div className="all_gigs_list">
              {allData?.map((item) => (
                <Link style={{
                  textDecoration: 'none',
                  color: "black"
                }} to={`/gigDescriptionPage/${item?._id}`}>
                  <div className="single_gig">
                    <div className="gig_img_section">
                      <img
                        className="gig_img"
                        src={item?.photoLinks[0]}
                        alt=""
                      />
                      <div className="cat_name">
                        <span>{item?.categoryDetails[0]?.code}</span>
                      </div>
                    </div>
                    <div className="gig_user">
                      <img src={userPic} alt="" />
                      <span>{item?.userDetails[0]?.firstName}</span>
                    </div>
                    <h4 className="gig_title">{item?.title}</h4>
                    <p className="gig_price">{`Rs. ${item?.minPrice} - Rs. ${item?.maxPrice}`}</p>
                  </div>
                </Link>
              ))}
            </div>
          ) : (
            <h3 style={{
              display:'flex',
              justifyContent: 'center'
            }}>No data available</h3>
          )}

          <Row>
            <div className="LoadMoreSection">
              <button
                disabled={page <= 1}
                onClick={() => {
                  setPage(page > 1 ? page - 1 : page);
                  fetchMore({
                    variables: {
                      page,
                      pageSize: 6,
                    },
                  });
                }}
                className="loadMoretxt"
              >
                Previous
              </button>
              <button
                disabled={page >= totalPages}
                onClick={() => {
                  setPage(page < totalPages ? page + 1 : page);
                  fetchMore({
                    variables: {
                      page,
                      pageSize: 6,
                    },
                  });
                }}
                className="loadMoretxt"
              >
                Next
              </button>
            </div>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AllGigs;
