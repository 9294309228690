import React from "react";
import { Link } from "react-router-dom";
import { Col, Row, Button, Container } from "react-bootstrap";
import "../PatientStorySection/PatientStory.css";
import "@fontsource/poppins";
import Patientimg from "../../../assets/images/Patientimg.png";
import Verifiedimg from "../../../assets/images/verified.svg";
import patientprofileimg from "../../../assets/images/PatientsDp.png";
import ImagesContainer from "../ImagesContainer/ImagesContainer";
import DocumentSection from "../DocumentSection/DocumentSection";

const PatientStory = () => {
  return (
    <>
      <section className="patientInfoSection">
        <Container className="py-5">
          <Row>
            <h4 className="Title">Help us push Benjamin towards Recovery!</h4>
            <p className="Subtitle">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt u
            </p>
          </Row>
        </Container>

        <Container className="px-5">
          <Row>
            {/* ----------------Left SEction------------------ */}
            <Col lg={7}>
              <Col lg={12} md={12} xs={12}>
                <div className="imgHeadingContainer">
                  <Row className="imgHeading pt-3 px-2">
                    <Col lg={9} md={9} xs={7}>
                      <p className="PatientName">BelievingInBenjamin</p>
                    </Col>
                    <Col lg={1} md={1} xs={1}>
                      <img
                        className="verifiedImg"
                        src={Verifiedimg}
                        alt="tick_icon"
                      ></img>
                    </Col>
                    <Col lg={2} md={2} xs={2}>
                      <p className="VerifiedTxt">verified</p>
                    </Col>
                  </Row>
                </div>
                <Row className="MainImg">
                  <Col lg={12}>
                    <img
                      className="PatientImg"
                      src={Patientimg}
                      alt="PatientImage"
                    ></img>
                  </Col>
                </Row>
              </Col>

              <Col
                lg={12}
                md={12}
                xs={12}
                className="OrganizerContainer mt-4 px-2 pt-3 pb-4"
              >
                <Row>
                  <h3 className="OrganizerTitle px-4">Organizer</h3>
                </Row>
                <Row className="pb-4">
                  <Col lg={1} md={1} xs={2} className="mt-3">
                    <img
                      src={patientprofileimg}
                      alt="dp"
                      className="PatientDp w-10 px-3"
                    />
                  </Col>
                  <Col lg={3} md={3} xs={6}>
                    <Row>
                      <h3 className="OrganizerFullName mt-4 mx-5">Full Name</h3>
                    </Row>
                    <Row>
                      <p className="OrganizerLocation mx-5 ">Location</p>
                    </Row>
                  </Col>
                  <Col lg={8} md={8} xs={12}>
                    <div id="buttonsContainer">
                      <button className="_buttonDonate">Support</button>
                      <button className="_buttonAskforUpdate">
                        Ask for Update
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col
                lg={12}
                md={12}
                xs={12}
                className="StorySection mt-4 px-2 pt-3 pb-5"
              >
                <Row>
                  <p className="StoryTitle px-4">Story</p>
                </Row>
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <hr className="Line"></hr>
                  </Col>
                </Row>
                <Row className="mx-2">
                  <p className="storyContent">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras sed neque in orci pharetra volutpat sit amet a nunc. Ut
                    sodales iaculis urna. Nullam suscipit lacus lorem. Aliquam
                    viverra maximus diam vitae faucibus. Mauris tristique ornare
                    hendrerit. Etiam cursus faucibus elit at interdum. Proin
                    quis urna vitae neque faucibus viverra rhoncus quis ipsum.
                    Integer tortor sem, vehicula in fringilla vel, sollicitudin
                    eu lorem. Curabitur ut aliquam nisi, in porttitor dolor.
                    Aliquam sit amet tempus eros, at vulputate diam. Sed luctus
                    ante ipsum, sit amet facilisis velit fringilla non. Quisque
                    non scelerisque nibh, vel commodo quam. Nullam aliquam lorem
                    vel pulvinar volutpat. Praesent tincidunt, nulla quis
                    viverra ornare, massa magna efficitur libero, in elementum
                    ligula ex vitae sem. Donec quis justo justo. Vivamus
                    venenatis leo nec feugiat luctus. Nulla tempor turpis ut
                    metus tempus, id euismod purus lacinia. Phasellus ac augue
                    tellus. Quisque ornare risus ac nulla porta, ac consequat
                    nisl dignissim. Sed lacinia pellentesque lorem, et convallis
                    justo gravida non. Ut finibus sed sapien eget sollicitudin.
                    Phasellus faucibus metus ante, at imperdiet leo consequat
                    vel. Nunc sodales enim efficitur velit scelerisque, non
                    rutrum nisl dapibus. Aenean condimentum ipsum fermentum est
                    aliquet ornare non quis ipsum. Aenean tincidunt ornare est,
                    non lobortis libero. Donec tincidunt odio mi.
                  </p>
                </Row>
                <Row>
                  <ImagesContainer />
                </Row>

                <Row className=" px-2 mt-5">
                  <div className="Updates_Div">
                    <div className="left_div">
                      <p className="StoryTitle">Updates (3)</p>
                    </div>
                    <div className="right_div mb-3">
                      <Link to="/viewAllUpdates">
                        <p className="ViewAllUpdatesTxt">View All Updates</p>
                      </Link>
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <hr className="Line"></hr>
                  </Col>
                </Row>
                <Row className="mx-2">
                  <p className="storyContent">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras sed neque in orci pharetra volutpat sit amet a nunc. Ut
                    sodales iaculis urna. Nullam suscipit lacus lorem. Aliquam
                    viverra maximus diam vitae faucibus. Mauris tristique ornare
                    hendrerit. Etiam cursus faucibus elit at interdum. Proin
                    quis urna vitae neque faucibus viverra rhoncus quis ipsum.
                    Integer tortor sem, vehicula in fringilla vel, sollicitudin
                    eu lorem. Curabitur ut aliquam nisi, in porttitor dolor.
                    Aliquam sit amet tempus eros, at vulputate diam. Sed luctus
                    ante ipsum, sit amet facilisis velit fringilla non. Quisque
                    non scelerisque nibh, vel commodo quam. Nullam aliquam lorem
                    vel pulvinar volutpat. Praesent tincidunt, nulla quis
                    viverra ornare, massa magna efficitur libero, in elementum
                    ligula ex vitae sem. Donec quis justo justo. Vivamus
                    venenatis leo nec feugiat luctus. Nulla tempor turpis ut
                    metus tempus, id euismod purus lacinia.
                  </p>
                </Row>
                <Row>
                  <ImagesContainer />
                </Row>
              </Col>
            </Col>

            {/* ----------------Right SEction------------------ */}

            <Col lg={5} md={12} xs={12} className="RightContainer">
              <DocumentSection />
            </Col>
          </Row>
        </Container>

        <Col
          lg={12}
          md={12}
          xs={12}
          className="StratCrowdFundingContainer mt-5"
        >
          <Row>
            <h5 className="StratCrowdFundingHedaing mt-5">
              Ready to start campaigning?
            </h5>
          </Row>
          <Row>
            <center>
              <Link to="/starCampaigningForm">
                <Button
                  className="startFundraisingInBtn mt-3 mb-4 text-center"
                  type=""
                >
                  Start Campaigning
                </Button>
              </Link>
            </center>
          </Row>
        </Col>
      </section>
    </>
  );
};
export default PatientStory;
