import React, { useState } from "react";
import "./HowitWorksSection.css";
import { Link } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import landing_hoeitWorks1 from "../../../assets/images/landing-howitworks1.png";
import landing_hoeitWorks2 from "../../../assets/images/landing-howitworks2.png";
import landing_hoeitWorks3 from "../../../assets/images/landing-howitworks3.png";
import landing_hoeitWorks4 from "../../../assets/images/landing-howitworks4.png";
import plusSymbol from "../../../assets/images/landing-work-plussymbol.svg";
import equalSymbol from "../../../assets/images/landing-work-equalSymbol.svg";
import Video1 from "../../videos/video1.mp4";
import VideoBg from "../../../assets/images/howitworks-video.png";
import VideoIcon from "../../../assets/images/howitworks-video-icon.png";
import "@fontsource/poppins";
import vid from "../../videos/video1.mp4";

const HowitWorksSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <section className="sec-how-works">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
            <div className="sec-title">
              <h2>HOW IT WORKS</h2>
            </div>
          </div>
        </div>

        <div className="start-fund-row mt-5">
          <div className="row align-items-end">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="start-fund-content">
                <p>
                  ShifaLia is a platform where individuals in need of financial
                  assistance for their medical expenses can come and start their
                  own campaigns for the world to see. Our platform works to
                  amplify the voices of those in need and broadcast their
                  stories to potential donors.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="start-fund-Btn">
                <Link to="/starCampaigningForm">
                  <Button>Start Campaigning</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row  cards-main">
          <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3">
            <div className="work_card1  px-3">
              <div className="card-img-wrapper">
                <img
                  className="card_img"
                  src={landing_hoeitWorks1}
                  alt="landing_hoeitWorks1"
                ></img>
              </div>

              <h5 className="">Start with the basics</h5>
              <p>Set your financial goal.</p>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 card--wrapper">
            <div className="work_card px-3">
              <div className="card-img-wrapper">
                <img
                  className="card_img"
                  src={landing_hoeitWorks2}
                  alt="landing_hoeitWorks1"
                ></img>
              </div>

              <h5>Create your Story</h5>
              <p>Upload all the necessary documents.</p>
            </div>
            <div className="plusCircle1">
              <img src={plusSymbol} alt="plusSymbol"></img>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 card--wrapper">
            <div className="work_card px-3">
              <div className="card-img-wrapper">
                <img
                  className="card_img"
                  src={landing_hoeitWorks3}
                  alt="landing_hoeitWorks1"
                ></img>
              </div>

              <h5>Share with friends</h5>
              <p>Share your Campaign across social media</p>
            </div>
            <div className="plusCircle2">
              <img className="card_img" src={plusSymbol} alt="plusSymbol"></img>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 card--wrapper">
            <div className="work_lastcard px-3">
              <div className="card-img-wrapper">
                <img
                  className="card_img"
                  src={landing_hoeitWorks4}
                  alt="landing_hoeitWorks1"
                ></img>
              </div>

              <h5>Reach your financial goal</h5>
              <p>
                All the financial support you receive goes directly into your
                bank account
              </p>
            </div>
            <div className="equalCircle">
              <img src={equalSymbol} alt="equalSymbol"></img>
            </div>
          </div>
        </div>

        <section className="howitworksvideo mt-5" id="watchVideo">
          <div className="container mt-5">
            <div className="howitworksvideo-cnt">
              <h5>Let’s see how it’s done</h5>
              <div className="howitworksvideo-wrap">
                <video controls>
                  <source src={Video1} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default HowitWorksSection;
