import AllRoutes from "./routes/AllRoutes";
import { ToastContainer } from "react-toastify";
import CookieImg from "./assets/PolicyTermsImages/cookie_img.png";
import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { GET_PROFILE_QUERY } from "./services/methods/Query";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications, getUser } from "./redux/userSlice";
import DefaultLoader from "./components/loaderComponent/loader";
// import { generateToken } from "./firebase";
import SubscriptionComponent from "./components/Subcription/Subs";

function App() {
  const [showCookie, setShowCookie] = useState(false);
  const dispatch = useDispatch();
  const { user, isToken } = useSelector((state) => state.user);
  const [isUser, setIsUser] = useState(false);

  const cookies = new Cookies();
  const [getData, { data }] = useLazyQuery(GET_PROFILE_QUERY, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: cookies.get("token"),
      },
    },
    onCompleted: async () => {
      await dispatch(getUser(data?.GetPatientProfile?.data));
      setIsUser(true);
    },
    onError: () => setIsUser(true),
  });

  useEffect(() => {
    getData();
  }, [user, isToken, dispatch]);

  // useEffect(() => {
  //   generateToken();
  // }, []);

  return (
    <div className={showCookie && "main_wrapper"}>
      {user?.data && <SubscriptionComponent />}
      {showCookie && (
        <div className="cookie_banner">
          <img src={CookieImg} alt="" />
          <h6>Cookie</h6>
          <p>
            We use Cookies to provide the best experience on our website. This
            includes cookies for website functionality to manage our commercial
            objectives and organization.
          </p>

          <button onClick={() => setShowCookie(false)}>Yes Please!</button>
          <button onClick={() => setShowCookie(false)}>Nah, I’m full.</button>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={9000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isUser ? <AllRoutes /> : <DefaultLoader />}
    </div>
  );
}

export default App;
