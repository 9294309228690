import { toast } from "react-hot-toast";

const Toast = {
  error: (message) => {
    return toast.error(message, { duration: 5000 });
  },
  success: (message) => {
    return toast.success(message, { duration: 5000 });
  },
  loading: (message) => {
    return toast.loading(message, { duration: 5000 });
  },
};

export default Toast;
