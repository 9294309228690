import React from "react";
import "./_camtips.scss";

const Camptips = () => {
  const data = [
    {
      id: 1,
      heading: " Craft a Compelling Story",
      para: "Every successful Campaigning campaign starts with a compelling story. Share the 'why' behind your cause, creating an emotional connection that resonates with potential donors. Personal stories have the power to inspire and drive action.",
    },
    {
      id: 2,
      heading: " Set Clear Goals",
      para: "Clearly define your Campaigning goals. Whether it's a specific amount of money, a target number of donors, or a tangible project outcome, having clear objectives provides focus and motivation.",
    },
    {
      id: 3,
      heading: "Leverage Social Media",
      para: "Harness the power of social media to amplify your message. Utilize platforms like Facebook, Instagram, and Twitter to share updates, engage with your audience, and encourage them to participate in your Campaigning efforts.",
    },
    {
      id: 4,
      heading: "Create Engaging Content",
      para: "Capture attention with visually appealing and emotionally resonant content. Utilize images, videos, and infographics to tell your story and showcase the impact of donations. Make your content shareable to broaden its reach.",
    },
    {
      id: 5,
      heading: "Offer Incentives",
      para: "Encourage participation by offering incentives. Whether it's personalized thank-you notes, exclusive updates, or small tokens of appreciation, incentives add an extra layer of motivation for donors to get involved.",
    },
    {
      id: 6,
      heading: "Host Virtual Events",
      para: "Embrace the digital age by hosting virtual events. From webinars to online auctions and live streaming, virtual events provide a dynamic platform to engage donors, share your mission, and foster a sense of community.",
    },
    {
      id: 7,
      heading: "Empower Peer-to-Peer Campaigning",
      para: "Encourage supporters to become ambassadors for your cause. Empower them to create their own Campaigning pages and leverage their networks to expand your reach. Peer-to-peer Campaigning adds a personal touch and widens your impact.",
    },
    {
      id: 8,
      heading: " Express Gratitude",
      para: "Show genuine appreciation to your donors. Regularly express gratitude through personalized messages, social media shout-outs, and updates on how their contributions are making a difference.",
    },
    {
      id: 9,
      heading: "Keep Supporters Updated",
      para: "Keep your supporters informed about the progress of your campaign. Share milestones, success stories, and the impact of their contributions. Transparency builds trust and keeps donors engaged.",
    },
    {
      id: 10,
      heading: "Utilize Campaigning Platforms",
      para: "Explore online Campaigning platforms like ShifaLia that simplify the donation process. Platforms like Shifalia provide secure, user-friendly interfaces, making it easier for supporters to contribute to your cause.",
    },
  ];
  return (
    <>
      <div className="camptips-main-container d-flex flex-column">
        <h1 className="text-center">Campaigning Tips</h1>
        <div className="camptips-inner-main ">
          <span>
            In the world of Campaigning, each effort, no matter how small, has
            the potential to create a ripple of positive change. When it comes
            to healthcare needs in Pakistan, numerous people find it challenging
            to manage their medical expenses. However, with campaigning, we can
            help our fellow Pakistanis with their medical expenses. Let's dive
            into some invaluable tips to amplify the impact of your campaigning
            endeavours.
          </span>
          <div className="tips-container d-flex ">
            <div className="tips-inner-left d-flex flex-column">
              <div className="num-circle">1</div>
              <div className="num-line"></div>
              <div className="num-circle">2</div>
              <div className="num-line"></div>
              <div className="num-circle">3</div>
              <div className="num-line"></div>
              <div className="num-circle">4</div>
              <div className="num-line"></div>
              <div className="num-circle">5</div>
              <div className="num-line"></div>
              <div className="num-circle">6</div>
              <div className="num-line"></div>
              <div className="num-circle">7</div>
              <div className="num-line"></div>
              <div className="num-circle">8</div>
              <div className="num-line"></div>
              <div className="num-circle">9</div>
              <div className="num-line"></div>
              <div className="num-circle">10</div>
            </div>
            <div className="tips-inner-right d-flex flex-column gap-3">
              {data.map((index) => (
                <div className="tips-card d-flex flex-column" key={index.id}>
                  <h1>{index.heading}</h1>
                  <p>{index.para}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Camptips;
