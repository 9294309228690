import React from "react";
// import Header from "../components/Header/header"
import SignIn from "../components/SIgnInPageComponent/SignInPage";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const SignUpPage = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <div>
      {/* <Header /> */}
      {user?.data && user?.role === "patient" && (
        <Navigate to="/starCampaigningForm" replace={true} />
      )}
            {user?.data && user?.role === "donar" && (
        <Navigate to="/allCases" replace={true} />
      )}
      <SignIn />
    </div>
  );
};

export default SignUpPage;
