export const hospitals = [
  "Abbasi Shaheed Hospital",
  "Abbassi Shaheed Hospital",
  "Abbas Institute of Medical Sciences",
  "Aadil Hospital",
  "Aga Khan Hospital for Women",
  "Aga Khan University Hospital",
  "Al-Shifa Trust Eye Hospital",
  "Alam Hospital",
  "Allied Hospital",
  "Ammar Medical Complex",
  "Armed Forces Institute of Cardiology",
  "Armed Forces Institute of Pathology (Pakistan)",
  "Ayub Teaching Hospital",
  "Aziz Fatima Hospital, Faisalabad",
  "Bacha Khan Medical Complex Swabi",
  "Bahawal Victoria Hospital, Bahawalpur",
  "Bahria International Hospital",
  "Baluchistan Social Security Hospital Hub",
  "Benazir Bhutto Hospital",
  "Benazir Institute Urology and Transplantation, Benazirabad",
  "Bethania Hospital Sialkot",
  "Bilawal Medical College Hospital, Kotri",
  "Burhani Hospital",
  "Cancer Care Hospital & Research Center",
  "Capital International Hospital",
  "Centre for Nuclear Medicine (CENUM), Lahore",
  "Children Hospital Faisalabad",
  "Children Hospital Lahore",
  "Children Hospital Multan",
  "Children Hospital, Lahore",
  "Citi Hospital",
  "City Hospital, Institute of Mother & Child Heathy Lahore",
  "City Hospital, Lahore",
  "Civil Hospital, Bahawalpur",
  "Civil Hospital, Gwadar",
  "Civil Hospital, Larkana",
  "Civil Hospital, Quetta",
  "Civil Hospital, Sukkur",
  "Combined Military Hospital Lahore",
  "Combined Military Hospital Rawalpindi",
  "D.I Khan Institute of Nuclear Medicine and Radiotherapy (DINAR)",
  "DHQ Hospital Awaran",
  "DHQ Hospital Bannu",
  "DHQ Hospital Charsadda",
  "DHQ Hospital Dera Ismail Khan",
  "DHQ Hospital Faisalabad",
  "DHQ Hospital Gilgit",
  "DHQ Hospital Khar, Bajaur",
  "DHQ Hospital Kharan",
  "DHQ Hospital Khuzdar",
  "DHQ Hospital Kohat",
  "DHQ Hospital Lasbela",
  "DHQ Hospital Mansehra",
  "DHQ Hospital Nowshera",
  "DHQ Hospital Panjgur",
  "DHQ Hospital Swabi",
  "DHQ Hospital Washuk",
  "Doctors Hospital",
  "Dr. Ruth K. M. Pfau Civil Hospital Karachi",
  "Dr. Ziauddin Hospital",
  "Faisalabad Institute of Cardiology",
  "Family Health Hospital, Johar Town Lahore",
  "Farooq Hospital",
  "Fatima Memorial Hospital",
  "Fauji Foundation Hospital",
  "Fazl-e-Omar Hospital",
  "GDA Hospital Gwadar",
  "Gambat Liver Transplant Center",
  "Govt. General Hospital 224 R.B.",
  "Govt. General Hospital G.M.Abad",
  "Govt. General Hospital Samanabad",
  "Hameed Latif Hospital",
  "Hayatabad Medical Complex",
  "Health Care Hospital",
  "Health City Hospital",
  "Health Oriented Preventive Education",
  "Holy Family Hospital",
  "Holy Family Hospital, Karachi",
  "Hospice St. Joseph's Rawalpindi",
  "Horizon Hospital Lahore",
  "Ihsan Mumtaz Hospital",
  "Indus Hospital",
  "Indus Hospital, Lahore",
  "Institute of Dentistry, Faisalabad",
  "Institute of Nuclear Medicine & Oncology Lahore (INMOL)",
  "Institute of Nuclear Medicine, Oncology and Radiotherapy (INOR)",
  "Institute of Radiotherapy and Nuclear Medicine (IRNUM)",
  "Iqraa Medical Complex,Lahore",
  "Ittefaq Hospital (Trust)",
  "Jinnah Hospital",
  "Jinnah Postgraduate Medical Centre (JPMC)",
  "Jubilee women Hospital, Bahawalpur",
  "Kech Hospital Turbat",
  "Khalifa Gul Nawaz Teaching Hospital",
  "Khawaja Hospital",
  "King Abdullah Teaching Hospital",
  "Khyber Teaching Hospital",
  "Lady Aitchison Hospital",
  "Lady Reading Hospital",
  "Lady Willingdon Hospital",
  "Lahore Care Hospital",
  "Lahore General Hospital",
  "Lahore Health Care",
  "Liaquat National Hospital",
  "Liaquat University Hospital",
  "Luqman International Hospital",
  "Marie Adelaide Leprosy Centre, Karachi",
  "Masood Hospital, Garden Town Lahore",
  "Maternity And Child Health Care Institute Benazirabad",
  "Mayo Hospital",
  "Medix Hospital, Lahore",
  "Military Hospital Rawalpindi",
  "Miangul Abdul Haq Jahanzeb Kidney Hospital",
  "Model Town Hospital, Model Town Circular",
  "Mumtaz Baktawar Hospital",
  "National Hospital & Medical Centre , Lahore",
  "National Institute of Cardiovascular Diseases (NICVD)",
  "National Institute of Health (Pakistan)",
  "National Police Hospital (Pakistan)",
  "Noor Hospital",
  "Nishtar Hospital",
  "Noble Hospital",
  "North West General Hospital & Research Centre",
  "Nuclear Medicine Oncology & Radiotherapy Institute Nawabshah",
  "Orthopaedic Hospital & Medical Complex",
  "Pak Hospital, Ichhra Lahore",
  "Pak–China Friendship Hospital, Gwadar",
  "Patel Hospital, Karachi",
  "Peshawar General Hospital",
  "Peshawar Institute of Cardiology",
  "Peshawar Institute of Medical Sciences",
  "Pir Abdul Qadir Ali Shah Jillani Institute of Medical Sciences, Gambat (GIMS)",
  "P.N.S. Rahat",
  "P.N.S. Shifa",
  "Qazi Hussain Ahmed Medical Complex, Nowshera",
  "Quaid-e-Azam International Hospital",
  "Railway General Hospital Rawalpindi",
  "Railway Hospital Multan",
  "Rawalpindi Institute of Cardiology",
  "Rawalpindi International Hospital",
  "Recep Tayyip Erdoğan Hospital, Muzaffargarh",
  "Rehman Medical Institute",
  "Roshaan Hospital",
  "Saidu Teaching Hospital",
  "Saira Memorial Hospital",
  "Saifee Hospital, Karachi",
  "Samanabad General Hospital",
  "Services Hospital Lahore",
  "Shaikh Zayed Hospital",
  "Shaikh Zayad Hospital Quetta",
  "Shalamar Hospital",
  "Shadman General Hospital",
  "Shaukat Khanum Memorial Cancer Hospital & Research Centre",
  "Shaukat Khanum Memorial Cancer Hospital and Research Centre",
  "Sheikh Zayad Hospital Quetta",
  "Sindh Institute of Skin Diseases",
  "Sindh Institute of Urology and Transplantation (SIUT)",
  "Sir Ganga Ram Hospital",
  "Surgimed Hospital",
  "Sialkot Mission Hospital",
  "Social Security Hospital KLP , Lahore",
  "Society Hospital, Nabipura Lahore",
  "St. Elizabeth’s Hospital, Hyderabad",
  "St. Raphael's Hospital",
  "United Christian Hospital, Lahore",
  "Wali Hospital Kasur",
  "Wapda Hospital, Faisalabad",
  "Wazir Hospital",
  "Women and Children Hospital",
  "Women's Christian Hospital, Multan"
];