import React, { useEffect, useState } from "react";
import "./MsgModal.css";
import { useNavigate } from "react-router-dom";
const MsgModal = ({ closeMsgModal, msg }) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  const usenavigate = useNavigate();
  return (
    <>
      <div className="Modalwrapper"></div>
      <div className="Modal_Container_msg">
        {/* <h4 className="modal_heading_">Google Signup</h4> */}
        <p className="modal_para_ mt-2 mb-0">{msg && msg}</p>
        <div className="btn_cont">
          <button
            onClick={() => {
              closeMsgModal();
              usenavigate("/signin");
            }}
            className="okay_btn "
          >
            Okay
          </button>
        </div>
      </div>
    </>
  );
};

export default MsgModal;
