import React from "react";
import AllGigs from "../components/AllGigs/AllGigs";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";

const AllGigsPages = () => {
  return (
    <div>
      <Header />
      <AllGigs />
      <Footer />
    </div>
  );
};

export default AllGigsPages;
