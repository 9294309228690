import React, { useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import faqIcon from "../../assets/images/faq_modal_icon.png";
import "@fontsource/poppins";

const Accordion = ({ ques, ans }) => {
    const [show, setShow] = useState(false);



    return (
        <>
            <Row className="ContentRow" style={{ padding: '10px', border: '1px solid #7B7D7C', margin: '10px', borderRadius: '10px', paddingTop: '20px' }}>
                <Col xs={1} md={1} className="text-center">
                    <img src={faqIcon} alt="" />
                </Col>
                <Col xs={9} sm={9} md={10} lg={10}>
                    <button
                        className={`accordion-button ${show ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => setShow(!show)}
                        style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                    >
                        <h3 className="QuestionsTxt" style={{ fontSize: '17px', fontFamily: 'Poppins' }}>{ques}</h3>
                    </button>
                    {show && <p style={{ borderTop: '2px solid rgba(123, 125, 124, 0.27)', margin: '6px 0px 10px 0px' }}></p>}
                    {show && <div style={{ fontSize: '15px', fontFamily: 'Poppins', color: '#014E7F', listStyleType: "decimal" }} dangerouslySetInnerHTML={{ __html: ans }} />}
                </Col>
                <Col xs={1} md={1} className="text-center">
                    <p style={{ cursor: 'pointer' }} onClick={() => setShow(!show)}>
                        {show ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                    </p>
                </Col>
            </Row>
        </>
    );
};
export default Accordion;
