import React, { useState } from "react";
import { Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import loadmoreArrow from "../../../assets/images/loadmoreArrow.png";
import HeaderImgSection from "../HeaderImgSection/HeaderImgSection";
import SearchSection from "../SearchSection/SearchSection";
import CategoriesSection from "../CategoriesSection/CategoriesSection";
import AllCasesSection from "../AllCasesSection/AllCasesSection";
import AllcasesData from "../AllCasesSection/AllcasesData";
import StartMonthlyDonationSection from "../StartMonthlyDonationSection/StartMonthlyDonationSection";
import ReadyToStartSection from "../ReadyToStartSection/ReadyToStartSection";
import "./AllCasesMainPageCom.css";
import { GET_ALL_CASES_QUERY } from "../../../services/methods/Query";
import { useQuery } from "@apollo/client";
import Loader from "../../Loader/Loader";
import { Provider } from "../../../context/Context";

const AllCasesMainPageCom = () => {
  const { search, catId, date } = Provider();
  const [page, setPage] = useState(1);
  const query = {
    page,
    pageSize: 4,
  };

  // console.log(date);
  if (search) {
    query["query"] = search;
  } else if (catId) {
    query["filterByCategory"] = catId;
  } else if (date) {
    query["filterByDate"] = date;
  }

  // 1711572273

  const { data, loading, fetchMore } = useQuery(GET_ALL_CASES_QUERY, {
    fetchPolicy: "network-only",
    variables: query,
    
  });
  const allData = data?.getAllCases?.data;

  const totalPages = Math.ceil(allData?.total / allData?.per_page);

  return (
    <>
      <section className="AllcasesPageCom">
        <HeaderImgSection />
        <Container>
            <SearchSection />
          <CategoriesSection />
          <Container>
            <Row>
              {loading ? <Loader /> : <AllCasesSection data={allData} />}
            </Row>

            {/* <Row>
              <AllCasesSection />
            </Row> */}
            <Row style={{
              marginTop: '10px'
            }}>
              <div className="LoadMoreSection">
                <button
                  disabled={page <= 1}
                  onClick={() => {
                    setPage(page > 1 ? page - 1 : page);
                    fetchMore({
                      variables: {
                        page,
                        pageSize: 4,
                      },
                    });
                  }}
                  className="loadMoretxt"
                >
                  Previous
                </button>
                <button
                  disabled={page >= totalPages}
                  onClick={() => {
                    setPage(page < totalPages ? page + 1 : page);
                    fetchMore({
                      variables: {
                        page,
                        pageSize: 4,
                      },
                    });
                  }}
                  className="loadMoretxt"
                >
                  Next
                </button>
              </div>
            </Row>
            <Row>
              <StartMonthlyDonationSection />
            </Row>
            <Row>
              <ReadyToStartSection />
            </Row>
          </Container>
        </Container>
      </section>
    </>
  );
};

export default AllCasesMainPageCom;
