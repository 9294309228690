import React from "react";
import GigOffer from "../components/GigOffers/GigOffer";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";

const GigOfferPage = () => {
  return (
    <div>
      <Header />
      <GigOffer />
      <Footer />
    </div>
  );
};

export default GigOfferPage;
