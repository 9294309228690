import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./OurLocation.css";

const OurLocation = () => {
  return (
    <>
      <Row>
        <h5 className="OurLocationHeading mt-5 mb-4">Our Location</h5>
      </Row>
      <Row>
        <div className="map-container mb-5 mt-5">
          <iframe
            src="https://maps.google.com/maps?width=100%&amp;height=100%&amp;hl=en&amp;q=karachi&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            width="100%"
            height="650"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="eager" className="i-frame"
          ></iframe>

          <Col lg={4} md={5} sm={8} xs={9} className="map-overlay px-4 py-5">
            <div className="map-overlay-content">
              <h5 className="countryTxt">Pakistan</h5>
              <p className="mtxt mx-3">City:</p>
              <p className="majortxt mx-3">Karachi</p>
              <p className="mtxt mx-3">Address:</p>
              <p className="majortxt mx-3">
                Unit#377, Block 20, Fb area karachi
              </p>
              <p className="mtxt mx-3">Phone No.</p>
              <p className="majortxt mx-3">+923152344269</p>
              <p className="mtxt mx-3">Email Address</p>
              <p className="majortxt mx-3">info@shifalia.com</p>
            </div>
            {/* <Link to="/contactusUSA">
              <Button className="USABtn">USA</Button>
            </Link> */}
          </Col>
        </div>
      </Row>
    </>
  );
};

export default OurLocation;
