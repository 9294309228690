import React from "react";
// import SignUp from "../components/SignUpPageComponent";
import Signup from "../components/signup/signup";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const SignUpPage = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <div>
      {/* <SignUp /> */}
      {user?.data && user?.role === "patient" && (
        <Navigate to="/starCampaigningForm" replace={true} />
      )}
            {user?.data && user?.role === "donar" && (
        <Navigate to="/allCases" replace={true} />
      )}
      <Signup />
    </div>
  );
};

export default SignUpPage;
