import { gql } from "@apollo/client";

const LOGIN_QUERY = gql`
  mutation Login($data: LoginDto!) {
    login(data: $data) {
      success
      message
      error
      data
    }
  }
`;

const GET_PROFILE_QUERY = gql`
  query Query {
    GetPatientProfile {
      success
      message
      error
      data
    }
  }
`;

const GET_DICTIONARY_QUERY = gql`
  query Query {
    getDictionary {
      success
      message
      error
      data
    }
  }
`;

const GET_ALL_CASES_QUERY = gql`
  query GetAllCases(
    $page: Float
    $pageSize: Float
    $query: String
    $filterByDate: String
    $filterByCategory: String
  ) {
    getAllCases(
      page: $page
      pageSize: $pageSize
      query: $query
      filterByDate: $filterByDate
      filterByCategory: $filterByCategory
    ) {
      success
      message
      error
      data {
      page
      total
      per_page
      next
      previous
        items {
          _id
          categoryId
          title
          patientFullName
          goalAmount
          addedDB
          updatedIn
          picturesLinks
          totalAmountCollected
          supporter
        }
      }
    }
  }
`;

const GET_CASE_BY_ID = gql`
  query Query($getAllCasesId: String) {
    getAllCases(id: $getAllCasesId) {
      success
      message
      error
      data {
        items {
          _id
          userId
          categoryId
          fullName
          email
          phone
          patientPhone
          patientFullName
          CNICNumber
          maritalStatus
          patientAddress
          relationWithPatient
          healthIssue
          hospitalName
          city
          title
          WhatsAppNo
          consultingDoctor
          description
          doctorPhone
          gender
          howDoYouKnowAboutANF
          medicalCondition
          addedDB
          previousCompanies
          goalAmount
          age
          monthlyIncome
          mrNumber
          totalAmountCollected
          supporter
          utilityBillImageLinks
          picturesLinks
          videoLinks
          customShortLinks
          ReportsLinks
          otherDocsLinks
          consentLetter
          campaignerWhatsAppNo
          UHIDNumber
          addTeamMember
          Service
          anonymous
          verifyCNIC
          verifyBill
          status
          updatedIn
          verifySupporterCNIC
          employmentStatus
          fatherName
          motherName
          education
          husbandName
          CNICImageLinks
          utilityBillImageLinks
          treatmentLetter
          Address
          patientHouse
          isZakatEligible
          patientLivingIn
          totalEarners {
            relation
            income
          }
          depandant {
            age
            isStudent
            instituteName
          } 
          assets {
           cars
           bikes
          }
          categoryDetails {
            _id
            group
            code
          }
          AmountCollectionDetails {
            _id
            fundraisingID
            firstName
            lastName
            amount
          }
          userDetails {
            _id
            firstName
            lastName
            email
          }
          CaseInfo {
            ImageLinks
            updateTitleNews
            updateDescription
            addedDB
            updatedIn
            status
          }
        }
      }
    }
  }
`;

const GET_MY_CASES_QUERY = gql`
  query Query {
    getAllPatientCases {
      success
      message
      error
      data {
        _id
        title
        patientFullName
        goalAmount
        addedDB
        updatedIn
        picturesLinks
        totalAmountCollected
        supporter
        AmountCollectionDetails {
          _id
          fundraisingID
          firstName
          lastName
          amount
        }
      }
    }
  }
`;

const GET_COMMENTS = gql`
  query Query($fundraiserId: String) {
    getComments(fundraiserId: $fundraiserId) {
      success
      message
      error
      data
    }
  }
`;

const GET_MY_SERVICES = gql`
query Query($userId: String!) {
  getMyServices(userId: $userId) {
    success
    error
    message
    data
  }
}
`;

const GET_SERVICE_BY_ID = gql`
query GetMyServicesByID($getMyServicesByIdId: String) {
  getMyServicesByID(id: $getMyServicesByIdId) {
    success
    error
    message
    data
  }
}
`;

const GET_TESTIMONIALS_QUERY = gql`
  query Query($id: String) {
    getTestimonials(Id: $id) {
      success
      message
      error
      data
    }
  }
`;

const GET_SUPPORTED_DATA_QUERY = gql`
query Query {
  campaignSupported {
    success
    message
    error
    data
  }
}
`;

const GET_ALL_NOTIFICATIONS = gql`
query GetAllNotifications {
  getAllNotifications {
    success
    message
    error
    data
  }
}
`;

const GET_COMMENTS_NOTIFICATIONS = gql`
query Query {
  getCommentsNotifications {
    success
    message
    error
    data
  }
}
`;

const GET_UNREAD_NOTIFICATIONS = gql`
query GetUnreadNotifications {
  getUnreadNotifications {
    success
    message
    error
    data
  }
}
`;

const GET_ARCHIVED_NOTIFICATIONS = gql`
query Query {
  getArchivedNotifications {
    success
    message
    error
    data
  }
}
`;

const GET_TOTAL_DONATION = gql`
query Query {
  totalDonation {
    success
    message
    error
    data
  }
}
`;

const GET_GIG_OFFER_BY_ID = gql`
query GetGigOfferById($id: String) {
  getGigOfferById(Id: $id) {
    success
    error
    message
    data
  }
}
`;

const GET_ALL_GIGS = gql`
query Query($page: Float, $pageSize: Float, $query: String, $filterByCategory: String) {
  getAllGigs(page: $page, pageSize: $pageSize, query: $query, filterByCategory: $filterByCategory) {
    success
    error
    message
    data {
      page
      total
      per_page
      next
      previous
      items {
        _id
        userId
        serviceType
        title
        description
        email
        minPrice
        maxPrice
        gigOffers
        photoLinks
        categoryDetails {
          _id
          group
          code
        }
        userDetails {
          _id
          firstName
          lastName
          email
        }
      }
    }
  }
}
`;

const GET_GIG_OFFER_OF_CAMPAIGNER = gql`
query Query($page: Float, $pageSize: Float) {
  campaignerOffers(page: $page, pageSize: $pageSize) {
    success
    error
    message
    data {
      page
      total
      per_page
      next
      previous
      items {
        _id
        email
        title
        userDetails {
          _id
          firstName
          email
        }
      }
    }
  }
}
`;

const GET_GIG_OFFER_OF_DONER = gql`
query Query($page: Float, $pageSize: Float) {
  donerOffers(page: $page, pageSize: $pageSize) {
    success
    error
    message
    data {
      page
      total
      per_page
      next
      previous
      items {
        _id
        title
        email
        userDetails {
          firstName
          _id
        }
      }
    }
  }
}
`;

export {
  LOGIN_QUERY,
  GET_PROFILE_QUERY,
  GET_DICTIONARY_QUERY,
  GET_ALL_CASES_QUERY,
  GET_MY_CASES_QUERY,
  GET_CASE_BY_ID,
  GET_COMMENTS,
  GET_MY_SERVICES,
  GET_SERVICE_BY_ID,
  GET_TESTIMONIALS_QUERY,
  GET_SUPPORTED_DATA_QUERY,
  GET_ARCHIVED_NOTIFICATIONS,
  GET_ALL_NOTIFICATIONS,
  GET_COMMENTS_NOTIFICATIONS,
  GET_TOTAL_DONATION,
  GET_UNREAD_NOTIFICATIONS,
  GET_ALL_GIGS,
  GET_GIG_OFFER_BY_ID,
  GET_GIG_OFFER_OF_CAMPAIGNER,
  GET_GIG_OFFER_OF_DONER,
};
