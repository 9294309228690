import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isToken: false,
  notification: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUser: (state, action) => {
      state.user = action.payload;
    },
    checkToken: (state, action) => {
      state.isToken = action.payload;
    },
    getNotifications: (state, action) => {
      state.notification.push(action.payload);
    },
  },
});

export const { getUser, checkToken, getNotifications } = userSlice.actions;

export default userSlice.reducer;
