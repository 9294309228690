import { gql } from "@apollo/client";

const GET_SUBS_NOTIFICATION = gql`
subscription NewNotification {
  newNotification {
    success
    error
    message
    data
  }
}`;

export { GET_SUBS_NOTIFICATION };
