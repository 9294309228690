import React, { useEffect } from "react";
import "./logoutmodal.scss";
import Toast from "../../../functions/Toast";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { checkToken, getUser } from "../../../redux/userSlice";

const FooterModal = ({ closeheaderModal }) => {
  const { user } = useSelector((state) => state.user);
  const cookies = new Cookies();
  const dispatch = useDispatch();
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);
  const handleLogout = async () => {
    await cookies.remove("token", { path: "/" });
    await dispatch(getUser(null));
    dispatch(checkToken(false));
    Toast.success("Logout Successfully");
  };
  return (
    <>
      <div className="Modalwrapper"></div>
      <div className="Modal_Container_logout">
        <h4 className="modal_heading_">Are you sure?</h4>
        <div className="Btn_save_container mt-4">
          <button className="log-out_btn " onClick={handleLogout}>
            Logout
          </button>
          <button className="save_btn" onClick={closeheaderModal}>
            Cancal
          </button>
        </div>
      </div>
    </>
  );
};

export default FooterModal;
