import React, { useEffect, useState } from "react";
import "./Offers.css";
import View from "./ViewOffer/View";
import {
  GET_GIG_OFFER_BY_ID,
  GET_GIG_OFFER_OF_CAMPAIGNER,
  GET_GIG_OFFER_OF_DONER,
} from "../../../services/methods/Query";
import Cookies from "universal-cookie";
import { useLazyQuery } from "@apollo/client";
import { useSelector } from "react-redux";

const Offers = () => {
  const [showFooterModal, setshowFooterModal] = useState(false);
  const [offers, setOffers] = useState([]);
  const [offer, setOffer] = useState({});
  const [offerId, setOfferId] = useState("");
  const [page, setPage] = useState(1);
  const closeFooterModal = () => setshowFooterModal(false);
  const cookies = new Cookies();

  const { user } = useSelector((state) => state.user);

  const query = {
    page,
    pageSize: 5,
  };

  const [getCampaignerOffer, { data: campOffer, fetchMore }] = useLazyQuery(
    GET_GIG_OFFER_OF_CAMPAIGNER,
    {
      fetchPolicy: "network-only",
      variables: query,
      context: {
        headers: {
          Authorization: cookies.get("token"),
        },
      },
      onCompleted: () => setOffers(campOffer?.campaignerOffers?.data?.items),
    }
  );

  const [getDonerOffer, { data: donerOffer, fetchMore: DonerFatchMore }] =
    useLazyQuery(GET_GIG_OFFER_OF_DONER, {
      fetchPolicy: "network-only",
      variables: query,
      context: {
        headers: {
          Authorization: cookies.get("token"),
        },
      },
      onCompleted: () => setOffers(donerOffer?.donerOffers?.data?.items),
    });

  const pagination =
    donerOffer?.donerOffers?.data || campOffer?.campaignerOffers?.data;

  const totalPages = Math.ceil(pagination?.total / pagination?.per_page);

  const [getOfferDetails, { data: OfferDetails }] = useLazyQuery(
    GET_GIG_OFFER_BY_ID,
    {
      fetchPolicy: "network-only",
      variables: {
        id: offerId,
      },
      context: {
        headers: {
          Authorization: cookies.get("token"),
        },
      },
      onCompleted: () => setOffer(OfferDetails?.getGigOfferById?.data[0]),
    }
  );

  useEffect(() => {
    if (user?.role == "donar") {
      getDonerOffer();
    } else if (user?.role == "patient") {
      getCampaignerOffer();
    }
  }, []);

  return (
    <div className="offers_main">
      <h5>Projects Offered</h5>
      <div
        style={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        <table className="gig_table">
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Project Title</th>
            <th>Details</th>
          </tr>
          {offers.length >= 1 &&
            offers?.map((item) => (
              <tr>
                <td>{item?.userDetails[0]?.firstName}</td>
                <td>{item?.email}</td>
                <td>{item?.title}</td>
                <td>
                  <button
                    onClick={async () => {
                      setOfferId(item?._id);
                      await getOfferDetails();
                      if (offerId) {
                        setshowFooterModal(true);
                      }
                    }}
                    className="view_btn"
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
        </table>
      </div>

      {showFooterModal && (
        <View closeFooterModal={closeFooterModal} offer={offer} />
      )}

      <div className="pagination_btn">
        <button
          disabled={page <= 1}
          onClick={() => {
            setPage(page > 1 ? page - 1 : page);
            if (user?.role == "patient") {
              fetchMore({
                variables: {
                  page,
                  pageSize: 5,
                },
              });
            } else if (user?.role == "donar") {
              DonerFatchMore({
                variables: {
                  page,
                  pageSize: 5,
                },
              });
            }
          }}
        >
          Previous
        </button>
        <button
          disabled={page >= totalPages}
          onClick={() => {
            setPage(page < totalPages ? page + 1 : page);
            if (user?.role == "patient") {
              fetchMore({
                variables: {
                  page,
                  pageSize: 5,
                },
              });
            } else if (user?.role == "donar") {
              DonerFatchMore({
                variables: {
                  page,
                  pageSize: 5,
                },
              });
            }
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Offers;
