import React, { useRef, useState } from "react";
import "../Social Profile/socialprofile.css";
import { Col, Row, Container, Button, Form, FormGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Toast from "../../../functions/Toast.js";
import { Provider } from "../../../context/Context.jsx";
import { BsPlus } from "react-icons/bs";
import "./personal.css";
import Loader from "../../Loader/Loader.jsx";
// import { BsPlus } from 'react-icons/bs';
const PersonalDetails = ({ isSidebarOpen, loading, setTab }) => {
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const {
    fullName,
    setFullname,
    CNICNumber,
    setCNICNumber,
    maritalStatus,
    setmaritalStatus,
    Address,
    setAddress,
    CNICImageLinks,
    setCNICImageLinks,
    utilityBillImageLinks,
    setutilityBillImageLinks,
    caseById,
  } = Provider();
  const navigate = useNavigate();

  const [prevPic, setPrevPic] = useState([]);
  const [prevVideo, setPrevVideo] = useState([]);
  const [prevCusLinks, setPrevCusLinks] = useState([]);

  const handleCNIC = (e) => {
    setCNICImageLinks(e.target.files);
    const files = Array.from(e.target.files);
    setPrevPic([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPrevPic((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleBills = (e) => {
    setutilityBillImageLinks(e.target.files);
    const files = Array.from(e.target.files);
    setPrevVideo([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPrevVideo((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  // const handleCustomLinks = (e) => {
  //   setcustomShortLinks(e.target.files);
  //   const files = Array.from(e.target.files);
  //   setPrevCusLinks([]);
  //   files.forEach((file) => {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       if (reader.readyState === 2) {
  //         setPrevCusLinks((old) => [...old, reader.result]);
  //       }
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };

  // const handleSubmit = () => {
  //   navigate("/detailpage3");
  // };

  return (
    <>
      <div
        className={`form-container ${
          isSidebarOpen ? "form-container-open" : ""
        }`}
      >
        <h3 className="socialprofile">Personal Details</h3>
        <Container className="mb-5 form-09">
          <Row>
            <Container className=" pb-4  px-5">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Form>
                    <FormGroup className="d-flex flex-wrap form-colom   ">
                      <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                        <div>
                          <label>Full Name</label>
                          <input
                            type="text"
                            className="fields px-4"
                            placeholder="Full Name"
                            value={fullName}
                            onChange={(e) => setFullname(e.target.value)}
                          />
                          {/* <Form.Text className="Form-Text">
                            Please write a detailed description of the case
                            while highlighting all the important aspects
                            including, medical problems, financial background of
                            the patient, reason for this medical campaign, etc.
                            The more convincing your story, the more donors your
                            cause will attract.
                          </Form.Text> */}
                        </div>
                      </Col>
                      <Col lg={4} md={5} sm={6} xs={12} className="form-group ">
                      <label>CNIC Number</label>
                        <input
                          type="text"
                          id="number"
                          className="fields px-4"
                          placeholder="CNIC Number"
                          value={CNICNumber}
                          onChange={(e) => setCNICNumber(e.target.value)}
                        />
                        <br></br>
                        {/* <Form.Text className="Form-Text">
                          Please enter the amount you are trying to raise for
                          this cause.
                        </Form.Text> */}
                      </Col>
                    </FormGroup>
                    <FormGroup className="d-flex flex-wrap form-colom ">
                      {/* <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                    <input
                      type="text"
                      id="img"
                      className="fields px-4"
                      placeholder="Fundraise Details"
                      // value={details}
                      // onChange={(e) => setDetails(e.target.value)}
                    />
                    <Form.Text className="Form-Text">
                      Please enter the amount you are trying to raise for this
                      cause.
                    </Form.Text>
                  </Col> */}
                      <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                        <div>
                        <label>Marital Status</label>
                          <input
                            type="text"
                            className="fields px-4"
                            placeholder="Marital Status"
                            value={maritalStatus}
                            onChange={(e) => setmaritalStatus(e.target.value)}
                          />
                          {/* <Form.Text className="Form-Text">
                            Please enter the medical condition the patient is
                            suffering from.
                          </Form.Text> */}
                        </div>
                      </Col>

                      <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                        <div>
                        <label>Your Address</label>
                          <input
                            type="text"
                            className="fields px-4"
                            placeholder="Your Address"
                            value={Address}
                            onChange={(e) => setAddress(e.target.value)}
                          />
                          {/* <Form.Text className="Form-Text">
                            Please enter a short title for this fundraiser. For
                            example: “Support Patient XYZ” .
                          </Form.Text> */}
                        </div>
                      </Col>
                    </FormGroup>

                    <FormGroup className="d-flex flex-wrap form-colom ">
                      <Col lg={4} md={5} sm={5} xs={5} className="form-group">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef1.current?.click();
                          }}
                          className="button-upload"
                        >
                          <span className="Text-photo d-flex ">
                            Add CNIC picture
                          </span>
                          <BsPlus size="30px" className="plus-icoon" />
                        </button>
                        <input
                          type="file"
                          id="img"
                          className="fields px-4"
                          placeholder="Add Photo"
                          accept="image/*"
                          multiple
                          style={{ display: "none" }}
                          ref={fileInputRef1}
                          onChange={handleCNIC}
                        />
                        <div>
                          {prevPic.length >= 1
                            ? prevPic?.map((i) => (
                                <img className="edit_img_prev" src={i} />
                              ))
                            : caseById?.CNICImageLinks?.map((i) => (
                                <img src={i} className="edit_img_prev" />
                              ))}
                        </div>
                        {/* <Form.Text className="Form-Text">
                          People find it easier to entrust their campaign with a
                          cause that they can see for themselves. This is why,
                          to receive maximum donations, please add a picture of
                          the patient these campaign are being raised for.
                        </Form.Text> */}
                      </Col>
                      <Col lg={4} md={5} sm={6} xs={12} className="form-group ">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            fileInputRef2.current?.click();
                          }}
                          className="button-upload"
                        >
                          <span className="Text-photo d-flex">
                            Add Utility Bill Picture
                          </span>
                          <BsPlus size="30px" className="plus-icoon" />
                        </button>
                        <input
                          type="file"
                          id="img"
                          className="fields px-2"
                          multiple
                          style={{ display: "none" }}
                          ref={fileInputRef2}
                          onChange={handleBills}
                        />
                        <div>
                          {prevVideo?.length >= 1
                            ? prevVideo?.map((i) => (
                                <img className="edit_img_prev" src={i} />
                              ))
                            : caseById?.utilityBillImageLinks?.map((i) => (
                                <img src={i} className="edit_img_prev" />
                              ))}
                        </div>
                        {/* <Form.Text className="Form-Text">
                          Have a video showcasing the patient talking about
                          their case? Add it here. (Optional)
                        </Form.Text> */}
                      </Col>
                    </FormGroup>
                  </Form>

                  <Button
                    onClick={() => setTab("fundraise")}
                    className="Done-button mt-2"
                    type="submit"
                  >
                    Next
                  </Button>
                </>
              )}
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PersonalDetails;
