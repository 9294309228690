import React, { useRef, useState } from "react";
import "./hospitaldetail.css";
import { Col, Row, Container, Button, Form, FormGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "../../../context/Context";
import { useNavigate } from "react-router-dom";
import { BsPlus } from "react-icons/bs";
import Toast from "../../../functions/Toast";
import { ThreeDots } from "react-loader-spinner";
const HospitalDetails = ({ isSidebarOpen, setTab }) => {
  const {
    hospitalName,
    setHospitalName,
    UHIDNumber,
    setUHIDNumber,
    consultingDoctor,
    setconsultingDoctor,
    treatmentLetter,
    settreatmentLetter,
    healthIssue,
    sethealthIssue,
    editLoading,
    handleSubmit,
    caseById,
  } = Provider();
  const navigate = useNavigate();
  const fileInputRef1 = useRef(null);
  const [prevConsent, setPrevConsent] = useState([]);
  const handleTreatment = (e) => {
    settreatmentLetter(e.target.files);
    const files = Array.from(e.target.files);
    setPrevConsent([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPrevConsent((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const [valOfUHIDNum, setvalOfUHIDNum] = useState("");

  const handleAdd = (e) => {
    e.preventDefault();
    if (valOfUHIDNum == "") {
      return;
    }
    setvalOfUHIDNum("");
    setUHIDNumber([...UHIDNumber, valOfUHIDNum]);
  };

  const handleUpdate = async () => {
    const res = await handleSubmit();
    console.log(res);
    if (res?.data?.success) {
      sessionStorage.clear();
      navigate("/");
      Toast.success("Updated Successfully");
    }
  };

  return (
    <>
      <div
        className={`form-container ${
          isSidebarOpen ? "form-container-open" : ""
        }`}
      >
        <h3 className=" socialprofile">Hospital Details</h3>
        <Container className="mt-1 mb-5 form-09">
          <Row>
            <Container className=" py-2 px-5">
              <Form>
                <FormGroup className="d-flex flex-wrap form-colom   ">
                  <Col lg={8} md={8} sm={12} xs={12} className="form-group ">
                    <label>Hospital Name</label>

                    <input
                      type="text"
                      id="text"
                      className="fields px-4"
                      placeholder="Hospital Name"
                      value={hospitalName}
                      onChange={(e) => setHospitalName(e.target.value)}
                    />
                  </Col>
                  {/* <Col lg={4} md={4} sm={12} xs={12} className="form-group ">
                    <div>
                      <input
                        type="tel"
                        id="document"
                        className="fields px-4"
                        placeholder="Patient’s UHID Number"
                        value={valOfUHIDNum}
                        onChange={(e) => setvalOfUHIDNum(e.target.value)}
                      />
                      <button
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          marginTop: "5px",
                          background: "transparent",
                        }}
                        onClick={handleAdd}
                      >
                        Add
                      </button>
                      <Form.Text className="Form-Text">
                        {UHIDNumber.length >= 1
                          ? UHIDNumber?.map((i) => <li>{i}</li>)
                          : caseById?.UHIDNumber?.map((i) => <li>{i}</li>)}
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut.{" "}
                      </Form.Text>
                    </div>
                  </Col> */}
                </FormGroup>
                <FormGroup className="d-flex flex-wrap form-colom ">
                  <Col lg={8} md={8} sm={12} xs={12} className="form-group">
                    <label>Name of Consulting Doctor</label>

                    <input
                      type="text"
                      id="document"
                      className="fields px-4"
                      placeholder="Name of Consulting Doctor"
                      value={consultingDoctor}
                      onChange={(e) => setconsultingDoctor(e.target.value)}
                    />
                    <Form.Text className=" Form-Text">
                      Please provide the doctor’s name.{" "}
                    </Form.Text>
                  </Col>
                  {/* <Col lg={4} md={4} sm={12} xs={12} className="form-group">
                    <div>
                      <input
                        type="number"
                        className="fields px-4"
                        placeholder="Doctor’s Phone Number"
                        value={doctorPhone}
                        onChange={(e) => setdoctorPhone(e.target.value)}
                      />
                      <Form.Text className="Form-Text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing{" "}
                        <br></br> elit, sed do eiusmod tempor incididunt ut.
                      </Form.Text>
                    </div>
                  </Col> */}
                </FormGroup>
                <FormGroup className="d-flex flex-wrap form-colom ">
                  <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                    <label>What is health issue</label>

                    <input
                      type="text"
                      id="name"
                      className="fields px-4"
                      placeholder="What is health issue?"
                      value={healthIssue}
                      onChange={(e) => sethealthIssue(e.target.value)}
                    />
                  </Col>
                  <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                    <label>Hospital Treatment Letter</label>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRef1.current?.click();
                      }}
                      className="button-upload"
                    >
                      <span className="Text-photo d-flex ">
                        Hospital Treatment Letter
                      </span>
                      <BsPlus size="30px" className="plus-icoon" />
                    </button>
                    <input
                      type="file"
                      id="img"
                      className="fields px-4"
                      placeholder="Add Photo"
                      accept="image/*"
                      multiple
                      style={{ display: "none" }}
                      ref={fileInputRef1}
                      onChange={handleTreatment}
                    />
                    <div>
                      {prevConsent?.length >= 1
                        ? prevConsent?.map((i) => (
                            <img className="edit_img_prev" src={i} />
                          ))
                        : caseById?.treatmentLetter?.map((i) => (
                            <img src={i} className="edit_img_prev" />
                          ))}
                    </div>
                  </Col>
                </FormGroup>

                {editLoading ? (
                  <Button
                    className="Done-button mt-4"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <ThreeDots
                      visible={true}
                      height="40"
                      width="40"
                      color="#FFFFFF"
                      radius="9"
                      ariaLabel="three-dots-loading"
                    />
                  </Button>
                ) : (
                  <Button onClick={handleUpdate} className="Done-button mt-4">
                    Done
                  </Button>
                )}
              </Form>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HospitalDetails;
