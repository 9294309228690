import { gql } from "@apollo/client";

const WAITLIST_MUTATION = gql`
  mutation waitList($Data: WaitListDto!) {
    waitList(rawData: $Data) {
      success
      error
      message
      data
    }
  }
`;

const USERQUERY_MUTATION = gql`
  mutation userQuery($Data: UserQueryDto!) {
    userQuery(rawData: $Data) {
      success
      error
      message
      data
    }
  }
`;

const ACTIONUSER_REGISTRATION = gql`
  mutation ActionsUsersRegistration($rawData: CreateUserDto!) {
    actionsUsersRegistration(rawData: $rawData) {
      success
      message
      error
      data
    }
  }
`;

const GOOGLE_REGISTRATION = gql`
  mutation Mutation($data: GoogleUserDto!) {
    googleAuth(data: $data) {
      success
      message
      error
      data
    }
  }
`;

const GOOGLE_LOGIN = gql`
  mutation GoogleLogin($data: GoogleLoginDto!) {
    googleLogin(data: $data) {
      success
      message
      error
      data
    }
  }
`;

const CONTACT_FORM = gql`
  mutation ContactForm($rawData: ContactFormDto!) {
    contactForm(rawData: $rawData) {
      success
      message
      error
      data
    }
  }
`;
const DONATION_FORM = gql`
  mutation Donation($rawData: DonationDto!) {
    donation(rawData: $rawData) {
      success
      message
      error
      data
    }
  }
`;

const RAISE_A_CONCERN = gql`
  mutation RaiseAConcern($rawData: RaiseConcernDto!) {
    RaiseAConcern(rawData: $rawData) {
      success
      message
      error
      data
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUsers($rawData: UpdateUserDto!) {
    updateUsers(rawData: $rawData) {
      success
      message
      error
      data
    }
  }
`;

const EMAIL_SEND = gql`
  mutation ForgetEmailSend($email: String!) {
    forgetEmailSend(email: $email) {
      success
      message
      error
      data
    }
  }
`;

const OTP_SEND = gql`
  mutation ForgetOTPSend($email: String!, $otpCode: Float!) {
    forgetOTPSend(email: $email, otpCode: $otpCode) {
      success
      message
      error
      data
    }
  }
`;

const NEW_PASSWORD = gql`
  mutation ForgetPasswordChange($newPassword: String!) {
    forgetPasswordChange(newPassword: $newPassword) {
      success
      message
      error
      data
    }
  }
`;

const FUNDRAISER_AMOUNT_COLLECTION = gql`
  mutation Mutation($rawData: FundraiserAmountCollectionDTO!) {
    fundraiserAmountCollection(rawData: $rawData) {
      success
      message
      error
      data
    }
  }
`;

const CREATE_COMMENTS = gql`
  mutation Mutation($rawData: FundraisingCommentsDto!) {
    caseComments(rawData: $rawData) {
      success
      message
      error
      data
    }
  }
`;

const NEW_TESTIMONIALS = gql`
  mutation Mutation($rawData: TestimonialsDTO!) {
    newTestimonials(rawData: $rawData) {
      success
      message
      error
      data
    }
  }
`;

const NEW_NOTIFICATION = gql`
mutation Mutation($rawData: NotificationDTO!) {
  newNotification(rawData: $rawData) {
    success
    error
    message
    data
  }
}
`;



export {
  UPDATE_USER,
  RAISE_A_CONCERN,
  DONATION_FORM,
  CONTACT_FORM,
  ACTIONUSER_REGISTRATION,
  GOOGLE_REGISTRATION,
  USERQUERY_MUTATION,
  WAITLIST_MUTATION,
  EMAIL_SEND,
  OTP_SEND,
  NEW_PASSWORD,
  FUNDRAISER_AMOUNT_COLLECTION,
  CREATE_COMMENTS,
  GOOGLE_LOGIN,
  NEW_TESTIMONIALS,
  NEW_NOTIFICATION,
};
