import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import SignUpImage from "../../assets/images/signUpImage.png";
import Google from "../../assets/images/google.svg";
import Facebook from "../../assets/images/facebook.svg";
import "./signup.scss";
import { ACTIONUSER_REGISTRATION } from "../../services/methods/Mutations";
import { useMutation } from "@apollo/client";
import { useApi } from "../../services/mutation&Query/GraphqlApi";
import Toast from "../../functions/Toast";
import Cookies from "universal-cookie";
import { ThreeDots } from "react-loader-spinner";
import { isEmailValid } from "../../services/helper/helper";
import GoogleModal from "./googleModal/GoogleModal";
import MsgModal from "./msgModal/MsgModal";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [registration] = useMutation(ACTIONUSER_REGISTRATION);
  const usenavigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [showFooterModal, setshowFooterModal] = useState(false);
  const [showMsgModal, setShowMsgModal] = useState(false);
  const [msg, setMsg] = useState("");
  const closeFooterModal = () => setshowFooterModal(false);
  const closeMsgModal = () => setShowMsgModal(false);

  const { ActionsUsersRegistrationMutation } = useApi();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { firstName, lastName, email, password } = formData;
    console.log(formData);

    if (firstName == "" || lastName == "" || email == "" || password == "") {
      Toast.error("Please fill in all fields");
      return false;
    }
    if (!firstName || !lastName || !email || !password) {
      Toast.error("Please fill in all fields");
      return false;
    }
    return true;
  };

  const signUpHandler = async (event) => {
    const validEmail = await isEmailValid(formData.email?.toLowerCase().trim());
    if (!validEmail) {
      Toast.error("Please provide valid email");
      return;
    }
    const data = {
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      role: Number(formData.role) || 1,
    };
    event.preventDefault(); // Add this line
    console.log(data);
    if (validateForm()) {
      setLoading(true);
      const response = await ActionsUsersRegistrationMutation(
        registration,
        data
      );
      setLoading(false);
      console.log(response, "register");
      if (response?.actionsUsersRegistration?.success) {
        setShowMsgModal(true);
        setMsg(response.actionsUsersRegistration?.data);
      }
    }
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="signup-container d-flex flex-wrap">
        <div className="signup-left d-flex flex-column ">
          <img src={Logo} alt="Logo" />
          <h1>
            Financial Limitations Should Not Restrict Access to Healthcare
          </h1>
          <img src={SignUpImage} alt="SignUpImage" />
        </div>
        <div className="signup-right d-flex flex-column">
          <img src={Logo} alt="Logo" className="m_logo" />
          <h5 className="mb-2">Sign Up</h5>
          <div className="signup-inputs-container  d-flex flex-column">
            <Row className="signup-name-inputs">
              <Col lg={6} md={12} sm={12}>
                <input
                  disabled={loading}
                  defaultValue={""}
                  type="text"
                  name="firstName"
                  className="w-100 signup-inputs"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                />
              </Col>
              <Col lg={6} md={12} sm={12}>
                <input
                  disabled={loading}
                  type="text"
                  defaultValue={""}
                  name="lastName"
                  className="w-100 signup-inputs"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <input
                  disabled={loading}
                  type="email"
                  defaultValue={""}
                  name="email"
                  className="w-100 signup-inputs m-0 p-3"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <input
                  disabled={loading}
                  defaultValue={""}
                  className="w-100 signup-inputs m-0 p-3"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            <Row>
              <Col
                lg={12}
                md={12}
                sm={12}
                style={{ display: "flex", gap: "30px" }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <label for="donar">As Campaigner</label>
                  <input
                    disabled={loading}
                    type="radio"
                    name="role"
                    value="1"
                    onChange={handleInputChange}
                    defaultChecked
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    alignItems: "center",
                  }}
                >
                  <label for="donar">As Supporter</label>
                  <input
                    disabled={loading}
                    type="radio"
                    name="role"
                    value="2"
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="Signup-bottom-content  d-flex flex-column ">
            <span className="mb-3">
              By continuing, you agree to our{" "}
              <Link to="/termsOfUse" className="span-link">
                terms
              </Link>{" "}
              and{" "}
              <Link to="/privacypolicy" className="span-link">
                privacy policy.
              </Link>
            </span>
            <Row>
              <Col lg={12} md={12} sm={12}>
                {loading ? (
                  <button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <ThreeDots
                      visible={true}
                      height="20"
                      width="50"
                      color="#FFFFFF  "
                      radius="9"
                      ariaLabel="three-dots-loading"
                    />
                  </button>
                ) : (
                  <button onClick={signUpHandler} className="w-100">
                    Create Account
                  </button>
                )}
              </Col>
            </Row>
            <p className="mt-3">-OR-</p>
          </div>
          <div className="bottom-most  d-flex flex-column">
            <Row className="mb-3 signin-opts-btn">
              <Col lg={12} md={12} sm={12}>
                <button
                  onClick={() => {
                    setshowFooterModal(true);
                  }}
                  className="w-100"
                >
                  <img src={Google} alt="Google" /> Sign up with Google
                </button>
              </Col>
            </Row>
            {showFooterModal && (
              <GoogleModal closeFooterModal={closeFooterModal} />
            )}
            {showMsgModal && (
              <MsgModal closeMsgModal={closeMsgModal} msg={msg} />
            )}
            <span>
              Already have an account?{" "}
              <Link to="/signin" className="span-link">
                Sign in.
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
