import React, { useState } from "react";
import "./GigDescription.css";
import { Col, Container, Row } from "react-bootstrap";
import gigImg from "../../assets/GigDescriptionImages/gig_title_main.jpg";
import gigSubImg from "../../assets/GigDescriptionImages/sub_img.jpg";
import tickIcon from "../../assets/GigDescriptionImages/tick_icon.png";
import msgIcon from "../../assets/GigDescriptionImages/msg_icon.png";
import reviewImg from "../../assets/GigDescriptionImages/review_img.png";
import { Accordion } from "react-bootstrap";
import {
  GET_SERVICE_BY_ID,
  GET_TESTIMONIALS_QUERY,
} from "../../services/methods/Query";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import Slider from "react-slick";
import Loader from "../Loader/Loader";
import { useApi } from "../../services/mutation&Query/GraphqlApi";
import { NEW_TESTIMONIALS } from "../../services/methods/Mutations";
import Toast from "../../functions/Toast";
import { useSelector } from "react-redux";
import { paymentAPI } from "../../services/http/paymentApi";

const GigDescriptionComponent = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  var Reviewsettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const params = useParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_SERVICE_BY_ID, {
    variables: {
      getMyServicesByIdId: params?.id,
    },
  });
  const {
    data: testimonials,
    loading: testimonialsLoading,
    refetch,
  } = useQuery(GET_TESTIMONIALS_QUERY, {
    variables: {
      id: params?.id,
    },
  });
  const testimonialsData = testimonials?.getTestimonials?.data;
  const service = data?.getMyServicesByID?.data;

  const [comment, setComment] = useState("");
  const [rating, setRating] = useState("");
  const { NewTestimonialsMutation } = useApi();
  const [testimonial] = useMutation(NEW_TESTIMONIALS);
  const testimonials_data = {
    id: params.id,
    comment,
    rating: Number(rating),
  };

  const [testimonialLoading, setTestimonialLoading] = useState(false);
  const handleNewTestimonials = async () => {
    if (comment == "" || rating == "") {
      Toast.error("Please provide all fields");
      return;
    }
    setTestimonialLoading(true);
    await NewTestimonialsMutation(testimonial, testimonials_data);
    setTestimonialLoading(false);
    refetch();
    setComment("");
  };

  const stripeData = [
    {
      name: params?.id,
      price: service?.amount,
      description: "gig",
    },
  ];

  const handlePay = async () => {
    const res = await paymentAPI(stripeData);
    console.log(res);
    window.location = res?.data?.data?.url;
  };

  const { user } = useSelector((state) => state.user);

  return (
    <div className="gig_desciption_main">
      <div className="desx_top_sec">
        <div className="gig_service">Service Type: {service?.serviceTypeDetails[0]?.code}</div>
        <h4>{service?.title}</h4>
        <p>{service?.userDetails?.firstName}</p>
      </div>
      <Container className="py-5">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="gig_details">
              <Row className="justify-content-between">
                <Col md={12}>
                  <Slider {...settings}>
                    {service?.photoLinks?.map((item) => (
                      <div>
                        <img src={item} alt="" />
                      </div>
                    ))}
                    {/* <div>
                  <img src={service?.photoLinks[1]} alt="" />
                </div>
                <div>
                  <img src={service?.photoLinks[2]} alt="" />
                </div> */}
                  </Slider>
                </Col>
                <Col md={6}>
                  <div className="mt-5">
                    <h3>Gig's Description</h3>
                    <p>{service?.description}</p>
                  </div>

                  <div>
                    {user && user?.role === "donar" && (
                      <>
                        <h3>Reviews</h3>
                        <input
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          type="text"
                          placeholder="Write a comment"
                          className="testimonials_input"
                        />
                        <div className="rating_comp">
                          <label for="rate">Rate this gig </label>
                          <select
                            value={rating}
                            onChange={(e) => setRating(e.target.value)}
                            name="rate"
                            className="rating"
                          >
                            <option value="" selected hidden disabled>
                              Rating
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                        <button
                          disabled={testimonialLoading}
                          onClick={handleNewTestimonials}
                          className="testimonial_btn"
                        >
                          {testimonialLoading ? "Wait....." : "Post"}
                        </button>
                      </>
                    )}
                    {/* <div className="reviews_main">
                      <h6>{testimonialsData?.length} reviews</h6>
                      <div className="mt-3">
                        <p>5 stars</p>
                        <span></span>
                        <p>(0)</p>
                      </div>
                      <div>
                        <p>4 stars</p>
                        <span></span>
                        <p>(0)</p>
                      </div>
                      <div>
                        <p>3 stars</p>
                        <span></span>
                        <p>(0)</p>
                      </div>
                      <div>
                        <p>2 stars</p>
                        <span></span>
                        <p>(0)</p>
                      </div>
                      <div>
                        <p>1 stars</p>
                        <span></span>
                        <p>(0)</p>
                      </div>
                    </div> */}

                    {/* {testimonialsData?.length >= 1 &&
                      testimonialsData?.map((item) => (
                        <div className="review_div mt-4 left_review">
                          <div>
                            <div className="review_user_name">
                              <div>
                                <img
                                  src={item?.userDetails?.profileImageLink}
                                  height={50}
                                  alt=""
                                  style={{ borderRadius: "50%" }}
                                />
                                <div>
                                  <h6>
                                    {item?.userDetails?.firstName}{" "}
                                    {item?.userDetails?.lastName}
                                  </h6>
                                  <p>{item?.addedDB}</p>
                                </div>
                              </div>

                              <div>
                                {Array.from({ length: item?.rating }).map(
                                  () => (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                        fill="#F8F3EA"
                                      />
                                    </svg>
                                  )
                                )}
                              </div>
                            </div>
                            <p className="mt-2">{item?.comments}</p>
                          </div>
                        </div>
                      ))} */}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="about_gig">
                    <div>
                      <p>Gig Price Range</p>
                      <p>Rs. {service?.minPrice} - Rs. {service?.maxPrice}</p>
                    </div>

                    <hr />

                    <h6>Services I provide:</h6>

                    <ul>
                      {service?.gigOffers?.map((item) => (
                        <li>
                          <img src={tickIcon} alt="" />
                          <p>{item}</p>
                        </li>
                      ))}
                      {/* <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed{" "}
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed{" "}
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed{" "}
                    </p>
                  </li>
                  <li>
                    <img src={tickIcon} alt="" />
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed{" "}
                    </p>
                  </li> */}
                    </ul>

                    <div className="mb-0 about_gig_btn">
                      <button
                        onClick={() => navigate(`/gig-offer/${params?.id}`)}
                      >
                        Buy Gig
                      </button>
                      <button>Contact through Email</button>
                    </div>
                  </div>
                </Col>

                {testimonialsData?.length >= 1 && (
                  <Col md={12}>
                    <div className="mt-5 review_main">
                      <h3>Reviews</h3>
                      <Slider {...Reviewsettings}>
                        {testimonialsData?.map((item) => (
                          <div className="review_div mt-4">
                            <div>
                              <div className="review_user_name">
                                <div>
                                  <img
                                    src={
                                      item.userDetails
                                        ?.profileImageLink || gigImg
                                    }
                                    alt=""
                                    height={50}
                                    style={{ borderRadius: "100%" }}
                                  />
                                  <div>
                                    <h6>
                                      {
                                       item?.userDetails
                                          ?.firstName
                                      }
                                    </h6>
                                    <p>{item?.addedDB}</p>
                                  </div>
                                </div>
                                <div>
                                  {Array.from({
                                    length: item?.rating,
                                  }).map(() => (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        d="M7.32831 19.923L8.56831 14.61L4.44531 11.038L9.87631 10.568L12.0033 5.55701L14.1303 10.567L19.5603 11.037L15.4383 14.609L16.6783 19.922L12.0033 17.102L7.32831 19.923Z"
                                        fill="#F8F3EA"
                                      />
                                    </svg>
                                  ))}
                                </div>
                              </div>
                              <p className="mt-2">
                                {item?.comments}
                              </p>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default GigDescriptionComponent;


