import React from "react";
import { Row, Col, Conatiner } from "react-bootstrap";
import "./HeaderImgSection.css";
import AllCasesHeaderimg from "../../../assets/images/AllCasesHeaderimg.webp";

const HeaderImgSection = () => {
  return (
    <>
      <Row className="mb-4 h-row">
        <div className="HeaderImg">
          <img src={AllCasesHeaderimg} alt="AllCasesHeaderimg" />
          <h4 className="HeaderImgText">
            Every contribution gets us closer to a <br />
            healthier Pakistan
          </h4>
        </div>
      </Row>
    </>
  );
};

export default HeaderImgSection;
