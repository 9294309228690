import React, { useEffect } from "react";
import "./Modal.css";
import { updateUser } from "../../../../services/http/updateUserApi";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../../../redux/userSlice";
import Toast from "../../../../functions/Toast";

const Modal = ({
  closeFooterModal,
  loading,
  setLoading,
  userData,
  setEditMode,
  refetch,
}) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleSave = async () => {
    if (userData?.firstName == "" || userData?.lastName == "") {
      Toast.error("First & Last Name is required");
      return;
    }
    setLoading(true);
    console.log(userData.phoneNumber);
    const data = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      phoneNumber: userData.phoneNumber,
      CNICNumber: userData.CNICNumber,
      address: userData.address,
      postal: userData.postal,
      city: userData.city,
      stateProvince: userData.stateProvince,
      country: userData.country,
      TaxId: userData.TaxId,
      profileImageLink:
        userData.profileImageLink || user?.data?.profileImageLink,
    };
    console.log(data);
    const res = await updateUser(data);
    setLoading(false);
    closeFooterModal();
    if (res?.data?.success) {
      refetch();
      dispatch(getUser(res?.data?.data));
      setEditMode(false);
    }
  };

  return (
    <>
      <div className="Modalwrapper"></div>
      <div className="Modal_Container_">
        <h4 className="modal_heading_">Are you sure?</h4>
        {/* <p className="modal_para_ mt-2 mb-0">
          Update Your language and country
        </p> */}
        <div className="Btn_save_container mt-3">
          <button
            disabled={loading}
            onClick={closeFooterModal}
            className="cancel_btn mt-5"
          >
            Cancel
          </button>
          {loading ? (
            <button disabled={loading} className="save_btn mt-5">
              Saving....
            </button>
          ) : (
            <button className="save_btn mt-5" onClick={handleSave}>
              Confirm
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Modal;
