import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Select from "react-select";
import startCampaign from "../../../assets/PolicyTermsImages/start_campaign.png";
import privacyCampaign from "../../../assets/PolicyTermsImages/privacy_campaign.png";
import whatsappIcon from "../../../assets/PolicyTermsImages/whatsapp_icon.png";
import facebookIcon from "../../../assets/PolicyTermsImages/facebook_icon.png";
import "./Description.css";
import { useNavigate } from "react-router-dom";

const Description = () => {
  const navigate = useNavigate();

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const dashboardColorStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      color: "#fff",
      backgroundColor: "#014E7F",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "15px",
      boxShadow: "none",
      borderColor:
        state.isFocused || state.isHovered || state.isActive || state.onHovered
          ? "#fff"
          : "#fff",
      "&:hover": {
        borderColor: state.isFocused || state.isActive ? "#fff" : "#fff",
      },
      "&::placeholder": {
        color: "#fff", // Change placeholder color to white
      },
    }),
    option: (styles) => {
      return {
        ...styles,
        fontSize: "15px",
        color: "#000",
      };
    },
  };

  return (
    <div className="privacy_description">
      <Container>
        {/* <Row className='align-items-center justify-content-center'>
                    <Col md={6}>
                        <Select options={options} styles={dashboardColorStyles} className='search_policy' placeholder="Search Terms" />
                    </Col>
                    <Col md={4}>
                        <p className='contact_para'><span>Contact us</span> for further queries.</p>
                    </Col>
                </Row> */}

        <div className="description_details">
          <h6>Last Updated: August 30th, 2023</h6>

          <div>
            <h5>Welcome to ShifaLia!</h5>
            <p style={{ marginBottom: "30px" }}>
              By using this Site and the Services, in addition to any Additional
              Policies applicable to any particular features, Content, and
              functionality of the Services (incorporated into the Terms by
              reference), offered by ShifaLia, Users agree to be bound by these
              Terms.
            </p>

            <p>
              We may change these terms from time to time. If we do, we’ll
              inform you about any material changes, either by notifying you on
              the Site or by emailing you. New versions of the terms will never
              apply retroactively—we’ll tell you the exact date they go into
              effect. If you keep using ShifaLia after a change, it will
              automatically mean that you accept the new terms. These terms may
              be translated into another language for your convenience; in case
              of a conflict between the English version and any translations,
              the English version shall control.
            </p>
          </div>

          <div>
            <div class="table_index">
              <div class="row_main">
                <div class="title">
                  <a href="#1">1. What is ShifaLia</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">2</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#2">2. Creating an Account</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">2</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  <a href="#3">3. How Campaigns Work</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">2</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#4">4. How Campaigning works</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">3</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#5">5. Things ShifaLia is Not Responsible For</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">3</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#6">6. Our Responsibilities</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">3</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#7">7. Gigs On ShifaLia</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">4</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#8">8. Communication Between Users</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">4</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  <a href="#9">9. Our Fees</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">4</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#10">10. Our Intellectual Property</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">4</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#11">11. Your Intellectual Property</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">5</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#12">12. Copyright Infringement</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">5</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#13">13. Account Deletion</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">6</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#14">14. ShifaLia’s Rights</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">6</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#15">15. Legal Issues</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">6</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  {" "}
                  <a href="#16">16. System Outages Due to Maintenance</a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">6</div> */}
              </div>
              <div class="row_main">
                <div class="title">
                  <a href="#17">
                    {" "}
                    17. Usage of the Financial Assistance Received by the
                    Campaigner
                  </a>
                </div>
                <div class="dots"></div>
                {/* <div class="value">7</div> */}
              </div>
            </div>
          </div>

          <div>
            <h5 id="1">1. What is ShifaLia</h5>
            <p>
              ShifaLia is an online medical campaigning platform that allows its
              Users to create Campaigns for their medical expenses. In addition,
              our Platform also allows the Users to become supporters of another
              campaign to help them achieve their financial goals.
            </p>
          </div>
          <div>
            <h5 id="2">2. Creating an Account</h5>
            <p>
              You can browse ShifaLia without registering for an account. But to
              use some of ShifaLia’s functions, you’ll need to register, choose
              a username, and set a password. The information provided must be
              accurate and complete. Don’t impersonate anyone else or choose
              names that are offensive or that violate anyone’s rights. Failure
              to abide by these rules will result in the cancellation of the
              account.
            </p>
          </div>
          <div>
            <h5 id="3">3. How Campaigns Work</h5>
            <p>
              Users are provided with a platform where they can create medical
              Campaigns to raise the required amount. Each Campaign is
              independently verified for authenticity by the ShifaLia Team to
              ensure maximum accuracy. In addition, Campaigners are required to
              upload complete documents about their Campaign so that their
              potential supporters are well-informed. The following rules apply
              to Campaigners:
            </p>

            <ul>
              <li>
                Ensure accurate and complete details about the Campaign
                including all documents that verify the identity of the patient.
                Failure to comply will result in immediate termination of the
                Campaign from the platform.
              </li>
              <li>
                Respond promptly and truthfully to all questions posed in
                comments, messages, or updates including any questions or
                requests made by ShifaLia. In case of a dispute between the
                Campaigner and supporters, ShifaLia may provide your name, legal
                mailing address, and email address to your Contributors, or in
                the instances where the law requires it.
              </li>
              <li>
                Campaigners are required to provide updates to their backers.
              </li>
              <li>
                When you use the Services by creating a Campaign, you may
                receive information about Users that have Contributed to your
                Campaign, including personally identifiable information (“PII”),
                such as names, e-mail addresses, and physical addresses. The
                Campaigners must keep this information private and it must not
                be used for may not be used or disclosed for any other purposes,
                including cross-promotional marketing of any other products
                including your other products, without separate, verifiable
                consent from the User obtained independently from ShifaLia.
              </li>
            </ul>
          </div>

          <div>
            <h5 id="4">4. How Campaigning works </h5>
            <p>
              Users may agree to back a Campaign by deciding to provide
              financial assistance to a campaign of their choice. These
              transactions are sent to the Campaigner's bank accounts within 1
              business day. ShifaLia will not be responsible for providing any
              refunds once the Supporter decides to go ahead with the transfer.
            </p>
          </div>

          <div>
            <h5 id="5">5. Things ShifaLia is Not Responsible for</h5>

            <p style={{ marginBottom: "15px" }}>
              ShifaLia is not liable for any damages or losses related to your
              use of the Platform. We don’t become involved in disputes between
              users, or between users and any third party relating to the use of
              the Services. ShifaLia will not entertain any chargebacks, or
              refund claims once the Users go ahead with their decision to
              support a Campaign.
            </p>
            <p>
              Users release ShifaLia from all claims, damages, and demands of
              every kind - known and unknown, suspected or unsuspected,
              disclosed or undisclosed - arising out of or related to the
              Platform. All content that you access or use through the Platform
              is entirely at the Users’ own risk and Users solely are
              responsible for any resulting damage or loss to any party.
            </p>
          </div>

          <div>
            <h5 id="6">6. Our Responsibilities</h5>

            <p>ShifaLia is responsible for the following only:</p>
            <ul>
              <li>
                Providing a safe, secure, and trusted Platform for the Users.
              </li>
              <li>
                Verifying Campaigns for authenticity. Users interacting with
                Campaigns that have not yet been verified by ShifaLia do so at
                their own risk. ShifaLia bears NO responsibility for such
                Campaigns.
              </li>
              <li>
                ShifaLia is responsible for keeping all the private information
                provided by Users safe and secure.
              </li>
            </ul>
          </div>

          <div id="7">
            <h5>7. Gigs On ShifaLia</h5>

            <p style={{ marginBottom: "15px" }}>
              Campaigners are given the option of providing services in the form
              of Gigs on ShifaLia. Campaigners may decide on their own, without
              any compulsion, if they want to provide any services in return for
              receiving financial assistance. These Gigs, however, are not
              endorsed by ShifaLia, therefore, we distance ourselves from any
              and all issues created between the Campaigner and Supporter once
              they mutually decide to go ahead with a Gig.
            </p>
            <p>
              ShifaLia serves strictly as a bridge between Campaigners and
              Supporters in only the medical campaigning aspect of things. We
              are not liable for problems created in dealings. outside of the
              Platform. We will not intervene, nor mediate in matters of Gigs,
              as they are not endorsed by us.
            </p>
          </div>

          <div>
            <h5 id="8">8. Communication Between Users</h5>

            <p style={{ marginBottom: "15px" }}>
              Users can interact with Campaigns in the form of words of
              encouragement. These messages are shown privately to the
              Campaigner and are not available for other Users to see on the
              Platform.
            </p>
            <p>
              If a Campaigner reports a certain message, ShifaLia reserves the
              right to terminate the account of that User permanently if the
              message goes against societal norms and our Code of Conduct.
            </p>
          </div>

          <div>
            <h5 id="9">9. Our Fees</h5>
            <p>
              ShifaLia charges no upfront fees from the Campaigners, nor does it
              require the Users to pay to use its services. However, once the
              Campaign starts receiving financial support, ShifaLia only deducts
              a minuscule amount of only 1% of the amount raised. This amount is
              used for keeping the platform operational and allowing us to
              provide our Users with a seamless experience.
            </p>
          </div>

          <div id="10">
            <h5>10. Our Intellectual Property</h5>
            <p style={{ marginBottom: "15px" }}>
              Services provided by ShifaLia are legally protected in various
              ways, including copyrights, trademarks, service marks, patents,
              trade secrets, and other rights and laws. You agree to respect all
              copyright and other legal notices, information, and restrictions
              contained in any content accessed through the Platform. You also
              agree not to change, translate, or otherwise create derivative
              works of the Platform.
            </p>
            <p>
              ShifaLia grants you a license to reproduce content from the
              Platform for personal use only. This license covers both
              ShifaLia’s protected content and user-generated content on the
              Platform. (This license is worldwide, non-exclusive,
              non-sublicensable, and non-transferable.) If you want to use,
              reproduce, modify, distribute, or store any of this content for a
              commercial purpose, you need prior written permission from
              ShifaLia or the relevant copyright holder. A “commercial purpose”
              means you intend to use, sell, license, rent, or otherwise exploit
              content for commercial use, in any way.
            </p>
          </div>

          <div id="11">
            <h5>11. Your Intellectual Property</h5>
            <p>
              ShifaLia does not own the Content provided by the Users (your
              “Content”), however, we have your permission to use or copy it
              however we need. We generally use it to promote projects and
              showcase our community. Ultimately, you're responsible for the
              content you post, and you're vouching to us that it's ok to use.
            </p>

            <p>
              When Users submit a Campaign for ShifaLia’s review and
              verification process, they agree to the following terms:
            </p>

            <ul>
              <li>
                <b>ShifaLia can use the Content provided by its Users.</b> The
                Users grant us, and others acting on our behalf the worldwide,
                non-exclusive, perpetual, irrevocable, royalty-free,
                sublicensable, transferable right to use, exercise,
                commercialize, and exploit the copyright, publicity, trademark,
                and database rights for your Content.
              </li>
              <li>
                <b>
                  ShifaLia can make changes to the Content (like making edits or
                  translating it).
                </b>{" "}
                The Users grant us the right to edit, modify, reformat, excerpt,
                delete, or translate any of the Content provided by them.
              </li>
              <li>
                <b>
                  Users agree that if ShifaLia uses their Content, we are not
                  violating anyone’s rights or privacy.
                </b>{" "}
                Campaigners submitting their private information regarding their
                cases agree that ShifaLia using their Content will not infringe
                or violate the rights of any third party, including (without
                limitation) any privacy rights, publicity rights, copyrights,
                contract rights, or any other intellectual property or
                proprietary rights.
              </li>
              <li>
                <b>ShifaLia is not responsible for mistakes in your Content.</b>{" "}
                Once a Campaign has been submitted on the Platform, ShifaLia
                reserves the right to either accept or reject its publication on
                the Platform. Campaigns can be rejected if they have incorrect
                or incomplete information.
              </li>
            </ul>
          </div>

          <div id="12">
            <h5>12. Copyright Infringement</h5>
            <p style={{ marginBottom: "15px" }}>
              ShifaLia complies with the Copyright Ordinance, 1962 of Pakistan.
              The Ordinance lays out a system of legal requirements for dealing
              with allegations of copyright infringement. ShifaLia complies with
              these legal requirements and responds to notices of alleged
              infringement if they comply with the law and the requirements
              outlined in our Copyright Policy. We reserve the right to delete
              or disable content alleged to be infringing and to terminate
              accounts for repeat infringers. Any such actions will be taken at
              our sole indiscretion.
            </p>
            <p>
              If you would like to submit a claim of copyright infringement,
              please visit our Copyright Policy. You can reach out to the
              ShifaLia Team as well at the following address:
              copyright@shifalia.com
            </p>
          </div>

          <div id="13">
            <h5>13. Account Deletion</h5>
            <p style={{ marginBottom: "15px" }}>
              ShifaLia reserves the right to terminate accounts that do not
              adhere to our policies without having any obligation to comment on
              any reasons for this termination. Campaigns associated with these
              accounts will be taken off the platform with immediate effect.
            </p>
            <p>
              Users can delete their accounts by accessing their account
              settings. This will also result in any ongoing Campaigns
              affiliated with their account being removed from the Platform.
            </p>
          </div>

          <div id="14">
            <h5>14. ShifaLia’s Rights</h5>
            <p>
              To maintain control over our Platform and ensure an environment
              that is beneficial for our Users, we reserve the right to make
              certain decisions to protect the integrity of this Platform. These
              rights are as follows:
            </p>

            <ul>
              <li>
                We reserve the right to make changes to our Platform without
                prior notices or liabilities.
              </li>
              <li>
                We reserve the right to decide which accounts are eligible for
                our Services. ShifaLia may, under certain circumstances,
                withhold, restrict, and even terminate accounts that do not
                abide by the rules and regulations of our Platform.
              </li>
              <li>We can change our eligibility criteria at any time.</li>
              <li>
                We have the right to reject, remove, interrupt, or cancel a
                Campaign at any time without giving any reason.
              </li>
            </ul>
          </div>

          <div id="15">
            <h5>15. Legal Issues</h5>

            <p style={{ marginBottom: "15px" }}>
              If ShifaLia is sued or faces legal issues due to the actions of
              its Users, it is up to that User to defend, indemnify, and hold us
              harmless from all liabilities, claims, and expenses (including
              reasonable attorneys’ fees and other legal costs) that arise from
              or relate to your use or misuse of the platform.
            </p>
            <p style={{ marginBottom: "15px" }}>
              ShifaLia retains the right to take on the sole defence and control
              of any matter covered by this indemnification clause. In such
              instances, Users agree to cooperate and assist us in asserting any
              defences.
            </p>
            <p>
              By accepting the Terms of Use, you consent to be of service to the
              Platform if an action is brought against ShifaLia because of your
              incorrect use.
            </p>
          </div>

          <div id="16">
            <h5>16. System Outages Due to Maintenance</h5>
            <p>
              To maintain a Platform with perfect functionality, ShifaLia
              undergoes maintenance causing outages for some time. Any losses
              that occur during this time will not be the responsibility of
              ShifaLia.
            </p>
          </div>

          <div id="17">
            <h5>
              17. Usage of the Financial Assistance Received by the Campaigner
            </h5>
            <p>
              Campaigners are strongly directed to use the financial assistance
              for the mentioned purposes in their Campaign. ShifaLia and/or the
              Supporters reserve the right to take strict action against the
              Campaigner if they are found to be misusing the amount collected.
              Misuse of these financial contributions will result in immediate
              account termination alongside removal of the Campaign from the
              Platform. In addition to this, the Campaigner will become liable
              for legal proceedings started by ShifaLia and/or the Supporter.
            </p>
          </div>
        </div>

        <div className="mt-5">
          <Row>
            <Col md={7}>
              <div className="privacy_campaign">
                <Row className="align-items-end">
                  <Col md={7}>
                    <h6>Ready to start your own Campaign?</h6>
                    <button onClick={() => navigate("/starCampaigningForm")}>
                      Start Campaigning
                    </button>
                  </Col>
                  <Col md={5}>
                    <img src={privacyCampaign} alt="" />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={5}>
              <div className="start_campaign">
                <h6>
                  Confused about how to <br /> start a Campaign?
                </h6>
                <button onClick={() => navigate("/starCampaigningForm")}>
                  Check Here
                </button>

                <img src={startCampaign} alt="" />
              </div>
            </Col>
          </Row>
        </div>

        <div className="have_question">
          <Row className="align-items-center">
            <Col md={8}>
              <p>
                Have a question? Check out our FAQs page or chat with us on
                Facebook or WhatsApp.
              </p>
            </Col>
            <Col md={4}>
              <div className="questions_btn">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://wa.me/+923323165701"
                >
                  {" "}
                  <img src={whatsappIcon} alt="" /> Contact Us
                </a>
                <a
                  href="https://www.facebook.com/people/ShifaLia/100089498583736/?mibextid=LQQJ4d"
                  target="_blank"
                >
                  {" "}
                  <img src={facebookIcon} alt="" /> Contact Us
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Description;
