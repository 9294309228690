import React, { useRef } from "react";
import "./_campideas.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ideas1 from "../../../assets/FundRaisingIdeasImages/idea1.webp";
import ideas2 from "../../../assets/FundRaisingIdeasImages/idea2.webp";
import ideas3 from "../../../assets/FundRaisingIdeasImages/idea3.webp";
import ideas4 from "../../../assets/FundRaisingIdeasImages/idea4.webp";
import ideas5 from "../../../assets/FundRaisingIdeasImages/idea5.webp";
import ideas6 from "../../../assets/FundRaisingIdeasImages/idea6.webp";
import arrowLeft from "../../../assets/FundRaisingIdeasImages/arrowleft.png";
import arrowRight from "../../../assets/FundRaisingIdeasImages/arrowright.png";

const Campideas = () => {
  const sliderRef = useRef(null);
  const handleNext = () => {
    sliderRef.current.slickNext();
  };
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const cards = [
    {
      id: 1,
      content: "Virtual Talent Showcase",
      img: ideas1,
      para: "Showcase your community's hidden talents through a virtual event. Whether it's singing, dancing, or comedy, viewers can financial support their favorite performers.",
    },
    {
      id: 2,
      content: "Themed Virtual Fitness Challenge",
      img: ideas2,
      para: "Energize your community with a themed fitness challenge. Participants can set personal goals, and supporters can contribute based on achievements. Think dance-a-thons, yoga challenges, or step-count competitions.",
    },
    {
      id: 3,
      content: "DIY Masterclass Series",
      img: ideas3,
      para: "Unleash creativity with DIY masterclasses. Host sessions on anything from painting to cooking, and participants can financial support to access these exclusive tutorials. It's a fun way to learn new skills while supporting a cause.",
    },
    {
      id: 4,
      content: "Eco-Friendly Campaign",
      img: ideas4,
      para: "Go green with an eco-friendly Campaign. Sell reusable items, host a virtual tree-planting event, or challenge participants to undertake eco-friendly pledges in exchange for financial support.",
    },
    {
      id: 5,
      content: "Culinary Adventure Night",
      img: ideas5,
      para: "Take your taste buds on an adventure with a virtual cooking night. Participants can financial support for exclusive recipes, and everyone cooks together, creating a shared experience despite the distance.",
    },
    {
      id: 6,
      content: "Personalized Storytelling Campaigns",
      img: ideas6,
      para: "Let your supporters become storytellers. Encourage them to share personal stories and narratives related to your cause to ensure that others are able to resonate with your cause as well.",
    },
    {
      id: 7,
      content: "DIY Charity Auction",
      img: ideas3,
      para: "Host an online auction featuring donated items or experiences. From handmade crafts to virtual consultations, supporters bid, and all proceeds go towards your causes.",
    },
  ];
  return (
    <>
      <div className="campideas-main-container d-flex flex-column">
        <h1 className="text-center">Campaigning Ideas</h1>
        <div className="campideas-inner-main ">
          <span>
            Campaigning is the process of gathering financial support or
            resources to achieve a specific goal, often associated with
            charitable or organizational endeavors. It plays a crucial role in
            enabling various initiatives especially when it comes to helping
            individuals with their medical expenses.
            <br /> It fosters a culture of philanthropy, encouraging people to
            contribute to the greater good and make a positive impact on the
            lives of others.
            <br />
            With creative Campaigning ideas, you can transform somebody's life
            into something amazing. Together, let's create a world where
            kindness and compassion are at the heart of everything we do. Here
            are some Campaigning ideas;
          </span>
          <div className="camp-inner-slider">
            <Slider {...settings} ref={sliderRef}>
              {cards.map((card) => (
                <div key={card.id}>
                  <div className="slider-inner d-flex flex-column">
                    <img src={card.img} alt={card.content} />
                    <h3>{card.content}</h3>
                    <p>{card.para}</p>
                  </div>
                </div>
              ))}
            </Slider>
            <button className="prev-btn" onClick={handlePrev}>
              <img src={arrowLeft} alt="leftarrow" className="arrow-left" />
            </button>
            <button className="next-btn" onClick={handleNext}>
              <img src={arrowRight} alt="leftarrow" className="arrow-right" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Campideas;
