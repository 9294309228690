import React, { useRef, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import ImgIcon from "../../assets/FundraisingTipsImages/imageIcon.png";
import { useNavigate } from "react-router-dom";
import { postNewServiceData } from "../../services/http/newServiceApi";
import Toast from "../../functions/Toast";
import { ThreeDots } from "react-loader-spinner";
import { useQuery } from "@apollo/client";
import { GET_DICTIONARY_QUERY } from "../../services/methods/Query";
import './GigForm.css'

const GigForm = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photoLinks, setphotoLinks] = useState([]);
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [features, setFeatures] = useState([]);
  const [featVal, setFeatVal] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [maxPrice, setmaxPrice] = useState("");
  const [minPrice, setminPrice] = useState("");
  const [alternatePhone, setAlternatePhone] = useState("");
  const [imagesPreview, setImagesPreview] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    fileInputRef.current?.click();
  };
  const handleAdd = (e) => {
    e.preventDefault();
    if (featVal == "") {
      return;
    }
    setFeatVal("");
    setFeatures([...features, featVal]);
  };

  const { data } = useQuery(GET_DICTIONARY_QUERY);
  const arrOfData = data?.getDictionary?.data;

  const categories = arrOfData?.filter((item) => {
    return item?.group === "ServiceType";
  });

  const navigateHandler = async () => {
    console.log(
      photoLinks.length,
      title,
      description,
      maxPrice,
      minPrice,
      serviceType,
      email,
      features
    );
    if (
      photoLinks.length <= 0 ||
      title == "" ||
      description == "" ||
      maxPrice == "" ||
      minPrice == "" ||
      serviceType == "" ||
      email == "" ||
      features.length <= 0
      // phoneNumber == "" ||
      // alternatePhone == "" ||
      // amount == "" ||
    ) {
      Toast.error("Please provide all fields");
      return;
    }
    const formData = new FormData();

    formData.append("title", title);
    formData.append("description", description);
    for (let i = 0; i < photoLinks?.length; i++) {
      formData.append("photoLinks", photoLinks[i]);
    }
    formData.append("gigOffers", features);
    formData.append("email", email);
    formData.append("serviceType", serviceType);
    formData.append("minPrice", Number(minPrice));
    formData.append("maxPrice", Number(maxPrice));
    // formData.append("phoneNumber", phoneNumber);
    // formData.append("alternatePhone", alternatePhone);
    // formData.append("amount", Number(amount));

    try {
      setLoading(true);
      const res = await postNewServiceData(formData);
      setLoading(false);
      if (res?.data?.success) {
        Toast.success("Created");
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      Toast.error("Something went wrong");
    }
  };

  const handleImage = (e) => {
    setphotoLinks(e.target.files);
    const files = Array.from(e.target.files);

    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="fundraising_step5_main">
      <Container>
        {/* <div className="change_lang">
          <Dropdown>
            <Dropdown.Toggle>language</Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>English</Dropdown.Item>
              <Dropdown.Item>Urdu</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}

        <div className="step5_form">
          <div>
            <label>Gigs's Title</label>
            <input
              disabled={loading}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div>
            <label>Gigs's Description</label>
            <textarea
              disabled={loading}
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div>
            <label>Service Type</label>
            <select
              onChange={(e) => setServiceType(e.target.value)}
              disabled={loading}
              rows={5}
            >
              {categories?.map((item) => (
                <option value={item._id}>{item.code}</option>
              ))}
            </select>
          </div>
          <div>
            <label>Enter your work Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
              rows={5}
            />
          </div>
          <Row>
            <label>
              What does your gig Offers? (Mention in Bullet Points){" "}
            </label>
            <Col md={12} style={{ display: "flex", gap: "5px" }}>
              <input
                disabled={loading}
                value={featVal}
                onChange={(e) => setFeatVal(e.target.value)}
              />
              <button
                onClick={handleAdd}
                style={{
                  borderRadius: "5px",
                  padding: "10px",
                  backgroundColor: "#afc8c9",
                  border: "none",
                }}
              >
                Add
              </button>
            </Col>
            {features.length > 0 && (
              <div>
                {features.map((item) => (
                  <li>{item}</li>
                ))}
              </div>
            )}
          </Row>
          <div style={{ width: "50%" }}>
            <label>What is your Price Range?</label>
            <div className="price_gigs">
              <input
                type="number"
                value={minPrice}
                onChange={(e) => setminPrice(e.target.value)}
                disabled={loading}
                rows={2}
              />
              <span>To</span>
              <input
                type="number"
                value={maxPrice}
                onChange={(e) => setmaxPrice(e.target.value)}
                disabled={loading}
                rows={2}
              />
            </div>
          </div>
          <div>
            <label>Upload Photos that describe your gig</label>
            <Row style={{ gap: "10px 0" }}>
              <Col md={12}>
                <div className="upload_box">
                  <img src={ImgIcon} alt="" />
                  <p>Drag a photo or</p>
                  <button onClick={handleClick}>Browse</button>
                  <input
                    disabled={loading}
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg"
                    multiple
                    onChange={handleImage}
                  />
                </div>
                <div id="imagePreview">
                  {imagesPreview.map((image, index) => (
                    <img
                      id="img"
                      key={index}
                      src={image}
                      alt="Product Preview"
                    />
                  ))}
                </div>
              </Col>
              {/* <Col md={4}>
                <div className="upload_box">
                  <img src={ImgIcon} alt="" />
                  <p>Drag a photo or</p>
                  <button onClick={handleClick}>Browse</button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    multiple
                    onChange={(e) => setphotoLinks(e.target.files)}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="upload_box">
                  <img src={ImgIcon} alt="" />
                  <p>Drag a photo or</p>
                  <button onClick={handleClick}>Browse</button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    multiple
                    onChange={(e) => setphotoLinks(e.target.files)}
                  />
                </div>
              </Col> */}
            </Row>
          </div>

          {/* <div>
            <label>Contact Information</label>

            <Row style={{ gap: "20px 0" }}>
              <Col md={4}>
                <input
                  disabled={loading}
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setphoneNumber(e.target.value)}
                />
              </Col>
              <Col md={4}>
                <input
                  disabled={loading}
                  placeholder="Alternate Phone Number"
                  value={alternatePhone}
                  onChange={(e) => setAlternatePhone(e.target.value)}
                />
              </Col>
              <Col md={4}>
                <input
                  type="number"
                  disabled={loading}
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Col>
              <Col md={12}>
                <input
                  disabled={loading}
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Row>
          </div> */}

          <div className="done_btn">
            {loading ? (
              <button>
                <ThreeDots
                  visible={true}
                  height="20"
                  width="30"
                  color="#FFFFFF  "
                  radius="9"
                  ariaLabel="three-dots-loading"
                />
              </button>
            ) : (
              <button onClick={navigateHandler}>Done</button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default GigForm;
