import profile from "../../../assets/casesImgs/cmtprofile.png";
import "./donations.scss";
import React from "react";

const Supportercard = ({ name, amount }) => {
  return (
    <>
      <div className="supportercard d-flex w-100 my-3">
        <img src={profile} alt="profile" />
        <div className="supportercardContent w-100">
          <span>
            {name} <p>1 day ago</p>
          </span>
          <span>
            Donation Amount: <h6>Rs. {amount}</h6>
          </span>
        </div>
      </div>
    </>
  );
};

export default Supportercard;
