import React from "react";
import "./fundIdeas.scss";
import ideas1 from "../../../assets/FundRaisingIdeasImages/idea1.webp";
import ideas2 from "../../../assets/FundRaisingIdeasImages/idea2.webp";
import ideas3 from "../../../assets/FundRaisingIdeasImages/idea3.webp";
import ideas4 from "../../../assets/FundRaisingIdeasImages/idea4.webp";
import ideas5 from "../../../assets/FundRaisingIdeasImages/idea5.webp";
import ideas6 from "../../../assets/FundRaisingIdeasImages/idea6.webp";

const FundIdeas = () => {
  return (
    <>
      <div className="fund_ideas-section">
        <div className="ideassection">
          <h6>Campaigning Ideas</h6>
          <p>
            Campaigning is the process of gathering financial support or
            resources to achieve a specific goal, often associated with
            charitable or organizational endeavors. It plays a crucial role in
            enabling various initiatives especially when it comes to helping
            individuals with their medical expenses.
          </p>
          <p>
            It fosters a culture of philanthropy, encouraging people to
            contribute to the greater good and make a positive impact on the
            lives of others.
          </p>
          <p>
            With creative Campaigning ideas, you can transform somebody's life
            into something amazing. Together, let's create a world where
            kindness and compassion are at the heart of everything we do. Here
            are some Campaigning ideas;
          </p>
          {/* <h6>How to raise money for your Cause?</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
            volutpat elit ut quam viverra efficitur. Sed eu sem tristique,
            vestibulum nunc sed, pharetra libero.{" "}
          </p> */}
          <ul>
            <a href="#bakesale">
              <li>Virtual talent showcase</li>
            </a>
            <a href="#art">
              <li>Themed virtual fitness challenge</li>
            </a>
            <a href="#workshop">
              <li>DIY masterclass series</li>
            </a>
            <a href="#gala">
              <li>Eco-friendly Campaign</li>
            </a>
            <a href="#openday">
              <li>Culinary adventure night</li>
            </a>
            <a href="#talentShow">
              <li>Personalized storytelling campaigns</li>
            </a>
            <a href="#charity">
              <li>DIY charity auction</li>
            </a>
          </ul>
          <div data-bs-spy="scroll">
            <div className="list-items">
              <div className="item">
                <ul>
                  <li id="bakesale">Virtual Talent Showcase</li>
                </ul>
                <img src={ideas1} alt="" className="w-100" />
                <p>
                  Showcase your community's hidden talents through a virtual
                  event. Whether it's singing, dancing, or comedy, viewers can
                  financial support their favorite performers.
                </p>
              </div>
              <div className="item">
                <ul>
                  <li id="art">Themed Virtual Fitness Challenge</li>
                </ul>
                <img src={ideas2} alt="" className="w-100" />
                <p>
                  Energize your community with a themed fitness challenge.
                  Participants can set personal goals, and supporters can
                  contribute based on achievements. Think dance-a-thons, yoga
                  challenges, or step-count competitions.
                </p>
              </div>
              <div className="item">
                <ul>
                  <li id="workshop">DIY Masterclass Series</li>
                </ul>
                <img src={ideas3} alt="" className="w-100" />
                <p>
                  Unleash creativity with DIY masterclasses. Host sessions on
                  anything from painting to cooking, and participants can
                  financial support to access these exclusive tutorials. It's a
                  fun way to learn new skills while supporting a cause.
                </p>
              </div>
              <div className="item">
                <ul>
                  <li id="gala">Eco-Friendly Campaign</li>
                </ul>
                <img src={ideas4} alt="" className="w-100" />
                <p>
                  Go green with an eco-friendly Campaign. Sell reusable items,
                  host a virtual tree-planting event, or challenge participants
                  to undertake eco-friendly pledges in exchange for financial
                  support.
                </p>
              </div>
              <div className="item">
                <ul>
                  <li id="openday">Culinary Adventure Night</li>
                </ul>
                <img src={ideas5} alt="" className="w-100" />
                <p>
                  Take your taste buds on an adventure with a virtual cooking
                  night. Participants can financial support for exclusive
                  recipes, and everyone cooks together, creating a shared
                  experience despite the distance.
                </p>
              </div>
              <div className="item pb-5">
                <ul>
                  <li id="talentShow">Personalized Storytelling Campaigns</li>
                </ul>
                <img src={ideas6} alt="" className="w-100" />
                <p>
                  Let your supporters become storytellers. Encourage them to
                  share personal stories and narratives related to your cause to
                  ensure that others are able to resonate with your cause as
                  well.
                </p>
              </div>
              <div className="item pb-5">
                <ul>
                  <li id="charity">DIY Charity Auction</li>
                </ul>
                <img src={ideas6} alt="" className="w-100" />
                <p>
                  Host an online auction featuring donated items or experiences.
                  From handmade crafts to virtual consultations, supporters bid,
                  and all proceeds go towards your causes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FundIdeas;
