import React, { useEffect, useState } from "react";
import SideBar from "../../components/Sidebar/SideBar";
import "../Edit details page/editpage1.css";
import FundraiseDetails from "../../components/Forms/Fundraise Form/FundraiseDetail";
import { useQuery } from "@apollo/client";
import { GET_CASE_BY_ID } from "../../services/methods/Query";
import { useLocation, useParams } from "react-router-dom";
import { Provider } from "../../context/Context";
import Loader from "../../components/loaderComponent/loader";
import { ColorRing } from "react-loader-spinner";
import MedicalDocument from "../../components/Forms/Medical Document/MedicalDocument";
import Patientsverification from "../../components/Forms/Family Background/FamilyBackground";
import BackGround from "../../components/Forms/Background/BackGround";
import SocialProfile from "../../components/Forms/Social Profile/SocialProfile";
import HospitalDetails from "../../components/Forms/Hospital Details/HospitalDetails";
import Team from "../../components/Forms/Team/Team";
import PersonalDetails from "../../components/Forms/Personal Details/PersonalDetails";
import FamilyBackground from "../../components/Forms/Family Background/FamilyBackground";
import PatientVerification from "../../components/Forms/Patient Varification/PatientVarification";

const Editdetailpage1 = () => {
  const { setCaseById, setfundraiserId } = Provider();
  const params = useParams();
  // const location = useLocation();
  // const state = location.state;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { data, loading } = useQuery(GET_CASE_BY_ID, {
    fetchPolicy: "network-only",
    variables: {
      getAllCasesId: params?.id,
    },
    onCompleted: () => setCaseById(data?.getAllCases?.data?.items[0]),
  });
  // setfundraiserId(state?.from);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    setfundraiserId(params?.id);
  }, []);
  const [tab, setTab] = useState("personal");
  return (
    <>
      <div className="main">
        <div className="">
          <SideBar
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
            setTab={setTab}
          />
        </div>
        <div>
          {tab == "personal" && (
            <PersonalDetails
              isSidebarOpen={isSidebarOpen}
              loading={loading}
              setTab={setTab}
            />
          )}
          {tab == "fundraise" && (
            <FundraiseDetails
              isSidebarOpen={isSidebarOpen}
              loading={loading}
              setTab={setTab}
            />
          )}
          {tab == "medical" && (
            <MedicalDocument isSidebarOpen={isSidebarOpen} setTab={setTab} />
          )}
          {tab == "patient" && (
            <PatientVerification
            isSidebarOpen={isSidebarOpen}
            setTab={setTab}
            />
          )}
          {tab == "family" && (
            <FamilyBackground isSidebarOpen={isSidebarOpen} setTab={setTab} />
          )}
          {tab == "person" && (
            <BackGround isSidebarOpen={isSidebarOpen} setTab={setTab} />
          )}
          {tab == "social" && (
            <SocialProfile isSidebarOpen={isSidebarOpen} setTab={setTab} />
          )}
          {tab == "hospitaluser" && (
            <HospitalDetails isSidebarOpen={isSidebarOpen} setTab={setTab} />
          )}
          {/* {tab == "team" && (
            <Team isSidebarOpen={isSidebarOpen} setTab={setTab} />
          )} */}
        </div>
      </div>
    </>
  );
};

export default Editdetailpage1;
