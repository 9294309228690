// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offers_main {
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gig_table {
  border: 1px solid black;
  width: 100%;
  height: max-content;
}

.gig_table th,
td {
  padding: 10px;
}

.view_btn {
  padding: 5px 15px;
  border: none;
  background-color: #014e7f;
  color: white;
  border-radius: 5px;
}
.pagination_btn {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.pagination_btn button {
  padding: 5px 15px;
  border: none;
  background-color: #014e7f;
  color: white;
  border-radius: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/components/userProfileComponents/offers/Offers.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,mBAAmB;AACrB;;AAEA;;EAEE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,oBAAoB;AACtB","sourcesContent":[".offers_main {\n  padding: 0px 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n}\n\n.gig_table {\n  border: 1px solid black;\n  width: 100%;\n  height: max-content;\n}\n\n.gig_table th,\ntd {\n  padding: 10px;\n}\n\n.view_btn {\n  padding: 5px 15px;\n  border: none;\n  background-color: #014e7f;\n  color: white;\n  border-radius: 5px;\n}\n.pagination_btn {\n  width: 100%;\n  margin-top: 10px;\n  display: flex;\n  justify-content: space-between;\n  gap: 10px;\n  align-items: center;\n}\n\n.pagination_btn button {\n  padding: 5px 15px;\n  border: none;\n  background-color: #014e7f;\n  color: white;\n  border-radius: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
