// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.our_goal_section .mt-5 h1 {
  font-size: 24px;
  font-weight: 700;
}

.our_goal_section p {
  margin-top: 10px;
}

.our_goal_section button {
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 12px 15px !important;
  font-weight: 700 !important;
}
.home-goals {
  padding-right: 3rem;
  padding-left: 3rem;
}
@media only screen and (max-width: 600px) {
  .home-goals {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .our_goal_section .mt-5 h1 {
    font-size: 18px;
    font-weight: 700;
    margin-top: -30px !important;
  }
  .our_goal_section p {
    font-size: 14px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/LandingPageComponents/OurGoals/OurGoals.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,0BAA0B;EAC1B,4BAA4B;EAC5B,6BAA6B;EAC7B,2BAA2B;AAC7B;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE;IACE,6BAA6B;IAC7B,4BAA4B;EAC9B;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,4BAA4B;EAC9B;EACA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".our_goal_section .mt-5 h1 {\n  font-size: 24px;\n  font-weight: 700;\n}\n\n.our_goal_section p {\n  margin-top: 10px;\n}\n\n.our_goal_section button {\n  font-size: 16px !important;\n  line-height: 18px !important;\n  padding: 12px 15px !important;\n  font-weight: 700 !important;\n}\n.home-goals {\n  padding-right: 3rem;\n  padding-left: 3rem;\n}\n@media only screen and (max-width: 600px) {\n  .home-goals {\n    padding-right: 0px !important;\n    padding-left: 0px !important;\n  }\n\n  .our_goal_section .mt-5 h1 {\n    font-size: 18px;\n    font-weight: 700;\n    margin-top: -30px !important;\n  }\n  .our_goal_section p {\n    font-size: 14px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
