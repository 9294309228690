import React, { useRef, useState } from "react";
// import '../Social Profile/socialprofile.css'
import "./medicaldocument.css";

import { Col, Row, Container, Button, Form, FormGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "../../../context/Context";
import { useNavigate } from "react-router-dom";
import Toast from "../../../functions/Toast";
import { BsPlus } from "react-icons/bs";

const MedicalDocument = ({ isSidebarOpen, setTab }) => {
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const {
    hospitalName,
    setHospitalName,
    consentLetter,
    setConsentLetter,
    otherDocsLinks,
    setotherDocsLinks,
    ReportsLinks,
    setReportsLinks,
    caseById,
  } = Provider();

  const navigate = useNavigate();
  const [prevConsent, setPrevConsent] = useState([]);
  const [prevDocs, setPrevDocs] = useState([]);
  const [prevReport, setPrevReport] = useState([]);
  const handleConsentLetter = (e) => {
    setConsentLetter(e.target.files);
    const files = Array.from(e.target.files);
    setPrevConsent([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPrevConsent((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const handleOtherDocs = (e) => {
    setotherDocsLinks(e.target.files);
    const files = Array.from(e.target.files);
    setPrevDocs([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPrevDocs((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const handleReportLinks = (e) => {
    setReportsLinks(e.target.files);
    const files = Array.from(e.target.files);
    setPrevReport([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setPrevReport((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = () => {
    navigate("/detailpage4");
  };

  return (
    <>
      <div
        className={`form-container ${
          isSidebarOpen ? "form-container-open" : ""
        }`}
      >
        <h3 className=" socialprofile">Medical Document</h3>
        <Container className="mt-1 mb-5 form-09">
          <Row>
            <Container className=" py-2 px-5">
              <Form>
                <FormGroup className=" d-flex flex-wrap form-colom  ">
                  <Col lg={4} md={5} sm={12} xs={12} className="form-group ">
                  <label>Hospital Name</label>
                    <input
                      type="text"
                      id="text"
                      className="fields px-4"
                      placeholder="Hospital Name"
                      value={hospitalName}
                      onChange={(e) => setHospitalName(e.target.value)}
                    />
                  </Col>
                  <Col lg={4} md={5} sm={12} xs={12} className="form-group ">
                  <label>Consent Letter</label>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRef1.current?.click();
                      }}
                      className="button-upload"
                    >
                      <span className="Text-photo d-flex ">Consent Letter from Patient</span>
                      <BsPlus size="30px" className="plus-icoon" />
                    </button>
                    <input
                      type="file"
                      id="img"
                      className="fields px-4"
                      placeholder="Add Photo"
                      accept="image/*"
                      multiple
                      style={{ display: "none" }}
                      ref={fileInputRef1}
                      onChange={handleConsentLetter}
                    />
                    <div>
                      {prevConsent.length >= 1
                        ? prevConsent?.map((i) => (
                            <img className="edit_img_prev" src={i} />
                          ))
                        : caseById?.consentLetter?.map((i) => (
                            <img src={i} className="edit_img_prev" />
                          ))}
                    </div>
                    <Form.Text className="Form-Text">
                      Are you raising campaign for another patient on their
                      behalf? Please upload a picture of the completed consent
                      form.
                    </Form.Text>
                    <p id="array"></p>
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex flex-wrap  ">
                  <Col lg={4} md={5} sm={12} xs={12} className="form-group">
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        fileInputRef2.current?.click();
                      }}
                      className="button-upload"
                    >
                      <span className="Text-photo d-flex ">Medical Reports</span>
                      <BsPlus size="30px" className="plus-icoon" />
                    </button>
                    <input
                      type="file"
                      id="img"
                      className="fields px-4"
                      placeholder="Add Photo"
                      accept="image/*"
                      multiple
                      style={{ display: "none" }}
                      ref={fileInputRef2}
                      onChange={handleOtherDocs}
                    />
                    <div>
                      {prevDocs.length >= 1
                        ? prevDocs?.map((i) => (
                            <img className="edit_img_prev" src={i} />
                          ))
                        : caseById?.otherDocsLinks?.map((i) => (
                            <img src={i} className="edit_img_prev" />
                          ))}
                    </div>
                    <Form.Text className=" mt-4">
                      Please upload the following:<br></br>
                      1.consulting doctor’s medical certificate certifying that
                      the patient is under his care.
                    </Form.Text>
                  </Col>
                  <Col lg={4} md={5} sm={12} xs={12} className="form-group">
                    <div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          fileInputRef3.current?.click();
                        }}
                        className="button-upload"
                      >
                        <span className="Text-photo d-flex ">Other Documents</span>
                        <BsPlus size="30px" className="plus-icoon" />
                      </button>
                      <input
                        type="file"
                        id="img"
                        className="fields px-4"
                        placeholder="Add Photo"
                        accept="image/*"
                        multiple
                        style={{ display: "none" }}
                        ref={fileInputRef3}
                        onChange={handleReportLinks}
                      />
                      <div>
                        {prevReport.length >= 1
                          ? prevReport?.map((i) => (
                              <img className="edit_img_prev" src={i} />
                            ))
                          : caseById?.ReportsLinks?.map((i) => (
                              <img src={i} className="edit_img_prev" />
                            ))}
                      </div>
                      <Form.Text className="Form-Text">
                        Please upload pictures of all the patient’s medical
                        records including:
                        <li>prescriptions</li>
                        <li>treatments</li>
                        <li>reports</li>
                        (Make sure to include all relevant medical documents to
                        ensure maximum donor interaction with your cause.)
                      </Form.Text>
                    </div>
                  </Col>
                </FormGroup>
              </Form>
              <Button
                onClick={() => setTab("patient")}
                className="Done-button mt-4"
              >
                Next
              </Button>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MedicalDocument;
