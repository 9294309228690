import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import "./UserPSidebar.scss";
import logo from "../../../assets/images/Logo.png";
import patientDp from "../../../assets/images/PatientsDp.png";
import userDP from "../../../../src/assets/images/PatientsDp.png";
import MyProfile from "../myProfile/myProfile";
import AccountSettings from "../accountSetting/accountsetting.js";
import Notifications from "../notifications/notifications.jsx";
import { Provider } from "../../../context/Context.jsx";
import Loader from "../../Loader/Loader.jsx";
import { useQuery } from "@apollo/client";
import { GET_PROFILE_QUERY } from "../../../services/methods/Query.js";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../functions/Toast.js";
import { checkToken, getUser } from "../../../redux/userSlice.js";
import { useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import Offers from "../offers/Offers.jsx";

const UserPSidebar = () => {
  const [activeFilter, setActiveFilter] = useState("MyProfile");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = async () => {
    await cookies.remove("token", { path: "/" });
    await dispatch(getUser(null));
    dispatch(checkToken(false));
    Toast.success("Logout Successfully");
  };

  const cookies = new Cookies();
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const { data, refetch, loading } = useQuery(GET_PROFILE_QUERY, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        Authorization: cookies.get("token"),
      },
    },
  });
  console.log(data);
  const userData = data?.GetPatientProfile?.data?.data;
  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="user-dashboard">
        <div className="user-sidebar">
          <div className="back-div">
            <Icon
              icon="fluent-mdl2:navigate-back"
              width="48"
              height="48"
              className="backk-icon cursor-pointer"
              color="#F8F8F8"
              onClick={() => navigate("/")}
            />
          </div>
          <button className="toggle-sidebar">
            <Icon icon="ic:round-menu" width="40" height="40" />
          </button>
          <div className="user-dp">
            <img src={userData?.profileImageLink || userDP} alt="" />
            <h6>{userData?.firstName}</h6>
            <span>
              {userData?.email}
              <p>{userData?.phoneNumber}</p>
            </span>
          </div>
          <div className="sidebar-opt">
            <button
              className={`${activeFilter === "MyProfile" ? "active" : ""}`}
              onClick={() => setActiveFilter("MyProfile")}
            >
              <Icon
                icon="gg:profile"
                width="48"
                height="48"
                className="side-icon "
              />
              <span>My Profile</span>
            </button>
            <button
              className={`${
                activeFilter === "AccountSettings" ? "active" : ""
              }`}
              onClick={() => setActiveFilter("AccountSettings")}
            >
              <Icon
                icon="ic:outline-manage-accounts"
                width="48"
                height="48"
                className="side-icon"
              />
              <span>Account Settings</span>
            </button>
            <button
              className={`${activeFilter === "Notifications" ? "active" : ""}`}
              onClick={() => setActiveFilter("Notifications")}
            >
              <Icon
                icon="iconamoon:notification-bold"
                width="48"
                height="48"
                className="side-icon"
              />
              <span>Notifications</span>
            </button>
            <button
              className={`${activeFilter === "offers" ? "active" : ""}`}
              onClick={() => setActiveFilter("offers")}
            >
              <Icon
                icon="pajamas:todo-done"
                width="48"
                height="48"
                className="side-icon"
              />
              <span>Offers For Your Gig(s)</span>
            </button>
          </div>
          <div className="bottom">
            <button onClick={handleLogout}>
              <Icon
                icon="ic:baseline-logout"
                color="white"
                width="40"
                height="40"
              />
              <span>Logout</span>
            </button>
          </div>
        </div>
        {/* <div className="mobil-user-drawer"></div> */}
        <Drawer
          placement="left"
          closable={false}
          onClose={toggleDrawer}
          open={isDrawerOpen}
          width="80%"
          className="mobile-user-sidebar-drawer"
        >
          <div className="mobile-user-sidebar ">
            <div className="back-div">
              <Icon
                icon="fluent-mdl2:navigate-back"
                width="48"
                height="48"
                className="backk-icon cursor-pointer"
                color="#F8F8F8"
                onClick={() => navigate("/")}
              />
            </div>
            <div className="user-dp">
              <img src={userData?.profileImageLink || userDP} alt="" />
              <h6>{userData?.firstName}</h6>
              <span>
                {userData?.email}
                <p>{userData?.phoneNumber}</p>
              </span>
            </div>
            <div className="sidebar-opt">
              <button
                className={`${activeFilter === "MyProfile" ? "active" : ""}`}
                onClick={() => {
                  setActiveFilter("MyProfile");
                  toggleDrawer();
                }}
              >
                <Icon
                  icon="gg:profile"
                  width="48"
                  height="48"
                  className="side-icon "
                />
                <span>My Profile</span>
              </button>
              <button
                className={`${
                  activeFilter === "AccountSettings" ? "active" : ""
                }`}
                onClick={() => {
                  setActiveFilter("AccountSettings");
                  toggleDrawer();
                }}
              >
                <Icon
                  icon="ic:outline-manage-accounts"
                  width="48"
                  height="48"
                  className="side-icon"
                />
                <span>Account Settings</span>
              </button>
              <button
                className={`${
                  activeFilter === "Notifications" ? "active" : ""
                }`}
                onClick={() => {
                  setActiveFilter("Notifications");
                  toggleDrawer();
                }}
              >
                <Icon
                  icon="iconamoon:notification-bold"
                  width="48"
                  height="48"
                  className="side-icon"
                />
                <span>Notifications</span>
              </button>
              <button
                className={`${activeFilter === "offers" ? "active" : ""}`}
                onClick={() => {
                  setActiveFilter("offers");
                  toggleDrawer();
                }}
              >
                <Icon
                  icon="pajamas:todo-done"
                  width="48"
                  height="48"
                  className="side-icon"
                />
                <span>Offers For Your Gig(s)s</span>
              </button>
            </div>
            <div className="bottom">
              <button onClick={handleLogout}>
                <Icon
                  icon="ic:baseline-logout"
                  color="white"
                  width="40"
                  height="40"
                  className="logout-icon"
                />
                <span>Logout</span>
              </button>
            </div>
          </div>
        </Drawer>

        <div className="user-main-content">
          <div className="top">
            <Icon
              icon="material-symbols:menu"
              className="menu-icon"
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer}
            />
            <img
              src={logo}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
          </div>
          <div className="components-sec">
            {activeFilter === "MyProfile" && <MyProfile user={userData} />}
            {activeFilter === "Notifications" && <Notifications />}
            {activeFilter === "AccountSettings" && (
              <AccountSettings data={userData} refetch={refetch} />
            )}
            {activeFilter === "offers" && <Offers />}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPSidebar;
