import React from "react";
import "./Confirmation.css";
import gigImg from "../../assets/images/gig1.png";
import Slider from "react-slick";
import { useQuery } from "@apollo/client";
import { GET_GIG_OFFER_BY_ID } from "../../services/methods/Query";
import { useNavigate, useParams } from "react-router-dom";

const Confirmation = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const params = useParams();
  const navigate = useNavigate();

  const { data } = useQuery(GET_GIG_OFFER_BY_ID, {
    variables: {
      id: params?.id,
    },
  });

  const gigData = data?.getGigOfferById?.data[0];

  return (
    <div className="confirm_main">
      <div className="confirm_top">
        <h3>Order Confirmation</h3>
        <p>Confirm your order!</p>
      </div>
      <p className="confirm_summary">Detail Project Summary</p>
      <div className="details_container_parent">
        <div className="details_container">
          <div className="container_child_1">
            {/* <h4>{gigData?.title}</h4> */}
          
            <h5>Project Detail</h5>
            <p>{gigData?.description}</p>
          </div>
          <div className="container_child_2">
            <Slider {...settings}>
              {gigData?.ImageLinks?.map((item) => (
                <div className="gig_confirm_img">
                  <img src={item} alt="" />
                </div>
              ))}
            </Slider>
            {/* <div className="confirm_bottom_img">
              <img src={gigImg} alt="" />
              <img src={gigImg} alt="" />
              <img src={gigImg} alt="" />
              <img src={gigImg} alt="" />
              <img src={gigImg} alt="" />
            </div> */}
          </div>
        </div>
        <hr className="confirm_hr" />
        <div className="confirm_buttom">
          <p>Ready to avail the services provided by {gigData?.userDetails[0]?.firstName}?</p>
          <div className="confirm_button">
            {/* <button onClick={() => Navigate('/')}>Draft</button> */}
            <button onClick={() => navigate(`/order-confirmed/${params?.id}`)}>
            Buy Gig
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
