export const pagesArr = [
  {
    name: "Home",
    path: "/landingpages",
  },
  {
    name: "All Cases",
    path: "/allCases",
  },
  {
    name: "Campaign Tips",
    path: "/campaigningTipsandIdeas",
  },
  {
    name: "How it work",
    path: "/works",
  },
  {
    name: "About us",
    path: "/about-us",
  },
  {
    name: "Blogs",
    path: "/blogs",
  },
  {
    name: "All Articles",
    path: "/allArticles",
  },
  {
    name: "Raise Concern",
    path: "/RaiseAConcern",
  },
  {
    name: "Our Mission",
    path: "/our-mission-vision",
  },
  {
    name: "Privacy Policy",
    path: "/privacypolicy",
  },
  {
    name: "Terms & Condition",
    path: "/termsOfUse",
  },
  {
    name: "Safety & Security",
    path: "/trustSafety",
  },
  {
    name: "Contact Us",
    path: "/contactusPakistan",
  },
  {
    name: "Goal Calculator",
    path: "/PricingPage",
  },
  {
    name: "Waitlist",
    path: "/waitlist",
  },
  {
    name: "Help Center",
    path: "/helpcenter",
  },
  {
    name: "All Gigs",
    path: "/all-gigs",
  },
];
