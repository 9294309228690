import React, { useState } from "react";
import "../Auth.css";
import { Container } from "react-bootstrap";
import Toast from "../../../functions/Toast";
import Logo from "../../../assets/images/Logo.png";
import emailIcon from "../../../assets/ForgetPasswordImages/email_icon.png";
import { useNavigate } from "react-router-dom";
import { successNotify } from "../../../services/helper/helper";
import { useApi } from "../../../services/mutation&Query/GraphqlApi";
import { useMutation } from "@apollo/client";
import { EMAIL_SEND } from "../../../services/methods/Mutations";
import { ThreeDots } from "react-loader-spinner";

const RecoveryEmail = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const [emailsend] = useMutation(EMAIL_SEND);
  const { EmailSendMutation } = useApi();

  const verifyHandler = async () => {
    if (email == "") {
      Toast.error("Please provide an email");
      return;
    }
    try {
      setLoading(true);
      const res = await EmailSendMutation(emailsend, email);
      setLoading(false);
      if (res?.forgetEmailSend?.success) {
        navigate(`/verify-account?verify=${email}`);
      }
    } catch (error) {
      Toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const resendCode = () => {
    successNotify("Email has been resent!");
  };

  return (
    <div className="forget_password">
      <Container>
        <a className="navbar-brand" href="/">
          <img src={Logo} alt="" className="img-fluid" />
          <p>ShifaLia</p>
        </a>

        <div className="forget_password_box mt-5">
          <h5>Forgot your password?</h5>

          <div className="forget_inner_box recovery_email">
            <div>
              <img src={emailIcon} alt="" />
              <div className="recover_email">
                <b>Recover password via Email</b>
              </div>
            </div>
            <input
              disabled={loading}
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {loading ? (
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ThreeDots
                visible={true}
                height="25"
                width="50"
                color="#FFFFFF  "
                radius="9"
                ariaLabel="three-dots-loading"
              />
            </button>
          ) : (
            <button onClick={verifyHandler} className="mt-4">Submit</button>
          )}
          <p className="didnt_receive">
            Did not receive the email?
            <span style={{ cursor: "pointer" }} onClick={resendCode}>
              {" "}
              Resend email
            </span>
          </p>
        </div>
      </Container>
    </div>
  );
};

export default RecoveryEmail;
