import React, { useEffect, useState } from "react";
import "./notifications.scss";
import { Icon } from "@iconify/react";
import profilepic1 from "../../../assets/images/profilePic.png";
import profilepic2 from "../../../assets/images/profilePic1.png";
import Comment from "../comment";
import { useSelector } from "react-redux";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_ALL_NOTIFICATIONS,
  GET_ARCHIVED_NOTIFICATIONS,
  GET_COMMENTS_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS,
} from "../../../services/methods/Query";
import Cookies from "universal-cookie";

const Notifications = () => {
  const [activeTab, setActiveTab] = useState("All");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  // const { notification } = useSelector((state) => state.user);
  const [notification, setNotification] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [archievNotification, setArchievNotification] = useState([]);
  const [commentNotification, setCommentNotification] = useState([]);

  const cookies = new Cookies();

  const [getAllNotifications, { data, loading }] = useLazyQuery(
    GET_ALL_NOTIFICATIONS,
    {
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: cookies.get("token"),
        },
      },
      onCompleted: () => setNotification(data?.getAllNotifications?.data),
    }
  );

  const [getUnreadNotification, { data: unread }] = useLazyQuery(
    GET_UNREAD_NOTIFICATIONS,
    {
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: cookies.get("token"),
        },
      },
      onCompleted: () =>
        setUnreadNotifications(unread?.getUnreadNotifications?.data),
    }
  );

  const [getArchiveNotification, { data: archive }] = useLazyQuery(
    GET_ARCHIVED_NOTIFICATIONS,
    {
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: cookies.get("token"),
        },
      },
      onCompleted: () =>
        setArchievNotification(archive?.getArchivedNotifications?.data),
    }
  );

  const [getCommentNotifications, { data: comments }] = useLazyQuery(
    GET_COMMENTS_NOTIFICATIONS,
    {
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: cookies.get("token"),
        },
      },
      onCompleted: () =>
        setCommentNotification(comments?.getCommentsNotifications?.data),
    }
  );

  useEffect(() => {
    if (activeTab === "All") {
      getAllNotifications();
    } else if (activeTab === "Unread") {
      getUnreadNotification();
    } else if (activeTab === "Archive") {
      getArchiveNotification();
    } else if (activeTab === "Comments") {
      getCommentNotifications();
    }
  }, [activeTab]);

  return (
    <>
      <div className="all-notications">
        <div className="notification-container">
          <h3>Notifications</h3>
          <div className="switch-tab d-flex ">
            <div className="left d-flex gap-4">
              <h6
                className={activeTab === "All" ? "active-tab" : ""}
                onClick={() => handleTabClick("All")}
              >
                All
              </h6>
              <h6
                className={activeTab === "Unread" ? "active-tab" : ""}
                onClick={() => handleTabClick("Unread")}
              >
                Unread
              </h6>
              <h6
                className={activeTab === "Archive" ? "active-tab" : ""}
                onClick={() => handleTabClick("Archive")}
              >
                Archive
              </h6>
              <h6
                className={activeTab === "Comments" ? "active-tab" : ""}
                onClick={() => handleTabClick("Comments")}
              >
                Comments
              </h6>
            </div>
            <div className="right d-flex">
              <h6>Mark all as read</h6>
            </div>
          </div>
          {activeTab === "All" && (
            <div className="all-cmts-div">
              {notification?.map((i) => (
                <div className="cmt-div d-flex gap-3">
                  <img
                    src={i?.notification?.image || profilepic1}
                    alt="profilepic1"
                  />
                  <div className="txt-div d-flex flex-column justify-content-center align-item-center">
                    <h6>{i?.notification?.body}</h6>
                  </div>
                </div>
              ))}

              {/* <Comment all="d-none" imgprofile={profilepic2} /> */}
              {/* <div className="cmt-div d-flex gap-3">
                <img src={profilepic1} alt="profilepic1" />
                <div className="txt-div d-flex flex-column justify-content-center align-item-center">
                  <h6>
                    User Fullname became a Supporter for your Campaign
                    BelievingInBenjamin
                  </h6>
                  <p>5 min ago</p>
                </div>
              </div> */}
              {/* <Comment all="d-none" imgprofile={profilepic2} /> */}
            </div>
          )}

          {activeTab === "Unread" && (
            <div className="all-cmts-div">
              {unreadNotifications?.map((i) => (
                <div className="cmt-div d-flex gap-3">
                  <img
                    src={i?.notification?.image || profilepic1}
                    alt="profilepic1"
                  />
                  <div className="txt-div d-flex flex-column justify-content-center align-item-center">
                    <h6>{i?.notification?.body}</h6>
                  </div>
                </div>
              ))}
              {unreadNotifications?.length <= 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Icon
                    icon="system-uicons:cross-circle"
                    color="#707070"
                    width="140"
                    height="140"
                    className="dotsIcon"
                  />
                  <h5>You have no unread notifications!</h5>
                </div>
              )}
            </div>
          )}
          {activeTab === "Archive" && (
            <div className="all-cmts-div">
              {archievNotification?.map((i) => (
                <div className="cmt-div d-flex gap-3">
                  <img
                    src={i?.notification?.image || profilepic1}
                    alt="profilepic1"
                  />
                  <div className="txt-div d-flex flex-column justify-content-center align-item-center">
                    <h6>{i?.notification?.body}</h6>
                  </div>
                </div>
              ))}
              {archievNotification?.length <= 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Icon
                    icon="system-uicons:cross-circle"
                    color="#707070"
                    width="140"
                    height="140"
                    className="dotsIcon"
                  />
                  <h5>You have no Archived notifications!</h5>
                </div>
              )}
            </div>
          )}
          {activeTab === "Comments" && (
            <div className="all-cmts-div">
              {commentNotification?.map((item) => (
                <Comment data={item} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Notifications;
