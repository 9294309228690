// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  display: flex;
  z-index: 999;
  background: #f8f3ea;
}

.edit_img_prev {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  padding: 2px;
}

/* Sidebar.css */
`, "",{"version":3,"sources":["webpack://./src/pages/Edit details page/editpage1.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,YAAY;AACd;;AAEA,gBAAgB","sourcesContent":[".main {\n  display: flex;\n  z-index: 999;\n  background: #f8f3ea;\n}\n\n.edit_img_prev {\n  height: 50px;\n  width: 50px;\n  border-radius: 50px;\n  padding: 2px;\n}\n\n/* Sidebar.css */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
