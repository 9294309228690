import React from "react";
import Confirmation from "../components/OrderConfirmation/Confirmation";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";

const OrderConfirmationPage = () => {
  return (
    <div>
      <Header />
      <Confirmation />
      <Footer />
    </div>
  );
};

export default OrderConfirmationPage;
