import React from "react";
import Patientcard from "../caseinfo/patientcard";
import { Link } from "react-router-dom";
import PatientDocSlider from "./patiendocslider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./doc.scss";
import { Provider } from "../../../context/Context";
import { useSelector } from "react-redux";

const Doc = () => {
  const { caseById } = Provider();
  const { user } = useSelector((state) => state.user);
  const percentage = Math.floor(
    (caseById?.totalAmountCollected / caseById?.goalAmount) * 100
  );
  return (
    <>
      <div className="doc-section">
        <Patientcard
          hide="d-none"
          titlename="Patient’s Name"
          content="Patient condition and why they need donations.( A small paragraph will go here fullfilling this space)"
          btn2="Donate"
        />
        {caseById?.otherDocsLinks?.length >= 1 && (
          <div className="Patientsdoc my-5 ">
            <h5>Patient’s Documents</h5>
            <div className="patientcard-container mt-5">
              <div className="patientcard-container mt-5">
                <PatientDocSlider />
              </div>
            </div>
          </div>
        )}
        <div className="patient-story-  my-5 py-5">
          <h4 className="py-4">Patient’s Story</h4>
          <Patientcard
            hide1="d-none"
            titlename="Patient Case Title"
            btn2="Support This Campaign"
          />
        </div>
        <div className="campaigner-programContainer">
          <h4>Campaign’s Progress</h4>
          <div className="Campaigner-div my-5">
            <div className="camp-content">
              <div className="d-flex justify-content-center align-items-center gap-2">
                <p style={{fontWeight: 'bolder', fontSize: '30px'}}>{caseById?.totalAmountCollected} PKR</p>
                <p style={{fontWeight: 'bolder', fontSize: '30px'}}>out of {caseById?.goalAmount} PKR</p>
              </div>
            </div>
            <div className="camp my-4 d-flex justify-content-center align-items-center">
              <div className="progress-containerr d-flex gap-4">
                <div
                  className="Pprogress-barr"
                  style={{
                    width: `${percentage}%`,
                    transition: "width 0.6s ease",
                  }}
                ></div>
              </div>
              <h5>{percentage}%</h5>
              <div className="btn-support-btn">
                {user?.role === "donar" && (
                  <Link to={`/monthlyDonation/${caseById?._id}`}>
                    <button className="my-4">Support</button>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Doc;
