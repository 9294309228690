import React, { useState } from "react";
import "../Social Profile/socialprofile.css";
import { Col, Row, Container, Button, Form, FormGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { Provider } from "../../../context/Context";
import Toast from "../../../functions/Toast";
const PatientVerification = ({ isSidebarOpen, setTab }) => {
  const navigate = useNavigate();
  const {
    mrNumber,
    setMrNumber,
    employmentStatus,
    patientFullName,
    setpatientFullName,
    age,
    setAge,
    gender,
    setGender,
    setEmploymentStatus,
    patientAddress,
    setpatientAddress,
  } = Provider();

  const handleSubmit = () => {
    navigate("/detailpage5");
  };
  return (
    <>
      <div
        className={`form-container ${
          isSidebarOpen ? "form-container-open" : ""
        }`}
      >
        <h3 className="socialprofile">Patient Verification</h3>
        <Container className=" mb-5 form-09">
          <Row>
            <Container className=" patient-con py-2 px-5 ">
              <Form>
                <FormGroup className="d-flex flex-wrap form-colom ">
                  <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                  <label>Patient’s Name</label>

                    <input
                      type="text"
                      id="name"
                      className="fields px-4"
                      placeholder="Patient’s Name"
                      value={patientFullName}
                      onChange={(e) => setpatientFullName(e.target.value)}
                    />
                  </Col>
                  <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                    <div>
                  <label>Patient’s Age</label>

                      <input
                        type="number"
                        id="age"
                        value={age}
                        onChange={(e) => setAge(e.target.value)}
                        className="fields px-4"
                        placeholder="Patient’s Age"
                      />
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="d-flex flex-wrap form-colom  ">
                  <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                  <label>Patient’s Gender</label>

                    <input
                      type="text"
                      id="img"
                      className="fields px-4"
                      placeholder="Patient’s Gender"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    />
                  </Col>
                  <Col lg={4} md={5} sm={6} xs={12} className="form-group">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <input
                        type="radio"
                        id="true"
                        checked="checked"
                        name="status"
                        value="true"
                        onChange={(e) => setEmploymentStatus(e.target.value)}
                      />
                      <label for="true">Yes</label>
                      <br />
                      <input
                        type="radio"
                        id="false"
                        name="status"
                        value="false"
                        onChange={(e) => setEmploymentStatus(e.target.value)}
                      />
                      <label for="false">No</label>
                      <br />
                    </div>

                    <Form.Text className="Form-Text">
                      Please specify if the patient is employed, unemployed, or
                      a student.
                    </Form.Text>
                  </Col>
                </FormGroup>

                <FormGroup className="d-flex flex-wrap form-colom ">
                  <Col lg={4} md={4} sm={12} xs={12} className="form-group ">
                    <div>
                    <label>Patient’s Address</label>
                      <input
                        type="text"
                        id="document"
                        className="fields px-4"
                        placeholder="Address:"
                        value={patientAddress}
                        onChange={(e) => setpatientAddress(e.target.value)}
                      />
                      {/* <Form.Text className="Form-Text">
                        Please enter the patient’s registration number/ MR
                        number of the hospital in which the treatment is
                        ongoing.
                      </Form.Text> */}
                    </div>
                  </Col>
                  <Col lg={4} md={4} sm={12} xs={12} className="form-group ">
                    <div>
                    <label>MR no.</label>
                      <input
                        type="number"
                        id="document"
                        className="fields px-4"
                        placeholder="Partient’s registration/ MR no."
                        value={mrNumber}
                        onChange={(e) => setMrNumber(e.target.value)}
                      />
                      <Form.Text className="Form-Text">
                        Please enter the patient’s registration number/ MR
                        number of the hospital in which the treatment is
                        ongoing.
                      </Form.Text>
                    </div>
                  </Col>
                </FormGroup>

                {/* <FormGroup className="d-flex flex-wrap form-colom ">
                  <Col lg={4} md={4} sm={12} xs={12} className="form-group ">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <input
                        type="radio"
                        id="true"
                        checked="checked"
                        name="status"
                        value="true"
                        onChange={(e) => setEmploymentStatus(e.target.value)}
                      />
                      <label for="true">Yes</label>
                      <br />
                      <input
                        type="radio"
                        id="false"
                        name="status"
                        value="false"
                        onChange={(e) => setEmploymentStatus(e.target.value)}
                      />
                      <label for="false">No</label>
                      <br />
                    </div>

                    <Form.Text className="Form-Text">
                      Please specify if the patient is employed, unemployed, or
                      a student.
                    </Form.Text>
                  </Col>
                </FormGroup> */}

                <Button
                  onClick={() => setTab("family")}
                  className="Done-button mt-4"
                >
                  Next
                </Button>
              </Form>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PatientVerification;
