// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DonationImg {
  position: relative;
  width: 100%;
  min-height: 140px;
}

.DonationImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.DonationImgText {
  text-align: center;
  color: #ffffff;
  font-size: 30px;
  font-weight: 600;
  font-family: "Poppins";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
}
.startDonatingBtn {
  background-color: #014e7f !important;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5vw;
  color: #ffffff;
  /* width: 195px; */
  /* height: 50px; */
  padding: 10px 15px !important;
}
.startDonatingBtn:hover {
  background-color: #024574;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
@media (max-width: 992px) {
  .DonationImgText {
    font-size: 18px;
  }
}
@media (max-width: 470px) {
  .DonationImgText {
    font-size: 14px !important;
  }
}
@media (max-width: 380px) {
  .DonationImgText {
    font-size: 12px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AllCasesPageComponents/StartMonthlyDonationSection/StartMonthlyDonationSection.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,sBAAsB;EACtB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;AACA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,2BAA2B;EAC3B,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;EAClB,6BAA6B;AAC/B;AACA;EACE,yBAAyB;EACzB,wCAAwC;AAC1C;AACA;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,0BAA0B;EAC5B;AACF;AACA;EACE;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".DonationImg {\n  position: relative;\n  width: 100%;\n  min-height: 140px;\n}\n\n.DonationImg img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.DonationImgText {\n  text-align: center;\n  color: #ffffff;\n  font-size: 30px;\n  font-weight: 600;\n  font-family: \"Poppins\";\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -30%);\n}\n.startDonatingBtn {\n  background-color: #014e7f !important;\n  position: absolute;\n  bottom: 20%;\n  left: 50%;\n  transform: translateX(-50%);\n  font-size: 1.5vw;\n  color: #ffffff;\n  /* width: 195px; */\n  /* height: 50px; */\n  padding: 10px 15px !important;\n}\n.startDonatingBtn:hover {\n  background-color: #024574;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n}\n@media (max-width: 992px) {\n  .DonationImgText {\n    font-size: 18px;\n  }\n}\n@media (max-width: 470px) {\n  .DonationImgText {\n    font-size: 14px !important;\n  }\n}\n@media (max-width: 380px) {\n  .DonationImgText {\n    font-size: 12px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
