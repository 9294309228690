import { createContext, useContext, useEffect, useState } from "react";
import { patchDetails } from "../services/http/editDetailsApi";

const Context = createContext();

export function ContextProvider({ children }) {
  // for get case by id
  const [caseById, setCaseById] = useState({});
  const [fundraiserId, setfundraiserId] = useState("");
  useEffect(() => {
    setGoalAmount(caseById?.goalAmount);
    setmedicalCondition(caseById?.medicalCondition);
    setDescription(caseById?.description);
    setpatientFullName(caseById?.patientFullName);
    setAge(caseById?.age);
    setGender(caseById?.gender);
    setmonthlyIncome(caseById?.monthlyIncome);
    setHospitalName(caseById?.hospitalName);
    setMrNumber(caseById?.mrNumber);
    setrelationWithPatient(caseById?.relationWithPatient);
    sethowDoYouKnowAboutANF(caseById?.howDoYouKnowAboutANF);
    setWhatsAppNo(caseById?.WhatsAppNo);
    setpreviousCompanies(caseById?.previousCompanies);
    setconsultingDoctor(caseById?.consultingDoctor);
    setdoctorPhone(caseById?.doctorPhone);
    setfatherName(caseById?.fatherName);
    setmotherName(caseById?.motherName);
    sethusbandName(caseById?.husbandName);
    seteducation(caseById?.education);
    setmaritalStatus(caseById?.maritalStatus);
    setCNICNumber(caseById?.CNICNumber);
    setpatientAddress(caseById?.patientAddress);
    setTitle(caseById?.title);
    sethealthIssue(caseById?.healthIssue);
    setFullname(caseById?.fullName);
    setAddress(caseById?.Address);
    setdepandant(caseById?.depandant);
    settotalEarners(caseById?.totalEarners);
    setassets(caseById?.assets);
    setpatientHouse(caseById?.patientHouse);
    setpatientLivingIn(caseById?.patientLivingIn);
    setisZakatEligible(caseById?.isZakatEligible);
    // setCNICNumberOfPatient(caseById?.CNICNumberOfPatient);
  }, [caseById]);

  // for edit case
  const [customShortLinks, setcustomShortLinks] = useState([]);
  const [picturesLinks, setpicturesLinks] = useState([]);
  const [videoLinks, setvideoLinks] = useState([]);
  const [goalAmount, setGoalAmount] = useState("");
  const [fullName, setFullname] = useState("");
  const [medicalCondition, setmedicalCondition] = useState("");
  const [description, setDescription] = useState("");
  const [patientFullName, setpatientFullName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [title, setTitle] = useState("");
  const [consentLetter, setConsentLetter] = useState([]);
  const [otherDocsLinks, setotherDocsLinks] = useState([]);
  const [treatmentLetter, settreatmentLetter] = useState([]);
  const [ReportsLinks, setReportsLinks] = useState([]);
  const [mrNumber, setMrNumber] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState(false);
  const [relationWithPatient, setrelationWithPatient] = useState("");
  const [monthlyIncome, setmonthlyIncome] = useState("");
  const [howDoYouKnowAboutANF, sethowDoYouKnowAboutANF] = useState("");
  const [campaignerWhatsAppNo, setcampaignerWhatsAppNo] = useState([]);
  const [WhatsAppNo, setWhatsAppNo] = useState("");
  const [previousCompanies, setpreviousCompanies] = useState("");
  const [UHIDNumber, setUHIDNumber] = useState([]);
  const [consultingDoctor, setconsultingDoctor] = useState("");
  const [doctorPhone, setdoctorPhone] = useState("");
  const [fatherName, setfatherName] = useState("");
  const [motherName, setmotherName] = useState("");
  const [education, seteducation] = useState("");
  const [husbandName, sethusbandName] = useState("");
  const [patientAddress, setpatientAddress] = useState("");
  const [Address, setAddress] = useState("");
  const [depandant, setdepandant] = useState([
    { age: "", isStudent: "", instituteName: "" },
  ]);
  const [totalEarners, settotalEarners] = useState([
    { relation: "", income: "" },
  ]);
  const [assets, setassets] = useState("");
  const [patientHouse, setpatientHouse] = useState("");
  const [patientLivingIn, setpatientLivingIn] = useState("");
  const [isZakatEligible, setisZakatEligible] = useState(Boolean);
  const [addTeamMember, setaddTeamMember] = useState([]);
  const [CNICNumber, setCNICNumber] = useState("");
  // const [CNICNumberOfPatient, setCNICNumberOfPatient] = useState("");
  const [maritalStatus, setmaritalStatus] = useState("");
  const [CNICImageLinks, setCNICImageLinks] = useState([]);
  const [utilityBillImageLinks, setutilityBillImageLinks] = useState([]);
  const [categoryId, setcategoryId] = useState("");
  const [healthIssue, sethealthIssue] = useState("");
  const [editLoading, setEditLoading] = useState(false);

  // for all Cases Filtration
  const [catId, setCatId] = useState("");
  const [date, setDate] = useState("");
  const [search, setSearch] = useState("");
  const handleSubmit = async () => {
    setEditLoading(true);
    const formData = new FormData();
    formData.append("fundraiserId", fundraiserId);
    formData.append("monthlyIncome", Number(monthlyIncome));
    formData.append("patientFullName", patientFullName);
    formData.append("age", Number(age));
    formData.append("gender", gender);
    formData.append("medicalCondition", medicalCondition);
    formData.append("goalAmount", Number(goalAmount));
    formData.append("description", description);
    if (picturesLinks) {
      for (let i = 0; i < picturesLinks?.length; i++) {
        formData.append("picturesLinks", picturesLinks[i]);
      }
    }
    if (videoLinks) {
      for (let i = 0; i < videoLinks?.length; i++) {
        formData.append("videoLinks", videoLinks[i]);
      }
    }
    // if (customShortLinks) {
    //   for (let i = 0; i < customShortLinks?.length; i++) {
    //     formData.append("customShortLinks", customShortLinks[i]);
    //   }
    // }

    formData.append("hospitalName", hospitalName);
    if (consentLetter) {
      for (let i = 0; i < consentLetter?.length; i++) {
        formData.append("consentLetter", consentLetter[i]);
      }
    }
    if (otherDocsLinks) {
      for (let i = 0; i < otherDocsLinks?.length; i++) {
        formData.append("otherDocsLinks", otherDocsLinks[i]);
      }
    }
    if (ReportsLinks) {
      for (let i = 0; i < ReportsLinks?.length; i++) {
        formData.append("ReportsLinks", ReportsLinks[i]);
      }
    }
    if (CNICImageLinks) {
      for (let i = 0; i < CNICImageLinks?.length; i++) {
        formData.append("CNICImageLinks", CNICImageLinks[i]);
      }
    }
    if (utilityBillImageLinks) {
      for (let i = 0; i < utilityBillImageLinks?.length; i++) {
        formData.append("utilityBillImageLinks", utilityBillImageLinks[i]);
      }
    }
    if (treatmentLetter) {
      for (let i = 0; i < treatmentLetter?.length; i++) {
        formData.append("treatmentLetter", treatmentLetter[i]);
      }
    }
    formData.append("mrNumber", Number(mrNumber));
    formData.append("employmentStatus", Boolean(employmentStatus));
    formData.append("relationWithPatient", relationWithPatient);
    // formData.append("howDoYouKnowAboutANF", howDoYouKnowAboutANF);
    formData.append("WhatsAppNo", WhatsAppNo);
    if (campaignerWhatsAppNo?.length >= 1) {
      formData.append("campaignerWhatsAppNo", campaignerWhatsAppNo);
    }
    formData.append("previousCompanies", previousCompanies);
    if (UHIDNumber?.length >= 1) {
      formData.append("UHIDNumber", UHIDNumber);
    }
    formData.append("consultingDoctor", consultingDoctor);
    formData.append("doctorPhone", doctorPhone);
    if (addTeamMember?.length >= 1) {
      formData.append("addTeamMember", addTeamMember);
    }
    formData.append("fatherName", fatherName);
    formData.append("motherName", motherName);
    formData.append("husbandName", husbandName);
    formData.append("title", title);
    formData.append("education", education);
    formData.append("patientAddress", patientAddress);
    formData.append("totalEarners", JSON.stringify(totalEarners));
    formData.append("depandant", JSON.stringify(depandant));
    formData.append("assets", JSON.stringify(assets));
    // formData.append("CNICNumberOfPatient", CNICNumberOfPatient);
    formData.append("Address", Address);
    formData.append("patientHouse", patientHouse);
    formData.append("patientLivingIn", patientLivingIn);
    formData.append("isZakatEligible", Boolean(isZakatEligible));
    formData.append("CNICNumber", CNICNumber);
    formData.append("maritalStatus", maritalStatus);
    formData.append(
      "categoryId",
      categoryId !== "" ? categoryId : caseById?.categoryDetails[0]?._id
    );
    const res = await patchDetails(formData);
    setEditLoading(false);
    return res;
  };

  return (
    <Context.Provider
      value={{
        setfundraiserId,
        customShortLinks,
        setcustomShortLinks,
        picturesLinks,
        setpicturesLinks,
        videoLinks,
        setvideoLinks,
        goalAmount,
        setGoalAmount,
        medicalCondition,
        setmedicalCondition,
        description,
        setDescription,
        patientFullName,
        setpatientFullName,
        age,
        setAge,
        gender,
        setGender,
        hospitalName,
        setHospitalName,
        consentLetter,
        setConsentLetter,
        otherDocsLinks,
        setotherDocsLinks,
        ReportsLinks,
        setReportsLinks,
        mrNumber,
        setMrNumber,
        employmentStatus,
        setEmploymentStatus,
        relationWithPatient,
        setrelationWithPatient,
        monthlyIncome,
        setmonthlyIncome,
        howDoYouKnowAboutANF,
        sethowDoYouKnowAboutANF,
        campaignerWhatsAppNo,
        setcampaignerWhatsAppNo,
        WhatsAppNo,
        setWhatsAppNo,
        previousCompanies,
        setpreviousCompanies,
        UHIDNumber,
        setUHIDNumber,
        consultingDoctor,
        setconsultingDoctor,
        doctorPhone,
        setdoctorPhone,
        addTeamMember,
        title,
        setTitle,
        fatherName,
        setfatherName,
        motherName,
        setmotherName,
        education,
        seteducation,
        husbandName,
        sethusbandName,
        depandant,
        setdepandant,
        totalEarners,
        settotalEarners,
        assets,
        setassets,
        patientHouse,
        setpatientHouse,
        patientLivingIn,
        setpatientLivingIn,
        isZakatEligible,
        setisZakatEligible,
        setaddTeamMember,
        categoryId,
        setcategoryId,
        CNICNumber,
        setCNICNumber,
        maritalStatus,
        setmaritalStatus,
        patientAddress,
        setpatientAddress,
        CNICImageLinks,
        setCNICImageLinks,
        utilityBillImageLinks,
        setutilityBillImageLinks,
        treatmentLetter,
        settreatmentLetter,
        healthIssue,
        sethealthIssue,
        fullName,
        setFullname,
        Address,
        setAddress,
        // CNICNumberOfPatient,
        // setCNICNumberOfPatient,
        editLoading,
        handleSubmit,
        // for all cases filtration
        catId,
        setCatId,
        date,
        setDate,
        search,
        setSearch,
        // for get case by id
        caseById,
        setCaseById,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function Provider() {
  return useContext(Context);
}
