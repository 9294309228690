import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./BlogContent.css";
import blogContentimg1 from "../../../assets/images/blogContentimg1.png";
import blogContentimg2 from "../../../assets/images/blogContentimg2.png";
import blogContentimg3 from "../../../assets/images/blogContentimg3.png";
import blogContentimg4 from "../../../assets/images/blogContentimg4.png";
import blogContentimg5 from "../../../assets/images/blogContentimg5.png";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

const BlogContent = () => {
  return (
    <>
      <section className="BlogContentContainer py-4">
        <div className="container">
          <div className="top-content">
            <div className="circle-arrow-div">
              <button className="circle-arrow">
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            </div>
            <div className="Blog_topContent">
              <p className="publishedDate mt-4">Published on: 16-jan-2024</p>
              <h2 className="Blog_topHeading">
                Unraveling the Gut-Brain Connection
              </h2>
              <p className="secondTxt">Your Second Brain in Action!</p>
            </div>
            <div className="Blog_topButtons">
              <Button className="blog_topbutton">Brain</Button>
              <Button className="blog_topbutton">Mental Health</Button>
              <Button className="blog_topbutton">Microbiome</Button>
              <Button className="blog_topbutton">Gut-Brain</Button>
            </div>
            <div className="Blog_topContent mt-5">
              <img
                src={blogContentimg1}
                className="blogContentimg1 w-100"
                alt=""
              ></img>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="container middle_content mt-3 mx-3 px-3">
            <div className="vl"></div>
            <div>
              <h1 className="MainBlogHead mx-5">
                Gut health: Your untapped resource for a happier mind and body.
              </h1>
              <p className="positioned-element pt-4 px-5 ">- Lorem Ipsum</p>
            </div>
            <div id="allsocialIcons">
              <a
                href="https://www.facebook.com/profile.php?id=100089498583736&mibextid=LQQJ4d"
                className="facebooksocial"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com/company/alia-nafees-foundation/mycompany/"
                className="linkedinsocial"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a
                href="https://twitter.com/i/flow/login?redirect_after_login=%2FAlia5Foundation"
                className="twittersocial"
              >
                <FontAwesomeIcon icon={faTwitter} size="2x" />
              </a>
              <a
                href="https://instagram.com/alia_nafees_foundation?igshid=OGQ5ZDc2ODk2ZA=="
                className="instagramsocial"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </div>
          </div>
        </div>

        <div className="container mt-5  d-flex flex-wrap justify-content-center">
          <div className="paraContent">
            <p className="ParaText">
              They say "trust your gut," but did you know that your gut might be
              influencing more than just your instincts? Welcome to the
              fascinating world of the gut-brain connection, where the trillions
              of microbes residing in your digestive system play a pivotal role
              in shaping not only your digestive health but also your mental
              well-being. In this blog, we'll dive deep into this intricate
              relationship, shedding light on how your gut and brain communicate
              and how nurturing your gut health can positively impact your
              overall wellness.
            </p>
            <p className="boldContent">
              The Hidden World Within: Your Microbiome
            </p>
            <p className="ParaText">
              Our gut is home to an astonishing ecosystem of microbes
              collectively known as the microbiome. Comprising bacteria,
              viruses, fungi, and other microorganisms, this bustling community
              is involved in a wide array of functions, from aiding in digestion
              to bolstering the immune system. Recent research has shown that
              these tiny inhabitants also hold a surprising amount of influence
              over our mental state.
            </p>
            <p className="boldContent">The Gut-Brain Connection Unveiled</p>
            <p className="ParaText">
              {" "}
              The gut-brain connection, often referred to as the "second brain,"
              is a complex network of communication between the central nervous
              system and the gastrointestinal tract. This bidirectional highway
              involves biochemical signaling, nerve pathways, and the release of
              neurotransmitters that transmit information between the two
              systems.<br></br>
              Scientists have discovered that the gut can send signals to the
              brain that affect mood, stress levels, and even cognitive
              function. Conversely, the brain can influence the gut's behavior,
              affecting digestion and gut motility. The communication between
              the gut and brain is so profound that imbalances in the gut
              microbiome have been linked to various mental health conditions,
              including anxiety, depression, and even neurodegenerative
              disorders.
            </p>
          </div>
          <div className="Blog_img d-flex justify-content-center  mt-3 mx-5">
            <img
              src={blogContentimg2}
              className="w-100 px-3 w-100 mt-3"
              alt=""
            ></img>
            <img
              src={blogContentimg3}
              className="w-100 px-3 w-100 mt-3"
              alt=""
            ></img>
          </div>
          <div className="paraContent mt-5">
            <p className="boldContent">
              Exploring the Microbiome: A Gateway to Wellness
            </p>
            <p className="ParaText">
              In the bustling world of your digestive system, an entire universe
              of microorganisms resides, quietly orchestrating a symphony of
              functions that extend far beyond digestion. These microbes,
              collectively known as the microbiome, are like the architects of
              your well-being, constructing a foundation for both physical
              health and mental equilibrium.
            </p>
            <p className="boldContent">The Harmonious Dance of Influence</p>
            <p className="ParaText">
              Picture this: a serene dance between your gut and brain, each
              partner leading and following in harmony. This intricate
              performance involves a constant exchange of signals, a dialogue
              that science is just beginning to comprehend. The gut, armed with
              an army of bacteria, releases compounds that influence brain
              function, mood, and even decision-making. In response, the brain
              reciprocates by affecting gut motility, secretion, and the
              delicate balance of the microbiome.
            </p>
            <p className="boldContent">Mood Alchemy in the Gut</p>
            <p className="ParaText">
              Imagine that your gut is a cauldron, bubbling with the potion of
              serotonin, dopamine, and other neurotransmitters crucial for
              emotional well-being. This potion isn't brewed in a wizard's
              chamber; rather, it's concocted by the microbiome dwelling within
              you. Serotonin, often associated with happiness, is primarily
              produced in the gut. Disruptions in this production line can lead
              to a range of emotional imbalances, from a simple case of the
              blues to more complex mental health challenges.
            </p>
            <p className="boldContent">The Anxiety-Inflammation Connection</p>
            <p className="ParaText">
              Anxiety, that relentless companion of modern life, may find an
              unexpected ally in the gut. When the microbiome becomes
              imbalanced, inflammation can surge, creating a cascade of effects
              that extends all the way to the brain. This inflammation is linked
              to the hyperactivity of the body's stress response, a state not
              conducive to peaceful minds. By nurturing your gut, you might just
              be offering your brain a shield against the storm of anxiety.
            </p>
          </div>
        </div>
        <div>
          <img src={blogContentimg4} className="blogContentimg4" alt=""></img>
        </div>
        <div className="container mt-5  d-flex flex-wrap justify-content-center">
          <div className="paraContent mt-4">
            <p className="boldContent">The Road Ahead: A Holistic Revolution</p>
            <p className="ParaText">
              {" "}
              As research delves deeper into the intricate world of the
              gut-brain connection, a revolution is taking place in the field of
              healthcare. No longer can we view our bodies as isolated systems;
              the mind-body connection is proving to be a potent force. By
              embracing this revolution and cultivating a harmonious
              relationship between our gut and brain, we can unlock a new realm
              of well-being that extends far beyond conventional boundaries.
            </p>
            <p className="ParaText">
              {" "}
              So, let's raise our forks to the future, a future where we not
              only eat for sustenance but for the thriving of our minds, bodies,
              and the enchanting bond that unites them – the gut-brain
              connection.
            </p>
          </div>
          <div className="mt-3 mx-5">
            <img
              src={blogContentimg5}
              className="blogContentimg5 px-1"
              alt=""
            ></img>
          </div>
          <div className="paraContent my-4">
            <p className="Conclusion_Heading">Conclusion</p>
            <p className="ParaText">
              The gut-brain connection offers a captivating glimpse into the
              intricate interplay between our physical and mental health. By
              nurturing your gut health, you can potentially influence your
              mood, cognitive function, and overall well-being. As science
              continues to unravel the mysteries of this dynamic relationship,
              adopting a holistic approach to health that considers both your
              gut and brain could be the key to unlocking a healthier, happier
              you. So, why not start by treating your "second brain" with the
              care and attention it deserves?
            </p>
            <p className="ParaText">
              {" "}
              Remember, a healthy gut is a step towards a healthier mind and
              body.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogContent;
