import React, { useState } from "react";
import { Col, Row, Container, Button, Form, FormGroup } from "react-bootstrap";
import "./Form.css";
import { useApi } from "../../../services/mutation&Query/GraphqlApi";
import { useMutation, useQuery } from "@apollo/client";
import { CONTACT_FORM } from "../../../services/methods/Mutations";
import Toast from "../../../functions/Toast";
import { GET_DICTIONARY_QUERY } from "../../../services/methods/Query";
const FormSection = () => {
  //   const [queryType, setQueryType] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    country: "PK",
    queryType: "",
  });

  const { data } = useQuery(GET_DICTIONARY_QUERY);
  const arrOfData = data?.getDictionary?.data;

  function checkGroup(arrOfData) {
    return arrOfData.group === "ContactUs";
  }

  const filterData = arrOfData?.filter(checkGroup);

  const { contactForm } = useApi();
  const [contactFormMutation] = useMutation(CONTACT_FORM);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const { firstName, lastName, email, phone, message, queryType } = formData;

    console.log(formData);
    if (!firstName || !lastName || !email || !phone || !queryType || !message) {
      Toast.error("Please fill in all fields");
      return false;
    }
    return true;
  };

  const sendMessageHandler = async () => {
    if (validateForm()) {
      const response = await contactForm(contactFormMutation, formData);
      // if (response.success) {
      //     // Handle success, e.g., show a success message or redirect
      //     console.log("Message sent successfully:", response.data);
      // } else {
      //     // Handle failure, e.g., show an error message
      //     console.error("Error sending message:", response.error);
      // }
    }
  };
  return (
    <>
      <Row>
        <h5 className="FormHeading mt-4">
          Have any suggestions or queries? Let us know and we’ll get back to you
          as soon as we can!
        </h5>
      </Row>
      <Container className="mt-5 mb-5">
        <Row>
          <Container className="FormContainer py-5 px-5">
            <Form>
              <FormGroup className="d-flex flex-wrap">
                <Col lg={6} md={6} sm={12} xs={12}>
                  <input
                    type="text"
                    className="Name px-4"
                    placeholder="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                  <input
                    type="text"
                    className="LastName px-4 mx-lg-4"
                    placeholder="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </Col>
              </FormGroup>
              <Col lg={12} md={12} sm={12} xs={12}>
                <input
                  type="email"
                  className="Other px-4 mt-4"
                  placeholder="Email Address"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <input
                  type="phone"
                  className="Other px-4 mt-4"
                  placeholder="Phone Number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <select
                  className="Others px-4 mt-4"
                  name="queryType"
                  value={formData.queryType}
                  onChange={handleInputChange}
                >
                  <option style={{ fontSize: "12px" }} selected hidden>
                    Select Query
                  </option>
                  {filterData?.map((i) => (
                    <option style={{ fontSize: "16px" }}>{i?.code}</option>
                  ))}
                </select>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <textarea
                  className="Message px-4 mt-4"
                  rows={9}
                  placeholder="Enter Message Here"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </Col>
              <Button
                className="SendMsgButton mt-4"
                onClick={sendMessageHandler}
              >
                Send Message
              </Button>
            </Form>
          </Container>
        </Row>
      </Container>
    </>
  );
};
export default FormSection;
