import React, { useRef, useState } from "react";
import "./GigOffer.css";
import testImg from "../../assets/images/gig1.png";
import imageIcon from "../../assets/FundraisingTipsImages/imageIcon.png";
import { useNavigate, useParams } from "react-router-dom";
import { createGigOffer } from "../../services/http/gigOfferApi";
import Toast from "../../functions/Toast";

const GigOffer = () => {
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [email, setemail] = useState("");
  const [ImageLinks, setImageLinks] = useState([]);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const data = {
    gigId: params?.id,
    email,
    // title,
    description,
    ImageLinks,
  };

  const formData = new FormData();
  formData.append("gigId", params?.id);
  // formData.append("title", title);
  formData.append("description", description);
  formData.append("email", email);
  for (let i = 0; i < ImageLinks?.length; i++) {
    formData.append("ImageLinks", ImageLinks[i]);
  }

  const [imagesPreview, setImagesPreview] = useState([]);
  const fileInputRef = useRef(null);
  const handleClick = () => {
    fileInputRef.current?.click();
  };
  const handleImage = (e) => {
    setImageLinks(e.target.files);
    const files = Array.from(e.target.files);
    setImagesPreview([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async () => {
    if (email == "" || description == "") {
      Toast.error("Please provide all fields");
      return;
    }
    setLoading(true);
    const res = await createGigOffer(formData);
    setLoading(false);
    console.log(res);
    if (res?.data?.success) {
      navigate(`/order-confirmation/${res?.data?.data}`);
    }
  };
  return (
    <div className="main_gig_offer">
      <div className="heading_sect">
        <h2>Requirements & Expectations</h2>
        <p>
          Assist the service provider by providing additional information about
          the service you require.
        </p>
      </div>
      <p className="span_para">Please provide the following details: </p>
      <div className="input_offer">
        {/* <input
          type="text"
          placeholder="Enter Project title"
          value={title}
          onChange={(e) => settitle(e.target.value)}
        /> */}
        <input
          type="text"
          placeholder="Enter Your Work Email"
          value={email}
          onChange={(e) => setemail(e.target.value)}
        />
        <textarea
          placeholder="Please provide a complete brief of the requirements. Make sure to include all necessary details to ensure the best possible service. "
          rows={10}
          value={description}
          onChange={(e) => setdescription(e.target.value)}
        ></textarea>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <input
            className="check_input"
            type="checkbox"
            value={check}
            onChange={(e) => {
              if (e.target.checked) {
                setCheck(true);
              } else {
                setCheck(false);
              }
            }}
          />
          <span>Check the box if you want to upload documents</span>
        </div>

        {check && (
          <div className="gig_docs">
            <p>Please provide any relevant reference work regarding the service you require. This will help the service provider better understand your requirements and expectations about the service. </p>
            <img src={imageIcon} alt="" />
            <h3>Add Documents</h3>
            <button onClick={handleClick}>Upload</button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              multiple
              onChange={handleImage}
            />
          </div>
        )}
      </div>
      {imagesPreview.map((image, index) => (
        <img
          className="selected_doc"
          key={index}
          src={image}
          alt="Product Preview"
        />
      ))}
      <div className="review_btn">
        <button disabled={loading} onClick={handleSubmit}>
          {loading ? "Wait..." : "Review"}
        </button>
      </div>
    </div>
  );
};

export default GigOffer;
