import React from "react";
import { Icon } from "@iconify/react";
import pp from "../../../assets/images/UserDp.png";

import "./caseinfo.scss";
const Commentcard = ({ image, name, comment, date }) => {
  return (
    <>
      <div className="cmt-card my-4">
        <div className="cmt-profile-img">
          <img src={image || pp} alt="profile" />
        </div>
        <div className="cmt-card-content">
          <h5>{name}</h5>
          <h6>{date}</h6>
          <p className="mt-4">{comment}</p>
        </div>
        {/* <div className="cmt-likes">
          <span className="d-flex gap-2 justify-content-center align-items-center">
            <Icon icon="ph:heart-bold" color="#014E7F" width="24" height="24" className="heart-bold"/>
            <h5>12</h5>
          </span>
        </div> */}
      </div>
    </>
  );
};

export default Commentcard;
