import React from "react";
import "./Confirmed.css";
import gigImg from "../../assets/images/gig1.png";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router-dom";
import { GET_GIG_OFFER_BY_ID } from "../../services/methods/Query";
import { useQuery } from "@apollo/client";

const Confirmed = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const params = useParams();
  const navigate = useNavigate();

  const { data } = useQuery(GET_GIG_OFFER_BY_ID, {
    variables: {
      id: params?.id,
    },
  });

  const gigData = data?.getGigOfferById?.data[0];
  return (
    <div className="confirm_main">
      <div className="confirm_top">
        <h3>Order Confirmed</h3>
        <p>Your Order is confirmed!</p>
      </div>
      <p className="confirm_summary">Detail Project Summary</p>
      <div className="details_container_parent">
        <div className="details_container">
          <div className="container_child_1">
            <div className="success_bar">
              You have successfully bought this service.
            </div>
            <h4>{gigData?.title}</h4>
            <p className="price_gig">
              {gigData?.gigDetails[0]?.minPrice} Rs. To {" "}
              {gigData?.gigDetails[0]?.maxPrice} Rs.
            </p>
            <h5>Gig Detail</h5>
            <p>{gigData?.description}</p>
          </div>
          <div className="container_child_2">
            <Slider {...settings}>
              {gigData?.ImageLinks?.map((item) => (
                <div className="gig_confirm_img">
                  <img src={item} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="confirm_button">
        <button onClick={() => navigate("/")}>Home</button>
      </div>
    </div>
  );
};

export default Confirmed;
