import React from "react";
import { Row, Button, Col } from "react-bootstrap";
import "./StartMonthlyDonationSection.css";
import StartMonthlyDonationImg from "../../../assets/images/StartMonthlyDonationImg.png";

const StartMonthlyDonationSection = () => {
  return (
    <>
      <div className="DonationImg mb-5">
        <img
          src={StartMonthlyDonationImg}
          alt="StartMonthlyDonationImg"
          className="w-100"
        />
        <Row>
          <h4 className="DonationImgText">
            Help More People by Supporting Campaigns <br />
            Through Monthly Recurring Payments
          </h4>
        </Row>
        {/* <Row>
          <Col>
            <Button className="startDonatingBtn">
              Start Supporting Monthly
            </Button>
          </Col>
        </Row> */}
      </div>
    </>
  );
};

export default StartMonthlyDonationSection;
